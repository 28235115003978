import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';

export interface ContinueSessionDialogProps{
  open: boolean,
  onClose: (refresh: boolean) => void
}
function ContinueSessionDialog(props: ContinueSessionDialogProps) {

  return (
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Session Expired</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session has expired. Would you like to refresh you session? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{color: "rgb(4, 4, 252)"}} onClick={() => props.onClose(false)}>
            No
          </Button>
          <Button style={{backgroundColor: "rgb(4, 4, 252)", color: 'white'}} onClick={() => props.onClose(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
    userState: state.userState

});


export default connect(mapStateToProps)(ContinueSessionDialog)