import { Property } from "../Models/Property"

export enum CartActionType {
    ADD_TO_CART = 'ADD_TO_CART',
    REMOVE_FROM_CART = 'REMOVE_FROM_CART',
    CLEAR_CART = 'CLEAR_CART'
}

export const addToCart = (property: Property) => {
    return {
        type: CartActionType.ADD_TO_CART,
        payload: {
            property: property
        }
    }
}

export const removeFromCart = (id: number) =>{
    return{
        type: CartActionType.REMOVE_FROM_CART,
        payload: {
            id: id
        }
    }
}

export const clearCart = () =>{
    return{
        type: CartActionType.CLEAR_CART,
        payload: null
    }
}
