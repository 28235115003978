import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Bar, Chart } from 'react-chartjs-2';
import { baseEndpoint } from '../App';
import { NeighborhoodPopular } from '../Models/Neighborhood';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "absolute",
            backgroundColor: "white",
            zIndex: 999,
            height: "90%",
            width: "100%",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function IncomeChart(props: any) {
    const classes = useStyles();
    const [neighborhoodId, setNeighborhoodId] = useState<number>();
    const [data, setData] = useState<any>({} as any);
    Chart.defaults.maintainAspectRatio = false;
    var id = queryString.parse(props.location.search, { arrayFormat: 'index' }).id
    if (id && id !== neighborhoodId) {
        fetchChart(id);
        setNeighborhoodId(id);
    }
    const d = {
        labels: [] as any[],
        datasets: [
            {
                data: [] as any[],
                backgroundColor: [] as any[],
                borderColor: [] as any[],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value: any, index: any, values: any) {
                        return '$' + value;
                    },
                    font:{
                        size: 15
                    }
                },

            },
            y:{
                ticks:{
                    font: {
                        size: 15
                    }
                }
            }
        },
        indexAxis: 'y' as "y",
        animation: false as false,
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false
            },

            title: {
                display: true,
                text: 'Median Household Income',
                font: {
                    wieght: "bold",
                    size: 30
                },
                padding: 5,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        var label = context.dataset.label || '';

                        if (label) {
                            label += ': ' + context.formattedValue;
                        }
                        if (context.formattedValue !== null) {
                            label += "$" + context.formattedValue;
                        }
                        return label;
                    }
                }
            }
        },
    };

    async function fetchChart(id: number) {
        try {
            await fetch(baseEndpoint + 'neighborhoods/income').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as NeighborhoodPopular[];
                    var labels = [] as string[];
                    var barData = [] as any[];
                    var backColor = [] as any[];
                    var borderColor = [] as any[];
                    r.forEach(q => {
                        labels.push(q.name);
                        barData.push(q.fact);
                        if (q.neighborhoodId === Number(id)) {
                            backColor.push("rgba(23, 215, 3, 0.2)");
                            borderColor.push("#17d703");
                        }
                        else {
                            backColor.push("rgba(4, 4, 252, 0.2)");
                            borderColor.push("#0404fc");
                        }
                    });
                    d.datasets[0].data = barData;
                    d.datasets[0].backgroundColor = backColor;
                    d.datasets[0].borderColor = borderColor;
                    d.labels = labels;
                    setData(d);
                }
            });
        }
        catch (ex) {

        }
    }
    return (
        <div className={classes.root}>
            {data.datasets &&<div style={{ position: "absolute", top: "2%", left: "0%", height: "100%", width: "96%", marginLeft: "2%",backgroundColor: "white" }}>
                <Bar id="chart" data={data} options={options} />
            </div>}
        </div>
    );
}