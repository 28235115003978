import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chart } from 'react-chartjs-2';
import { baseEndpoint } from '../App';
import { Property } from '../Models/Property';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "absolute",
            backgroundColor: "white",
            zIndex: 999,
            height: "90%",
            width: "100%",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function TaxChart(props: any) {
    const classes = useStyles();
    const [addressId, setAddressId] = useState<number>();
    const [property, setProperty] = useState<Property>({} as Property);
    const [data, setData] = useState<any[]>([] as any[]);
    const [taxRate, setTaxRate] = useState<any[]>([] as any[]);
    Chart.defaults.maintainAspectRatio = false;
    var id = queryString.parse(props.location.search, { arrayFormat: 'index' }).id
    if (id && id !== addressId) {
        fetchProperty(id);
        setAddressId(id);
    }


    async function fetchProperty(id: number) {
        try {
            await fetch(baseEndpoint + 'properties/address/' + id).then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Property;
                    setProperty(r)
                    fetchTaxData(r.addresses[0].block, r.addresses[0].lot, r.addresses[0].borough);
                    getTaxRates();
                    console.log(property)
                }
            });
        }
        catch (ex) {

        }
    }

    async function fetchTaxData(block: number, lot: number, borough: number)
    {
        try {
            await fetch('https://data.cityofnewyork.us/resource/yjxr-fw8i.json?block='+ block + '&lot='+ lot+ "&boro=" + borough).then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as any[];
                    console.log(r);
                    setData(r.sort((a: any,b: any) => Number(b.year.split('/')[0]) - Number(a.year.split('/')[0])));
                }
            });
        }
        catch (ex) {

        }
    }

    const getTaxRates = async () => {

        var requestOptions = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {
            await fetch("https://data.cityofnewyork.us/resource/7zb8-7bpk.json", requestOptions)
                .then(async result => {
                    var r = await result.json();
                    console.log(r);
                    setTaxRate(r);
                })
        }
        catch (ex) {
            console.error(ex);
        }
    }

    return (
        <div className={classes.root}>
            {data.length !== 0 && taxRate.length !== 0 && <TableContainer id="chart" component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingRight: "0px" }} align='left'>Year</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align='left'>Bldg. Class</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Market Value</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Assessed Value</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Taxable</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Tax Rate</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Property Tax</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Change Rate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((d, i) => {
                                console.log(d.year.substring(2))
                                var rateYear = taxRate.find(r => r.year === d.year.substring(2))
                                var rate = "";
                                if (d.taxclass.toString().includes('2')) {
                                    rate = rateYear.class_2;
                                }
                                else if (d.taxclass.toString().includes('4')) {
                                    rate = rateYear.class_4;
                                }
                                var deduct = d.extot2 ? d.extot2 : 0;
                                var propTax = !isNaN(d.avtot2) ? Math.round((Number(rate) / 100) * (Number(d.avtot2) - deduct)) : Math.round((Number(rate) / 100) * (Number(d.avtot)- deduct))
                                var change = 0;
                                if (i < data.length - 1) {
                                    var oldRate;
                                    if (d.taxclass.toString().includes('2')) {
                                        oldRate = taxRate.find(r => r.year === data[i + 1].year.substring(2)).class_2;
                                    }
                                    else if (d.taxclass.toString().includes('4')) {
                                        oldRate = taxRate.find(r => r.year === data[i + 1].year.substring(2)).class_4;
                                    }
                                    var old = !isNaN(d.avtot2) ? Math.round((Number(oldRate) / 100) * Number(data[i + 1].avtot2)) : Math.round((Number(oldRate) / 100) * Number(data[i + 1].avtot))
                                    change = ((propTax - old) / propTax) * 100
                                }
                                return (
                                    <TableRow style={{ backgroundColor: (i % 2 === 1) ? "white" : "lightgrey" }} key={i}>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{d.year}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{d.bldgcl}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{Number(d.fullval) === 0 ? "" : "$" + Number(d.fullval).toLocaleString('en-US')}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{!isNaN(d.avtot2) ? "$" + Number(d.avtot).toLocaleString('en-US') : ""}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{!isNaN(d.avtot2) ? "$" + Number(d.avtot2).toLocaleString('en-US') : (Number(d.avtot) === 0 ? "" : "$" + Number(d.avtot).toLocaleString('en-US'))}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{(Math.round(Number(rate) * 1000)) / 1000}%</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{"$" + propTax.toLocaleString('en-US')}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{isNaN(Math.round(change * 100) / 100) || Math.round(change * 100) / 100 === 0 ? "" : Math.round(change * 100) / 100 + "%"}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <div style={{textAlign: "left", paddingLeft: "10px", lineHeight: ".4em"}}><Typography variant='caption'>**Property tax history information taken from the New York City Department of Finance data sets may be incomplete, or differ slightly from actual tax bills.</Typography>
                    </div>
                </TableContainer>}
        </div>
    );
}