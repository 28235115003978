import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Neighborhood } from '../Models/Neighborhood';
import { AppBar, createStyles, FormControl, IconButton, List, ListItem, makeStyles, MenuItem, Select, Slide, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import { Answer, ScoreInfo } from '../Models/Info';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: '#0404fc'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);




type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;

export interface EditNeighborhoodDialogProps {
    open: boolean,
    onClose: (n: any) => void,
    neighborhood: Neighborhood,
    answers: Answer[]
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditNeighborhoodDialog(props: EditNeighborhoodDialogProps) {
    const classes = useStyles();
    const [neighborhood, setNeighborhood] = useState<Neighborhood>({} as Neighborhood);
    const ref = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line
    const [map, setMap] = useState<GoogleMap>();

    useEffect(() => {
        if (props.open === true && neighborhood.id == null) {
            setNeighborhood(props.neighborhood)
            
        }
        if(neighborhood.id != null)
        {
            initMap(new google.maps.LatLng(Number(props.neighborhood.latCenter), Number(props.neighborhood.lngCenter)))
        }
        if(props.open === false && neighborhood.id != null)
        {
            setNeighborhood({} as Neighborhood);
        }

    }, [props, neighborhood])

    const handleClose = () => {
        props.onClose(null);
    };

    const initMap = (address: GoogleLatLng) => {
        if (ref.current) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: 12,
                    center: address,
                    mapTypeControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    panControl: false,
                    zoomControl: true,
                    gestureHandling: 'greedy',
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggableCursor: 'pointer',
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [
                                { visibility: "off" }
                            ]
                        },
                        {
                            featureType: "transit",
                            stylers: [{ visibility: "off" }],
                        },
                    ]
                })
            )
        }
    }

    const handleChangeLat = (event: React.ChangeEvent<HTMLInputElement>) => {
        var temp = Object.assign({}, neighborhood) as Neighborhood;
        temp.latCenter = event.target.value;
        setNeighborhood(temp);

    };

    const handleChangeFact = (event: React.ChangeEvent<{ value: unknown }>, questionIndex: number) => {
        var temp = Object.assign({}, neighborhood) as Neighborhood;
        temp.scoreInfo[questionIndex].fact = event.target.value as string;
        setNeighborhood(temp);

    };

    const handleChangeLng = (event: React.ChangeEvent<HTMLInputElement>) => {
        var temp = Object.assign({}, neighborhood) as Neighborhood;
        temp.lngCenter = event.target.value;
        setNeighborhood(temp);

    };

    const handleChangeAnswer = (event: React.ChangeEvent<{ value: unknown }>, questionIndex: number) => {
        var temp = Object.assign({}, neighborhood) as Neighborhood;
        temp.scoreInfo[questionIndex].answerId = event.target.value as number;
        temp.scoreInfo[questionIndex].score = props.answers.find(a => a.internalId === event.target.value as number)?.score as number;
        temp.scoreInfo[questionIndex].answerDescription = props.answers.find(a => a.internalId === event.target.value as number)?.description as string;
        setNeighborhood(temp);
    }

    const handleSave = () => {
        props.onClose(neighborhood);
    };
    return (
        <Dialog TransitionComponent={Transition} fullScreen open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            {neighborhood.name != null && <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {neighborhood.name}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSave}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>}
            <DialogContent>
                <div style={{ display: "flex", marginTop: "15px" }}>
                    <TextField style={{ width: "49%", marginRight: "2%" }} onChange={handleChangeLat} value={neighborhood.latCenter} id="lat" label="Lat Center" />
                    <TextField style={{ width: "49%" }} onChange={handleChangeLng} value={neighborhood.lngCenter} id="lng" label="Lng Center" />
                </div>
                <List>
                    {neighborhood.scoreInfo != null && neighborhood.scoreInfo.map((s: ScoreInfo, i: number) => {
                        return (
                            <ListItem style={{ display: "flex", marginTop: "10px" }} key={i}>
                                <div style={{ width: "33%" }}>
                                    <Typography variant="body1">{s.questionDescription}</Typography>
                                </div>
                                <div style={{ width: "30%", marginLeft: "3%" }}>
                                    <FormControl style={{ width: "100%" }}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={s.answerId}
                                            onChange={(e) => {
                                                handleChangeAnswer(e, i)
                                            }}
                                        >
                                            {props.answers.map((a, j) => {
                                                if (a.questionId === s.questionId) {
                                                    return (
                                                        <MenuItem key={j} value={a.internalId}>{a.description} ({a.score})</MenuItem>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ width: "31%", display: "flex", marginLeft: "2%" }}>
                                    <TextField style={{ width: "100%" }} onChange={(e) => {
                                        handleChangeFact(e, i)
                                    }} value={s.fact != null ? s.fact : ""}
                                        helperText={s.additionalInfo} />
                                </div>
                            </ListItem>

                        )
                    })}
                </List>
                <Typography variant="h6">Map View</Typography>
                {neighborhood && <div ref={ref} style={{ height: "250px", width: "30%", marginLeft: "auto", marginRight: "auto" }} className="map-container__map"></div>}
            </DialogContent>

        </Dialog>
    );
}
