import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chart, Bubble } from 'react-chartjs-2';
import { baseEndpoint } from '../App';
import { LandlordCases} from '../Models/Neighborhood';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "absolute",
      backgroundColor: "white",
      zIndex: 999,
      height: "90%",
      width: "99%"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function CourtCaseChart(props: any) {
  const classes = useStyles();
  const [landlordId, setLandlordId] = useState<number>();
  const [data, setData] = useState<any>({} as any);
  const [options, setOptions] = useState<any>({} as any);
  Chart.defaults.maintainAspectRatio = false;
  
  var id = queryString.parse(props.location.search, { arrayFormat: 'index' }).id
  if (id && id !== landlordId) {
    fetchChart(id);
  }




  async function fetchChart(id: number) {
    try {
      await fetch(baseEndpoint + 'landlords/cases').then(async (response) => {
        if (response.ok) {
          var r = await response.json() as LandlordCases[];
          var d = { datasets: [] as any[] };
          var selectedX = Number(r.filter(p => p.landlordId === Number(id) && p.questionId === 71)[0].fact);
          var selectedY = Number(r.filter(p => p.landlordId === Number(id) && p.questionId === 70)[0].fact);
          var locations = Number(r.filter(p => p.landlordId === Number(id) && p.questionId === 67)[0].fact);

          if(locations < 10)
          {
            var size = 2 + locations / 10;
          }
          else if(locations < 50)
          {
            size = 6 + locations / 50 ;
          }
          else if(locations < 100){
               size = 15 + locations / 100
          }
          else if(locations < 250)
          {
             size = 30 + locations / 250;
          }
          else{
             size = 35 + locations / 1000;
          }
          var selectedSet = {
            label: r.filter(l => l.landlordId === Number(id) && l.questionId === 70)[0].name,
            data: [{ x: selectedX, y: selectedY, r: size }],
            borderColor: "#FFFF00",
            backgroundColor: "#17d703"
          }

          d.datasets.push(selectedSet);
          var set = {
            label: "Other Landlords",
            data: [] as any[],
            backgroundColor: "#0404fc20",
            borderColor: "#0404fc"
          }

          r.forEach(l => {
            if (l.landlordId !== Number(id)) {
              if (l.questionId === 71) {
                var x = Number(l.fact);
                var y = Number(r.filter(p => p.landlordId === l.landlordId && p.questionId === 70)[0].fact);
                var locations = Number(r.filter(p => p.landlordId === l.landlordId && p.questionId === 67)[0].fact);
                console.log(r.filter(p => p.landlordId === l.landlordId && p.questionId === 67)[0].fact)
                if(locations < 10)
                {
                  var size = 2 + locations / 10;
                }
                else if(locations < 50)
                {
                  size = 6 + locations / 50 ;
                }
                else if(locations < 100){
                     size = 15 + locations / 100
                }
                else if(locations < 250)
                {
                   size = 30 + locations / 250;
                }
                else{
                   size = 35 + locations / 1000;
                }
                set.data.push({ x: x, y: y, r: size });


              }
            }

          });
          var o = {
            scales: {
              y: {
                min:0,
                suggestedMax: 140,
                title: {
                  display: true,
                  text: 'Number or cases named as defendant',
                  font:{
                    size: 18
                  }
                },
                ticks: {
                  stepSize: 5
                }
              },
              x: {
                min:0,
                suggestedMax: 115,
                title: {
                  text: "Number of cases named as plaintiff",
                  display: true,
                  font:{
                    size: 18
                  }
                },
                ticks: {
                  stepSize: 5
                }
              }
            },
            plugins: {
              title:{
                display: true,
                text: "Lawsuits",
                font:{
                  wieght: "bold",
                  size: 25
                },
                padding: 5,
                position: "top",
                align: "start"
              },
              subtitle:{
                position: "bottom",
                text: "**Bubble size is an approximate representation of the number of properties the landlord owns.",
                display: true,
                align: "start"
              },
              legend: {
                display: true,
                position: "bottom",
                align: "start",
                labels:{
                  padding: 8,
                  font:{
                    size: 15
                  }
                },
              }
            },
            animation: false

          };

          setOptions(o);
          d.datasets.push(set);

          setLandlordId(id);
          setData(d);
        }
      })
    }
    catch (ex) {

    }
  }

  return (
    <div className={classes.root}>
      {data.datasets &&<div style={{ position: "absolute", top: "2%", left: "0%", height: "100%", width: "96%", marginLeft: "2%",backgroundColor: "white" }}>
        <Bubble id="chart"  data={data} options={options} />
        </div>}
    </div>
  );
}