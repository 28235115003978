
import { Checkbox, createStyles, Dialog, DialogContent, DialogTitle, Link, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import {
  CreditCardInput, SquarePaymentsForm
} from 'react-square-web-payments-sdk';
import { connect } from 'react-redux';
import { baseEndpoint } from '../App';
import React, { useEffect, useState } from 'react';
import TermsDialog from '../InfoPages/TermsDialog';
import { Alert } from '@material-ui/lab';
import { BETA_PRICE } from './Cart';
const config = require("../env.json")


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        color: '#0404fc',
        borderWidth: "3px"
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#0404fc',
        borderWidth: "3px"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: "translate(14px, -6px) scale(0.75)"
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
      },
    },
    inputError: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        borderWidth: "3px"
      },
      '& .MuiInput-underline:after': {
        borderWidth: "3px"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -6px) scale(0.75)"
    },
    '& .MuiOutlinedInput-input':{
      paddingTop: "12px",
      paddingBottom: "12px"
    },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderWidth: "3px"
        },
      },
    }
  }),
);

export interface BillingDialogProps {
  open: boolean,
  onClose: (value: any[]) => void,
  cartState: any,
  userState: any
}
export function BillingDialog(props: BillingDialogProps) {
  const { onClose, open } = props;
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [addressError, setAddressError] = useState<boolean>(false);
  const [city, setCity] = useState<string>("");
  const [cityError, setCityError] = useState<boolean>(false);
  const [state, setState] = useState<string>("");
  const [stateError, setStateError] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [agreeError, setAgreeError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const classes = useStyles();

  const handleClose = () => {
    setFirstNameError(false);
    setLastNameError(false);
    setAddressError(false);
    setCityError(false);
    setStateError("");
    setEmailError("");
    setFirstName("");
    setLastName("");
    setAddress("");
    setState("");
    setCity("");
    setEmail("");
    setAgreeToTerms(false);
    setErrorMessage("");
    onClose([] as any[]);

  };

  useEffect(() => {
    if (props.open && props.userState.isAuth) {
      setFirstName(props.userState.user.firstName);
      setLastName(props.userState.user.lastName);
      setEmail(props.userState.user.emailAddress);
    }
  }, [props.userState, props.open])

  const checkout = async (token: string, zip: string) => {
    try {
      setErrorMessage("");
      var ids = [] as number[];
      props.cartState.items.forEach((c: any) => {
        ids.push(c.addresses[0].internalId);
      })
      var j = {
        sourceId: token,
        amount: (props.cartState.items.length * BETA_PRICE * 100).toString(),
        addresses: ids,
        email: email,
        billingAddress: {
          add: address,
          city: city,
          state: state,
          zip: zip
        }
      };
      var requestOptions = {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + props.userState.user.currentToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(j)
      }
      await fetch(baseEndpoint + "checkout/pay", requestOptions).then(async response => {
        if (response.ok) {
          var r = await response.json() as any[];
          if (r[0].status === "COMPLETED") {
            setAgreeToTerms(false);
            setErrorMessage("");
            onClose(r as any[]);
          }
        }
        else {
          var error = await response.text()
          if (error === "Invalid session!") {
            setErrorMessage(error + " Try signing out and back in!");
          }
          else if(error === "HTTP Response Not OK")
          {
            setErrorMessage("Invalid card entered please try again!")
          }
        }
      })
    }
    catch (error: any) {
      if(error === "HTTP Response Not OK")
          {
            setErrorMessage("Invalid card entered please try again!")
          }
    }

  }

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  }

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  }

  const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
  }

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value.toUpperCase());
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleChangeAgreeToTerms = () => {
    setAgreeToTerms(!agreeToTerms);
    if (agreeToTerms === false && agreeError === true) {
      setAgreeError(false);
    }
  }

  const handleOpenTerms = () => {
    setTermsOpen(true);
  }

  const handleCloseTerms = () => {
    setTermsOpen(false);
  }
  return (
    <Dialog fullWidth={true} maxWidth={"sm"} onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">Billing Info</DialogTitle>
      <DialogContent style={{ marginLeft: "12px" }}>
        {errorMessage !== "" && <Alert variant='filled' style={{ marginBottom: "15px" }} severity='error'>{errorMessage}</Alert>}
        <form style={{ marginBottom: "30px", width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              style={{ width: "49%", marginRight: "2%" }}
              className={!firstNameError ? classes.input : classes.inputError}
              id="firstName"
              label="First Name *"
              variant="outlined"
              value={firstName}
              onChange={handleChangeFirstName}
              helperText={firstNameError ? "**First name is required" : ""}
              error={firstNameError}
            />
            <TextField
              style={{ width: "49%" }}
              className={!lastNameError ? classes.input : classes.inputError}
              id="lastName"
              label="Last Name *"
              variant="outlined"
              value={lastName}
              onChange={handleChangeLastName}
              helperText={lastNameError ? "**Last name is required" : ""}
              error={lastNameError}
            />
          </div>
          <div style={{ width: "100%", marginTop: firstNameError === true || lastNameError === true ? "5px" : "10px" }}>
            <TextField
              style={{ width: "100%" }}
              className={!addressError ? classes.input : classes.inputError}
              id="address"
              label="Address *"
              variant="outlined"
              value={address}
              onChange={handleChangeAddress}
              helperText={addressError ? "**Address is required" : ""}
              error={addressError}
            />
          </div>
          <div style={{ width: "100%", marginTop: addressError === true ? "5px" : "10px" }}> 
            <TextField
              style={{ width: "65%", marginRight: "3%" }}
              className={!cityError ? classes.input : classes.inputError}
              id="city"
              label="City *"
              variant="outlined"
              value={city}
              onChange={handleChangeCity}
              helperText={cityError ? "**City is required" : ""}
              error={cityError}
            />
            <TextField
              style={{ width: "32%" }}
              className={stateError === "" ? classes.input : classes.inputError}
              id="state"
              label="State *"
              variant="outlined"
              value={state}
              onChange={handleChangeState}
              helperText={stateError}
              error={stateError !== ""}
            />
          </div>
          <div style={{ width: "100%", marginTop: cityError === true || stateError !== "" ? "5px" : "10px" }}>
            <TextField
              style={{ width: "100%" }}
              className={emailError === "" ? classes.input : classes.inputError}
              id="email"
              label="Email *"
              variant="outlined"
              value={email}
              onChange={handleChangeEmail}
              helperText={emailError}
              error={emailError !== ""}
            />
          </div>
          <div style={{ width: "100%", marginTop:  emailError !== "" ? "5px" : "10px", display: "flex" }}>
            <Checkbox
              value={agreeToTerms}
              onChange={handleChangeAgreeToTerms}
              color='primary'
              style={{ color: agreeError === false ? "#0404fc" : "#f44336" }}
              name="terms"
            />
            <Typography style={{ paddingTop: "9px", color: agreeError === false ? "initial" : "#f44336" }} variant="body1">{agreeError === false ? "I agree to the" : <strong>**I agree to the</strong>} <Link onClick={handleOpenTerms} style={{ cursor: "pointer" }}>terms of use</Link> </Typography>
          </div>
        </form>
        <div style={{ marginBottom: "15px" }}>
          <SquarePaymentsForm
            /**
             * Identifies the calling form with a verified application ID
             * generated from the Square Application Dashboard.
             */
            applicationId={config.REACT_APP_SQUARE_ID}
            /**
             * Invoked when payment form receives the result of a tokenize generation request.
             * The result will be a valid credit card or wallet token, or an error.
             */
            cardTokenizeResponseReceived={(token: any, buyer: any) => {

              var stateRegEx = new RegExp('^((A[LKSZR])|(C[AOT])|(D[EC])|(F[ML])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EHDAINSOT])|(N[EVHJMYCD])|(MP)|(O[HKR])|(P[WAR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TIA])|(W[AVIY]))$');
              var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
              var stateMatch = stateRegEx.test(state);
              var emailMatch = emailRegEx.test(email);
              if (firstName.trim() === "") {
                setFirstNameError(true)
              }
              else if (firstNameError === true) {
                setFirstNameError(false);
              }
              if (lastName.trim() === "") {
                setLastNameError(true);
              }
              else if (lastNameError === true) {
                setLastNameError(false);
              }
              if (address.trim() === "") {
                setAddressError(true)
              }
              else if (addressError === true) {
                setAddressError(false)
              }
              if (city.trim() === "") {
                setCityError(true)
              }
              else if (cityError === true) {
                setCityError(false);
              }
              if (state === "") {
                setStateError("**State is required");
              }
              else if (stateMatch === false) {
                setStateError("**Invalid state");
              }
              else if (stateError !== "") {
                setStateError("");
              }
              if (email.trim() === "") {
                setEmailError("**Email required");
              }
              else if (emailMatch === false) {
                setEmailError("**Invalid email")
              }
              else if (emailError !== "") {
                setEmailError("");
              }
              if (agreeToTerms === false) {
                setAgreeError(true)
              }

              if (buyer && token.status === "OK" && firstName !== "" && lastName !== "" && address !== "" && city !== "" && state !== "" && stateMatch !== false && email !== "" && emailMatch !== false && agreeToTerms === true) {
                checkout(token.token, token.details.billing.postalCode);
              }
            }}
            /**
             * This function enable the Strong Customer Authentication (SCA) flow
             *
             * We strongly recommend use this function to verify the buyer and
             * reduce the chance of fraudulent transactions.
             */
            createVerificationDetails={() => ({
              amount: (props.cartState.items.length * BETA_PRICE).toString(),
              /* collected from the buyer */
              billingContact: {
                addressLines: [address],
                familyName: firstName,
                givenName: lastName,
                countryCode: 'US',
                city: city,
                state: state
              },
              currencyCode: 'USD',
              intent: 'CHARGE',
            })}
            /**
             * Identifies the location of the merchant that is taking the payment.
             * Obtained from the Square Application Dashboard - Locations tab.
             */
            locationId={config.REACT_APP_LOCATION_ID}
          > 
            <CreditCardInput
              text={"Total $" + (props.cartState.items.length * BETA_PRICE)}
            />

          </SquarePaymentsForm>
        </div>
      </DialogContent>
      <TermsDialog open={termsOpen} onClose={handleCloseTerms} />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  cartState: state.cartState,
  userState: state.userState
});
export default connect(mapStateToProps)(BillingDialog);
