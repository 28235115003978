import React from 'react';
import './App.css';
import Header from './Header/Header';
import Home from './Home/Home';
import Footer from './Footer/Footer';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import RequestProperty from './RequestProperty/RequestProperty';
import PropertyList from './PropertyList/PropertyList';
import PropertyPage from './PropertyPage/PropertyPage';
import PopularityChart from './Charts/PopularityChart';
import IncomeChart from './Charts/IncomeChart';
import PopDensityChart from './Charts/PopDensityChart';
import CourtCaseChart from './Charts/CourtCaseChart';
import AboutPage from './InfoPages/AboutPage';
import ContactUsPage from './InfoPages/ContactUsPage';
import Cart from './Cart/Cart';
import { connect } from 'react-redux';
import SignUp from './SignUp/SignUp';
import TermsPage from './InfoPages/TermsPage';
import AdminDashboard from './Dashboards/AdminDashboard';
import HomeMobile from './Home/HomeMobile';
import MobileFooter from './Footer/MobileFooter';
import PropertyListMobile from './PropertyList/PropertyListMobile';
import RequestPropertyMobile from './RequestProperty/RequestPropertyMobile';
import PropertyPageMobile from './PropertyPage/PropertyPageMobile';
import CartMobile from './Cart/CartMobile';
import AboutPageMobile from './InfoPages/AboutPageMobile';
import MapPage from './MapPage/MapPage';
import TaxChart from './Charts/TaxChart';
const config = require("./env.json")
export const baseEndpoint = config.REACT_APP_API_URL + "api/";
//export const baseEndpoint = "https://localhost:44381/api/";
export const isMobile = window.innerWidth <= 786;
function App(props: any) {
  const isMobile = window.innerWidth <= 768;
  console.log(isMobile);
  const history = useHistory()
  return (

    <div className="App">
      <Header isMobile={isMobile} />
      {isMobile === false ?
        //Desktop
        <Switch>
          <Route
            path="/home">
            <Home />
          </Route>
          <Route
            path="/requestProperty">
            <RequestProperty />
          </Route>
          <Route
            path="/about">
            <AboutPage />
          </Route>
          <Route
            path="/terms">
            <TermsPage />
          </Route>
          <Route
            path="/contact">
            <ContactUsPage />
          </Route>
          <Route
            path="/properties">
            <PropertyList history={history} />
          </Route>
          <Route
            path="/cart">
            <Cart />
          </Route>
          <Route
            path="/taxChart"
            render={(props: any) => (
              <TaxChart {...props} />
            )}/>
          <Route
            path="/property/:id"
            render={(props: any) => (
              <PropertyPage {...props} />
            )}
          />
          {props.userState.isAuth === false && <Route
            path="/signup"
            render={(props: any) => (
              <SignUp {...props} />
            )}
          />}
          {props.userState.isAuth === true && props.userState.user.category === 1 && baseEndpoint.includes("dev") && <Route
            path="/dashboard"
            render={(props: any) => (
              <AdminDashboard {...props} />
            )}
          />}
          <Route
            path="/f53a04fe-f8cd-11eb-9a03-0242ac130003"
            render={(props: any) => (
              <PopularityChart {...props} />
            )}
          />
          <Route
            path="/c8800a5a-0b9f-11ec-9a03-0242ac130003"
            render={(props: any) => (
              <IncomeChart {...props} />
            )}
          />
          <Route
            path="/a3d39f32-0e06-11ec-82a8-0242ac130003"
            render={(props: any) => (
              <PopDensityChart {...props} />
            )}
          />
          <Route
            path="/63beca36-19d0-11ec-9621-0242ac130002"
            render={(props: any) => (
              <CourtCaseChart {...props} />
            )}
          />
          <Route
            path="/mappage"
            render={(props: any) => (
              <MapPage {...props} />
            )}
          />
          <Redirect from="/" to="/home" />
        </Switch> :
        //Mobile
        <Switch>
          <Route
            path="/home">
            <HomeMobile />
          </Route>
          <Route
            path="/about">
            <AboutPageMobile />
          </Route>
          <Route
            path="/properties">
            <PropertyListMobile history={history} />
          </Route>
          <Route
            path="/requestProperty">
            <RequestPropertyMobile />
          </Route>
          <Route
            path="/terms">
            <TermsPage />
          </Route>
          <Route
            path="/property/:id"
            render={(props: any) => (
              <PropertyPageMobile {...props} />
            )}
          />
          <Route
            path="/contact">
            <ContactUsPage />
          </Route>
          <Route
            path="/cart">
            <CartMobile />
          </Route>
          {props.userState.isAuth === false && <Route
            path="/signup"
            render={(props: any) => (
              <SignUp {...props} />
            )}
          />}
          <Redirect from="/" to="/home" />
        </Switch>}
      {isMobile === false ? <Footer /> : <MobileFooter />}
    </div>

  );
}
const mapStateToProps = (state: any) => ({
  userState: state.userState,
});
export default connect(
  mapStateToProps
)(App);
