import cartReducer from "./Reducers/CartReducer";
import storage from 'redux-persist/lib/storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import userReducer from "./Reducers/UserReducer";
import thunk from "redux-thunk";

const reducers: any = { 
  cartState: cartReducer,
  userState: userReducer
};

export const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel1,
}

const rootReducer: any = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);


export const configureStore = () => 
  createStore(
    persistedReducer, applyMiddleware(thunk)
  )

  export const store = configureStore();
export const persistor = persistStore(store) ;