import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import { createUser } from '../Actions/thunks';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isMobile } from '../App';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "15px",
            textAlign: "left",
            paddingBottom: "25px"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        input: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                color: '#0404fc',
                borderWidth: "3px",
                backgroundColor: "white"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
              transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input':{
              paddingTop: isMobile === false ? "12px": "10px",
              paddingBottom:  isMobile === false ? "12px": "10px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
            },
        },
        inputError: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderWidth: "3px"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
              transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input':{
              paddingTop: "12px",
              paddingBottom: "12px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "3px"
                },
            },
        }
    }),
);

function SignUp(props: any) {
    const classes = useStyles();
    const [category, setCategory] = useState<string>("");
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<boolean>(false);
    const [company, setCompany] = useState<string>("");
    const [companyError, setCompanyError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [confirmPass, setConfirmPass] = useState<string>("");
    const [confirmError, setConfirmError] = useState<boolean>(false);
    const [showPass, setShowPass] = useState<boolean>(false);
    console.log(props.userState)


    const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCategory(event.target.value as string);
    };

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }

    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleChangeConfirmPass = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPass(event.target.value);
    }

    const handleChangeShowPass = () => {
        setShowPass(!showPass)
    }

    async function createAccount() {
        var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
        var emailMatch = emailRegEx.test(email);
        if (category === "") {
            setCategoryError(true)
        }
        else if (categoryError === true) {
            setCategoryError(false);
        }
        if (firstName.trim() === "") {
            setFirstNameError(true);
        }
        else if (firstNameError === true) {
            setFirstNameError(false);
        }
        if (lastName.trim() === "") {
            setLastNameError(true);
        }
        else if (lastNameError === true) {
            setLastNameError(false);
        }
        if (category === "broker" && company.trim() === "") {
            setCompanyError(true)
        }
        else if (companyError === true) {
            setCompanyError(false);
        }
        if (email.trim() === "") {
            setEmailError("**Email required");
        }
        else if (emailMatch === false) {
            setEmailError("**Invalid email")
        }
        else if (emailError !== "") {
            setEmailError("");
        }
        if (password.trim().length < 8) {
            setPasswordError(true);
        }
        else if (passwordError === true) {
            setPasswordError(false);
        }
        if (password !== confirmPass) {
            setConfirmError(true);
        }
        else if (confirmError === true) {
            setConfirmError(false);
        }
        if (category !== "" && firstName.trim() !== "" && lastName.trim() !== "" && (category !== "broker" || company.trim() !== "") && email.trim() !== "" && emailMatch === true && password.trim().length >= 8 && password === confirmPass) {
           
            props.createUser({ category: category, firstName: firstName, lastName: lastName, company: company, email: email, password: password });
        }
    }

    return (
        <div style={{ flexGrow: 1 }}>
            <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "17px", marginLeft: "17px" }} />
            <Paper className={classes.root}>
                <Typography style={{ marginLeft: "15px", marginRight: "15px" }} variant="h4">Create User Profile</Typography>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    createAccount();
                }
                } style={{ width: isMobile === false ? "65%" : "85%", marginLeft: "auto", marginRight: "auto", marginTop: "15px" }}>
                    {props.userState.signupMessage !== "" && <Alert style={{marginBottom: "15px", padding: "0px 10px"}} severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {props.userState.signupMessage}
                    </Alert>}
                    <FormControl error={categoryError} variant="outlined" style={{ width: "100%" }} className={categoryError ? classes.inputError : classes.input} >
                        <InputLabel id="category-label-id">Category *</InputLabel>
                        <Select

                            labelId="category-label-id"
                            id="category"
                            onChange={handleChangeCategory}
                            value={category}
                        >
                            <MenuItem value={"tenant"}>Tenant</MenuItem>
                            <MenuItem value={"broker"}>Broker</MenuItem>
                            <MenuItem value={"landlord"}>Landlord</MenuItem>
                        </Select>
                        {categoryError && <FormHelperText>**Required</FormHelperText>}
                    </FormControl>
                    <div style={{ width: "100%", display: isMobile === false ? "flex" : "block", marginTop: categoryError ? "5px" : "10px" }}>
                        <TextField
                            id="first-name"
                            label="First Name *"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            variant="outlined"
                            className={firstNameError ? classes.inputError : classes.input}
                            error={firstNameError}
                            helperText={firstNameError ? "**Required" : ""}
                            style={{ width: isMobile === false ? "49%" : "100%", marginRight: "2%" }}
                        />
                        <TextField
                            id="last-name"
                            label="Last Name *"
                            value={lastName}
                            onChange={handleChangeLastName}
                            variant="outlined"
                            className={lastNameError ? classes.inputError : classes.input}
                            error={lastNameError}
                            helperText={lastNameError ? "**Required" : ""}
                            style={{ width: isMobile === false ? "49%" : "100%", marginTop: isMobile === false ? "0px" : "10px" }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", marginTop: firstNameError || lastNameError ? "5px" : "10px" }}>
                        <TextField
                            id="company"
                            label={category === "broker" ? "Company *" : "Company"}
                            value={company}
                            onChange={handleChangeCompany}
                            variant="outlined"
                            className={companyError ? classes.inputError : classes.input}
                            error={companyError}
                            helperText={category === "broker" ? (companyError ? "**Required for brokers" : "Required for brokers") : ""}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", marginTop: category === "broker" ? "5px" : "10px" }}>
                        <TextField
                            id="email"
                            label="Email Address *"
                            value={email}
                            onChange={handleChangeEmail}
                            variant="outlined"
                            className={emailError !== "" ? classes.inputError : classes.input}
                            error={emailError !== ""}
                            helperText={emailError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
                        <TextField
                            id="phone"
                            label="Phone"
                            value={phone}
                            onChange={handleChangePhone}
                            variant="outlined"
                            className={classes.input}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", marginTop: "10px" }}>
                        <TextField
                            id="password"
                            label="Password *"
                            value={password}
                            type={showPass ? "text" : "password"}
                            onChange={handleChangePassword}
                            variant="outlined"
                            className={passwordError ? classes.inputError : classes.input}
                            error={passwordError}
                            helperText={passwordError ? "**Must be at least 8 characters" : "Must be at least 8 characters"}
                            style={{ width: "100%" }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleChangeShowPass}
                                        >
                                            {showPass ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>

                            }}
                        />
                    </div>
                    <div style={{ width: "100%", display: "flex", marginTop: "5px", marginBottom: "25px" }}>
                        <TextField
                            id="confirm"
                            label="Confirm Password *"
                            value={confirmPass}
                            type={showPass ? "text" : "password"}
                            onChange={handleChangeConfirmPass}
                            variant="outlined"
                            className={confirmError ? classes.inputError : classes.input}
                            error={confirmError}
                            helperText={confirmError ? "**Passwords do not match" : ""}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <Button type="submit" style={{ backgroundColor: "#17d703", color: "white" }}>Create Account</Button>
                    </div>

                </form>
            </Paper>

        </div>
    );
}
const mapStateToProps = (state: any) => ({
    userState: state.userState,
});

const mapDispatchToProps = (dispatch: any) => ({
    createUser: (value: any) => dispatch(createUser(value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUp);
