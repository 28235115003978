import { CartActionType } from "../Actions/CartActions";
import { Property } from "../Models/Property";

const initCartState = {
    items: [] as Property[],

}

const cartReducer = (state = initCartState, action: any) => {
    const { type, payload } = action;
    var newState = Object.assign({}, state);
    if (type === CartActionType.ADD_TO_CART) {

        if (newState.items.length === 0) {
            newState.items = [payload.property];
        }
        else {
            newState.items.push(payload.property);
        }
        return newState;
    }
    else if(type === CartActionType.REMOVE_FROM_CART)
    {
        newState.items = state.items.filter(p => p.addresses[0].internalId !== payload.id)
        return newState;
    }
    else if(type === CartActionType.CLEAR_CART)
    {
        newState.items = [] as Property[];
        return newState;
    }
    else {
        return state
    }

}



export default cartReducer;