import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField, FormHelperText, Snackbar } from '@material-ui/core';
import { useState } from 'react';
import { baseEndpoint } from '../App';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "10px",
      display: "block"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    input: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        color: '#0404fc',
        borderWidth: "3px",
        backgroundColor: "white"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: "translate(14px, -6px) scale(0.75)"
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
      },
    },
    inputError: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        borderWidth: "3px"
      },
      '& .MuiInput-underline:after': {
        borderWidth: "3px"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: "translate(14px, -6px) scale(0.75)"
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderWidth: "3px"
        },
      },
    }
  }),
);

function RequestProperty(props: any) {
  const classes = useStyles();
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [company, setCompany] = useState<string>("");
  const [companyError, setCompanyError] = useState<boolean>(false);
  const [category, setCategory] = useState<number>(0);
  const [categoryError, setCategoryError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [addressError, setAddressError] = useState<boolean>(false);
  const [comments, setComments] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [zipError, setZipError] = useState<string>("");
  const [requestAlertOpen, setRequestAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  useEffect(() => {
    if (props.userState.isAuth) {
      setFirstName(props.userState.user.firstName);
      setLastName(props.userState.user.lastName);
      setEmail(props.userState.user.emailAddress);
      if(props.userState.user.category !== 1)
      {
        setCategory(props.userState.user.category)
      }
    }
    else{
      setCategory(0)
      setFirstName("");
      setLastName("");
      setEmail("");
    }
  }, [props])

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(event.target.value);
  };

  const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategory(event.target.value as number);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const handleChangeZip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip(event.target.value);
  };

  const handleChangeComments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const handleSendRequest = async () => {
    var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
    var emailMatch = emailRegEx.test(email);
    if (firstName.trim() === "") {
      setFirstNameError(true);
    }
    else {
      setFirstNameError(false);
    }
    if (lastName.trim() === "") {
      setLastNameError(true);
    }
    else {
      setLastNameError(false);
    }
    if (category === 3 && company.trim() === "") {
      setCompanyError(true);
    }
    else {
      setCompanyError(false);
    }
    if (category === 0) {
      setCategoryError(true);
    }
    else {
      setCategoryError(false);
    }
    if (email.trim() === "") {
      setEmailError("**Email required");
    }
    else if (emailMatch === false) {
      setEmailError("**Invalid email")
    }
    else if (emailError !== "") {
      setEmailError("");
    }
    if (address.trim() === "") {
      setAddressError(true);
    }
    else {
      setAddressError(false);
    }
    if (zip.trim() === "") {
      setZipError("**Property zip code is required");
    }
    else if (isNaN(+zip) || zip.trim().length !== 5) {
      setZipError("**Invalid zip code")
    }
    else {
      setZipError("");
    }
    if(firstName.trim() !== "" && lastName.trim() !== "" && (category !== 3 || company.trim() !== "") && category !== 0 && email.trim() !== "" && emailMatch === true && address.trim() !== "" && zip.trim() !== "")
    {
      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Response-Type': 'application/json',
        },
        body: JSON.stringify({FirstName: firstName, LastName: lastName, EmailAddress: email, Address: address, Zip: zip, Company: company, Comments: comments, Category: category})
      }
      try {
        await fetch(baseEndpoint + "properties/request", requestOptions)
          .then(async response => {
            if(response.ok)
            {
              setAlertMessage("Thank you for your property request! We will get back to you soon!");
              setRequestAlertOpen(true);
            }
            else{
              setAlertMessage("There was an error with your request! Please try again later!");
              setRequestAlertOpen(true);
            }
          });
      }
      catch (error) {
        console.log(error);
      }
    }


  }

  const handleCloseRequestAlert = () =>{
    setRequestAlertOpen(false);
  }

  return (
    <div className={classes.root}>
      <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "17px", marginLeft: "17px" }} />
      <div style={{ marginLeft: "5%", display: "flex" }}>
        <div style={{ width: "45%", marginRight: "2%" }}>
          <img style={{ width: "553px" }} alt="Buildings on a New York City block overlaid with question marks." src="21-parslii-score-request-image.jpg" />
          <div style={{ borderStyle: "solid", borderWidth: "3px", color: "#0404fc", paddingBottom: "18px", borderColor: "#0404fc", textAlign: "left" }}>
            <Typography style={{ marginLeft: "15px", paddingTop: "12px" }} variant="h6">
              Fill out this form to let us know the properties you are interested in. We will get back to you as soon as the property analysis and scoring is complete.
              You can decide then if you want the Property Report.
            </Typography>
          </div>
          <Typography style={{ paddingTop: "12px", color: "#0404fc", float: "left", textAlign: "initial" }} variant="caption">
            Are you a commercial broker? We can produce Parslii Scores for all of your listings. Contact us and we can help.
          </Typography>

        </div>
        <div style={{ width: "48%", marginRight: "2%" }}>
          <Typography style={{textAlign: "left", marginLeft: "10px"}} variant="h6">Property Score Request Form</Typography>
          <form style={{ paddingTop: "13px" }}>
            <div style={{ width: "100%" }}>
              <TextField
                id="first-name"
                label="First Name *"
                value={firstName}
                onChange={handleChangeFirstName}
                variant="outlined"
                className={!firstNameError ? classes.input : classes.inputError}
                helperText={firstNameError ? "**First name is required" : ""}
                error={firstNameError}
                style={{ width: "48%", paddingRight: "15px" }}
              />
              <TextField
                id="last-name"
                label="Last Name *"
                value={lastName}
                onChange={handleChangeLastName}
                variant="outlined"
                className={!lastNameError ? classes.input : classes.inputError}
                helperText={lastNameError ? "**Last name is required" : ""}
                error={lastNameError}
                style={{ width: "48%" }}
              />
            </div>
            <div style={{ width: "100%", marginTop: firstNameError === true || lastNameError === true ? "5px" : "10px" }}>
              <TextField
                id="company"
                label={category === 3 ? "Company *" : "Company"}
                value={company}
                onChange={handleChangeCompany}
                variant="outlined"
                className={!companyError ? classes.input : classes.inputError}
                helperText={companyError ? "**Company is required" : ""}
                error={companyError}
                style={{ width: "calc(96% + 15px)" }}
              />
            </div>
            <div style={{ width: "100%", marginTop: companyError === true ? "5px" : "10px" }}>
              <FormControl error={categoryError} variant="outlined" style={{ width: "calc(96% + 15px)", textAlign: "left" }} className={!categoryError ? classes.input : classes.inputError} >
                <InputLabel id="category-label-id">Category *</InputLabel>
                <Select

                  labelId="category-label-id"
                  id="category"
                  onChange={handleChangeCategory}
                  value={category}
                >
                  <MenuItem value={2}>Tenant</MenuItem>
                  <MenuItem value={3}>Broker</MenuItem>
                  <MenuItem value={4}>Landlord</MenuItem>
                </Select>
                {categoryError === true && <FormHelperText>**Category is required</FormHelperText>}
              </FormControl>
            </div>
            <div style={{ width: "100%", marginTop: categoryError === true ? "5px" : "10px" }}>
              <TextField
                id="email"
                label="Email Address *"
                value={email}
                onChange={handleChangeEmail}
                variant="outlined"
                className={emailError === "" ? classes.input : classes.inputError}
                helperText={emailError}
                error={emailError !== ""}
                style={{ width: "calc(96% + 15px)" }}
              />
            </div>
            <div style={{ width: "calc(96% + 15px)", marginTop: emailError !== "" ? "5px" : "10px", backgroundColor: "#c2c2c2", padding: "7px" }}>
              <TextField
                id="address"
                label="Property Request Address *"
                value={address}
                onChange={handleChangeAddress}
                variant="outlined"
                className={!addressError ? classes.input : classes.inputError}
                error={addressError}
                style={{ width: "100%" }}
              />
              {addressError === true && <FormHelperText style={{ marginLeft: "14px" }} error={addressError}>**Property address Required</FormHelperText>}
              <div style={{ marginTop: addressError === true ? "5px" : "10px" }}>
                <TextField
                  id="zip"
                  label="Property Request Zip Code *"
                  value={zip}
                  onChange={handleChangeZip}
                  variant="outlined"
                  className={zipError === "" ? classes.input : classes.inputError}
                  error={zipError !== ""}
                  style={{ marginTop: "10px", width: "100%" }}
                />
                {zipError !== "" && <FormHelperText style={{ marginLeft: "14px" }} error={zipError !== ""}>{zipError}</FormHelperText>}
              </div>

            </div>
            <div style={{ width: "100%", marginTop: zipError !== "" ? "5px" : "10px" }}>
              <TextField
                id="comments"
                label="Comments"
                value={comments}
                multiline
                rows={5}
                onChange={handleChangeComments}
                className={classes.input}
                variant="outlined"
                style={{ width: "calc(96% + 15px)" }}
              />
            </div>
            <div style={{ paddingTop: "10px", textAlign: "right", width: "calc(96% + 15px)" }}>
              <Button onClick={handleSendRequest} style={{ backgroundColor: "#17d703", color: "black" }}>Submit Score Request Form</Button>
            </div>

          </form>
          <div style={{ backgroundColor: "#0404fc", color: "white", display: "flow-root", marginTop: "10px", paddingTop: "10px", paddingBottom: "10px" }}>
            <Typography style={{ marginRight: '10px', marginLeft: "10px", textAlign: "left" }} variant="h6">Keep looking for that perfect property</Typography>
            <Button href='/properties' style={{ backgroundColor: "#17d703", color: "black", float: "right", marginRight: "10px" }}>Browse Parslii Scores</Button>
          </div>
        </div>
      </div>
      <Snackbar anchorOrigin={{horizontal: "center", vertical: "top"}} open={requestAlertOpen} autoHideDuration={6000} onClose={handleCloseRequestAlert}>
        <Alert onClose={handleCloseRequestAlert} severity={alertMessage.includes("error") ? "error" : "success"}>
         {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
} 
const mapStateToProps = (state: any) => ({
  userState: state.userState
});
export default connect(mapStateToProps)(RequestProperty);