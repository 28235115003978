import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';

export const AddPropStepConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#0404fc',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#0404fc',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        backgroundColor: '#ccc',
        zIndex: 1,
        width: 20,
        height: 20,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        color: '#0404fc',
    },
    circle: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        backgroundColor: '#0404fc',
        color: "white",
        zIndex: 1,
        borderRadius: '50%',
        width: 20,
        height: 20,
    },
});

export function AddPropertyStepIcon(props: StepIconProps) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div style={{color: "lightgray"}} className={classes.circle} />}
        </div>
    );
}