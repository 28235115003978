import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { baseEndpoint, isMobile } from '../App';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "15px",
            textAlign: "left"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        input: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                color: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input': {
                paddingTop: "12px",
                paddingBottom: "12px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
            },
        },
        inputError: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderWidth: "3px"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input': {
                paddingTop: isMobile === false ? "12px" : "10px",
                paddingBottom: isMobile === false ? "12px" : "10px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "3px"
                },
            },
        }
    }),
);

function ContactUsPage(props: any) {
    const classes = useStyles();
    const [firstName, setFirstName] = React.useState<string>("");
    const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
    const [lastName, setLastName] = React.useState<string>("");
    const [lastNameError, setLastNameError] = React.useState<boolean>(false);
    const [emailHeader, setEmailHeader] = React.useState<string>("");
    const [headerError, setHeaderError] = React.useState<boolean>(false);
    const [emailBody, setEmailBody] = React.useState<string>("");
    const [bodyError, setBodyError] = React.useState<boolean>(false);
    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string>("");
    const [requestAlertOpen, setRequestAlertOpen] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>("");

    useEffect(() => {
        if (props.userState.isAuth) {
            setEmail(props.userState.user.emailAddress);
            setFirstName(props.userState.user.firstName);
            setLastName(props.userState.user.lastName)
        }
        else {
            setEmail("");
            setFirstName("");
            setLastName("");
        }
    }, [props])

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }

    const handleChangeEmailHeader = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailHeader(event.target.value);
    }

    const handleChangeEmailBody = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailBody(event.target.value);
    }

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const send = async () => {
        var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
        var emailMatch = emailRegEx.test(email);
        if (firstName.trim() === "") {
            setFirstNameError(true);
        }
        else if (firstNameError === true) {
            setFirstNameError(false);
        }
        if (lastName.trim() === "") {
            setLastNameError(true);
        }
        else if (lastNameError === true) {
            setLastNameError(false);
        }
        if (email.trim() === "") {
            setEmailError("**Email required");
        }
        else if (emailMatch === false) {
            setEmailError("**Invalid email")
        }
        else if (emailError !== "") {
            setEmailError("");
        }
        if (emailHeader.trim() === "") {
            setHeaderError(true);
        }
        else if (headerError === true) {
            setHeaderError(false);
        }
        if (emailBody.trim() === "") {
            setBodyError(true);
        }
        else if (bodyError === true) {
            setBodyError(false);
        }
        if (firstName.trim() !== "" && lastName.trim() !== "" && email.trim() !== "" && emailMatch === true && emailHeader.trim() !== "" && emailBody.trim() !== "") {
            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Response-Type': 'application/json',
                },
                body: JSON.stringify({ name: firstName + " " + lastName, email: email, emailHeader: emailHeader, emailBody: emailBody })
            }
            try {
                await fetch(baseEndpoint + "users/contactUs", requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            setAlertMessage("Thank you for your message! We will get back to you soon!");
                            setRequestAlertOpen(true);
                        }
                        else {
                            setAlertMessage("There was an error with your message! Please try again later!");
                            setRequestAlertOpen(true);
                        }
                    });
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    const handleCloseRequestAlert = () => {
        setRequestAlertOpen(false);
    }

    return (
        <div style={{ flexGrow: 1 }}>
            <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "17px", marginLeft: "17px" }} />
            <Paper className={classes.root}>
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h5">Contact</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="body1">Have a question, a comment, a tip, a request, a cool partnership idea, a press inquiry, an invitation to a fabulous party, or just want to say hello? We would love to hear from you!
                    Fill out the form below or email us at &nbsp;
                    <a href="mailto:info@parslii.com">info@parslii.com</a>
                </Typography>
                <br />
                <Typography style={{ paddingBottom: "25px", marginLeft: "25px", marginRight: "15px" }} variant="body1">And we will make sure it gets to the right place!
                    Thanks!

                </Typography>
                <Typography style={{ paddingLeft: "25px" }} variant="h5">Send Us a Message</Typography>
                <form style={{ paddingTop: "13px", paddingBottom: "15px", paddingLeft: "25px" }}>
                    <div style={{ width: "98%", paddingTop: "15px" }}>
                        <TextField
                            id="email"
                            label="Email"
                            value={email}
                            onChange={handleChangeEmail}
                            variant="outlined"
                            className={emailError === "" ? classes.input : classes.inputError}
                            helperText={emailError}
                            error={emailError !== ""}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "100%", paddingTop: "15px" }}>
                        <TextField
                            id="first-name"
                            label="First Name"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            variant="outlined"
                            className={!firstNameError ? classes.input : classes.inputError}
                            helperText={firstNameError ? "**First name is required" : ""}
                            error={firstNameError}
                            style={{ width: "48%", paddingRight: "2%" }}
                        />
                        <TextField
                            id="last-name"
                            label="Last Name"
                            value={lastName}
                            onChange={handleChangeLastName}
                            variant="outlined"
                            className={!lastNameError ? classes.input : classes.inputError}
                            helperText={lastNameError ? "**Last name is required" : ""}
                            error={lastNameError}
                            style={{ width: "48%" }}
                        />
                    </div>
                    <div style={{ width: "98%", paddingTop: "15px" }}>
                        <TextField
                            id="email-header"
                            label="Email Header"
                            value={emailHeader}
                            onChange={handleChangeEmailHeader}
                            variant="outlined"
                            className={!headerError ? classes.input : classes.inputError}
                            helperText={headerError ? "**Email header is required" : ""}
                            error={headerError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "98%", paddingTop: "15px" }}>
                        <TextField
                            id="email-body"
                            label="Email Body"
                            value={emailBody}
                            onChange={handleChangeEmailBody}
                            multiline
                            rows={5}
                            variant="outlined"
                            className={!bodyError ? classes.input : classes.inputError}
                            helperText={bodyError ? "**Email body is required" : ""}
                            error={bodyError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ paddingTop: "10px", textAlign: "right", width: "calc(96% + 15px)" }}>
                        <Button onClick={send} style={{ backgroundColor: "#17d703", color: "white", marginRight: isMobile === true ? "2%" : "0" }}>Send</Button>
                    </div>
                </form>
            </Paper>
            <Snackbar anchorOrigin={{ horizontal: "center", vertical: "top" }} open={requestAlertOpen} autoHideDuration={6000} onClose={handleCloseRequestAlert}>
                <Alert onClose={handleCloseRequestAlert} severity={alertMessage.includes("error") ? "error" : "success"}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
const mapStateToProps = (state: any) => ({
    userState: state.userState
});
export default connect(mapStateToProps)(ContactUsPage);
