import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Chart, Scatter } from 'react-chartjs-2';
import { baseEndpoint } from '../App';
import { NeighborhoodPopular } from '../Models/Neighborhood';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "absolute",
      backgroundColor: "white",
      zIndex: 999,
      height: "90%",
      width: "100%"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function PopularityChart(props: any) {
  const classes = useStyles();
  const [neighborhoodId, setNeighborhoodId] = useState<number>();
  const [data, setData] = useState<any>({} as any);
  const [options, setOptions] = useState<any>({} as any);
  var id = queryString.parse(props.location.search, { arrayFormat: 'index' }).id
  if (id && id !== neighborhoodId) {
    fetchChart(id);
  }
  Chart.defaults.maintainAspectRatio = false;




  async function fetchChart(id: number) {
    try {
      await fetch(baseEndpoint + 'neighborhoods/popularity').then(async (response) => {
        if (response.ok) {
          var r = await response.json() as NeighborhoodPopular[];
          var d = { datasets: [] as any[] };
          var selectedX = Number(r.filter(p => p.neighborhoodId === Number(id) && p.questionId === 22)[0].fact);
          var selectedY = Number(r.filter(p => p.neighborhoodId === Number(id) && p.questionId === 23)[0].fact);
          var selectedSet = {
            label: r.filter(p => p.neighborhoodId === Number(id) && p.questionId === 23)[0].name,
            data: [{ x: selectedX, y: selectedY }],
            backgroundColor: "#17d703"
          }

          d.datasets.push(selectedSet);
          var set = {
            label: "Other NYC neighborhoods",
            data: [] as any[],
            backgroundColor: "#0404fc"
          }

          r.forEach(n => {
            if (n.neighborhoodId !== Number(id)) {
              if (n.questionId === 22) {
                var x = Number(n.fact);
                var y = Number(r.filter(p => p.neighborhoodId === n.neighborhoodId && p.questionId === 23)[0].fact);
                set.data.push({ x: x, y: y });


              }
            }

          });
          var o = {
            pointRadius: 4.5,
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'Celebrity Residents',
                  font:{
                    size: 18
                  }
                },
                ticks: {
                  stepSize: 2
                }
              },
              x: {
                title: {
                  text: "Social Media Mentions",
                  display: true,
                  font:{
                    size: 18
                  }
                },
                ticks:{
                  stepSize: 250000
                }
              }
            },
            plugins: {
              title:{
                display: true,
                text: "Neighborhood Popularity",
                font:{
                  wieght: "bold",
                  size: 25
                },
                padding: 5,
                position: "top",
                align: "start"
              },
              legend: {
                display: true,
                position: "bottom",
                align: "start",
                labels:{
                  padding: 8,
                  font:{
                    size: 15
                  }
                },

              }
            },
            aspectRatio: 1,
            animation: false

          };
          setOptions(o);
          d.datasets.push(set);
          console.log(d);
          setNeighborhoodId(id);
          setData(d);
        }
      })
    }
    catch (ex) {

    }
  }
  console.log(data);
  return (
    <div className={classes.root}>
      <div style={{ position: "absolute", top: "2%", left: "0%", height: "100%", width: "90%", marginLeft: "5%" }}> 
        {data.datasets && <Scatter  id="chart" style={{height: "80%", width: "80%",backgroundColor: "white"}}   data={data} options={options} />}
      </div>
    </div>
  );
}