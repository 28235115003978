import { UserActionType } from "../Actions/UserActions";
import { User } from "../Models/User";

const initUserState = {
    isAuth: false,
    signupMessage: "",
    loginMessage: "",
    resetMessage: "",
    user: {} as User
}

const userReducer = (state: any = initUserState, action: any) => {

    const { type, payload } = action;
    if (type === UserActionType.LOGIN) {

        state = {
            isAuth: true,
            user: payload.user,
            signupMessage: "",
            resetMessage: "",
            loginMessage: "",
        }
        return state;
    }
    else if (type === UserActionType.SIGN_OUT) {
        state = {
            isAuth: false,
            user: {},
            signupMessage: "",
            resetMessage: "",
            loginMessage: "",
        }
        return state;
    }
    else if (type === UserActionType.USER_SIGNUP_ERROR) {
        state = {
            isAuth: false,
            user: {},
            signupMessage: payload.message,
            resetMessage: "",
            loginMessage: "",
        }
        return state;
    }
    else if (type === UserActionType.RESET_PASSWORD_ERROR) {

        state = {
            isAuth: false,
            user: {},
            signupMessage: "",
            resetMessage: payload.message,
            loginMessage: "",
        }
        return state;
    }
    else if (type === UserActionType.USER_LOGIN_ERROR) {
        state = {
            isAuth: false,
            user: {},
            signupMessage: "",
            resetMessage: "",
            loginMessage: payload.message,
        }
        return state;
    }
    else {
        return state
    }
}

export default userReducer;