import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AppBar, createStyles, IconButton, makeStyles, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Toolbar, Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { Property } from '../Models/Property';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: '#0404fc'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);




export interface taxDataDialog {
    open: boolean,
    onClose: () => void,
    property: Property
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TaxDataDialog(props: taxDataDialog) {
    const classes = useStyles();
    const [data, setData] = useState<any[]>([] as any[]);
    const [taxRate, setTaxRate] = useState<any[]>([] as any[]);


    useEffect(() => {
        if (props.property.id != null) {
            getTaxData(props.property)
            getTaxRates();
        }

    }, [props])

    const handleClose = () => {
        setData([] as any[]);
        props.onClose();
    };

    const getTaxData = async (property: Property) => {

        var requestOptions = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {
            await fetch("https://data.cityofnewyork.us/resource/yjxr-fw8i.json?block=" + property.addresses[0].block + "&lot=" + property.addresses[0].lot + "&boro=" + property.addresses[0].borough, requestOptions)
                .then(async result => {
                    var r = await result.json();
                    console.log(r);
                    setData(r.sort((a: any,b: any) => Number(b.year.split('/')[0]) - Number(a.year.split('/')[0])));
                })
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const getTaxRates = async () => {

        var requestOptions = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        try {
            await fetch("https://data.cityofnewyork.us/resource/7zb8-7bpk.json", requestOptions)
                .then(async result => {
                    var r = await result.json();
                    console.log(r);
                    setTaxRate(r);
                })
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const handleSave = () => {
        props.onClose();
    };
    return (
        <Dialog TransitionComponent={Transition} fullScreen open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            {props.property.id != null && <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {props.property.addresses[0].address + ", " + props.property.addresses[0].city + ", " + props.property.addresses[0].state + " " + props.property.addresses[0].zip}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSave}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>}
            <DialogContent>
                {data.length !== 0 && taxRate.length !== 0 && <TableContainer id="chart" component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingRight: "0px" }} align='left'>Year</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align='left'>Bldg. Class</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Market Value</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Assessed Value</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Taxable</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Tax Rate</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Property Tax</TableCell>
                                <TableCell style={{ paddingRight: "0px" }} align="left">Change Rate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((d, i) => {
                                console.log(d.year.substring(2))
                                var rateYear = taxRate.find(r => r.year === d.year.substring(2))
                                var rate = "";
                                if (d.taxclass.toString().includes('2')) {
                                    rate = rateYear.class_2;
                                }
                                else if (d.taxclass.toString().includes('4')) {
                                    rate = rateYear.class_4;
                                }
                                var deduct = d.extot2 ? d.extot2 : 0;
                                var propTax = !isNaN(d.avtot2) ? Math.round((Number(rate) / 100) * (Number(d.avtot2) - deduct)) : Math.round((Number(rate) / 100) * (Number(d.avtot)- deduct))
                                var change = 0;
                                if (i < data.length - 1) {
                                    var oldRate;
                                    if (d.taxclass.toString().includes('2')) {
                                        oldRate = taxRate.find(r => r.year === data[i + 1].year.substring(2)).class_2;
                                    }
                                    else if (d.taxclass.toString().includes('4')) {
                                        oldRate = taxRate.find(r => r.year === data[i + 1].year.substring(2)).class_4;
                                    }
                                    var oldDeduct = data[i + 1].extot2 ? data[i + 1].extot2 : 0;
                                    var old = !isNaN(d.avtot2) ? Math.round((Number(oldRate) / 100) * Number(data[i + 1].avtot2 - oldDeduct)) : Math.round((Number(oldRate) / 100) * Number(data[i + 1].avtot-oldDeduct))
                                    change = ((propTax - old) / propTax) * 100
                                }
                                return (
                                    <TableRow style={{ backgroundColor: (i % 2 === 1) ? "white" : "lightgrey" }} key={i}>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{d.year}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{d.bldgcl}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{Number(d.fullval) === 0 ? "" : "$" + Number(d.fullval).toLocaleString('en-US')}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{!isNaN(d.avtot2) ? "$" + Number(d.avtot).toLocaleString('en-US') : ""}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{!isNaN(d.avtot2) ? "$" + Number(d.avtot2).toLocaleString('en-US') : (Number(d.avtot) === 0 ? "" : "$" + Number(d.avtot).toLocaleString('en-US'))}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{(Math.round(Number(rate) * 1000)) / 1000}%</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{propTax === 0 ? "" : "$" + propTax.toLocaleString('en-US')}</TableCell>
                                        <TableCell style={{ paddingRight: "0px" }} align="left">{isNaN(Math.round(change * 100) / 100) || Math.round(change * 100) / 100 === 0 ? "" : Math.round(change * 100) / 100 + "%"}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <Typography variant='caption'>This may not be a complete data set. This is public data that comes from the New York City Department of Finance</Typography>
                </TableContainer>}
            </DialogContent>

        </Dialog>
    );
}
