export enum UserActionType {
    LOGIN = 'LOGIN',
    SIGN_OUT = "SIGN_OUT",
    USER_SIGNUP_ERROR = "USER_SIGNUP_ERROR",
    USER_LOGIN_ERROR = "USER_LOGIN_ERROR",
    RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
}

export const loginUser = (value: any) => {
    return {
        type: UserActionType.LOGIN,
        payload: {
            user: value
        }
    }
}

export const signoutUser = () =>{
    return{
        type: UserActionType.SIGN_OUT,
        payload: {}
    }
}

export const signUpError = (message: string) =>{
    return{
        type: UserActionType.USER_SIGNUP_ERROR,
        payload: {
            message: message
        }
    }
}

export const signInError = (message: string) =>{
    return{
        type: UserActionType.USER_LOGIN_ERROR,
        payload: {
            message: message
        }
    }
}

export const resetError = (message: string) =>{
    return{
        type: UserActionType.RESET_PASSWORD_ERROR,
        payload: {
            message: message
        }
    }
}



