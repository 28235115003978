import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, List, ListItem, Button, TextField, InputAdornment, IconButton, Slide, Paper, setRef } from '@material-ui/core';
import { Property } from '../Models/Property';
import { baseEndpoint } from '../App';
import { Neighborhood } from '../Models/Neighborhood';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: "10px",
            display: "flex",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        input: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                color: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
            },
        },
        toggleActive: {
            backgroundColor: "white",
            color: "black"
        },
        toggleDisabled: {
            backgroundColor: "#0404fc!important",
            color: "white!important"
        }
    }),
);

export const loadMapApi = () => {
    const mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ&libraries=places&languages=no&region=NO&v=quarterly"
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsUrl) === 0) {
            return scripts[i];
        }
    }

    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = mapsUrl;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    window.document.body.appendChild(googleMapsScript);

    return googleMapsScript;
}


type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;

export default function PropertyListMobile(props: any) {
    const classes = useStyles();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [markers, setMarkers] = useState<any[]>();
    const [map, setMap] = useState<GoogleMap>();
    const [allProperties, setAllProperties] = useState<Property[]>([] as Property[]);
    const [searchedProperties, setSearchedProperties] = useState<Property[]>([] as Property[])
    const [shownProperties, setShownProperties] = useState<Property[]>([] as Property[]);
    const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([] as Neighborhood[]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentTerm, setCurrentTerm] = useState<string>("");
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood>({ id: "-1" } as unknown as Neighborhood)
    const [viewType, setViewType] = useState<"list" | "map">("list");
    const [neighborhoodLoading, setNeighborhoodLoading] = useState<boolean>(false);
    let history = useHistory();


    useEffect(() => {

        if (!map && viewType === "map") {
            if (scriptLoaded === false) {
                const googleMapScript = loadMapApi();
                googleMapScript.addEventListener('load', function () {
                    setScriptLoaded(true);
                    initMap(new google.maps.LatLng(Number(selectedNeighborhood.latCenter), Number(selectedNeighborhood.lngCenter)))
                });
            }
            else {
                initMap(new google.maps.LatLng(Number(selectedNeighborhood.latCenter), Number(selectedNeighborhood.lngCenter)))
            }

        }
        else if (shownProperties.length > 0 && neighborhoods.length > 0 && !markers && viewType === "map") {
            createMarkers(shownProperties);
        }
        if (viewType === "list") {
            setRef(ref, null);
            setMap(undefined);
            setMarkers(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, allProperties, shownProperties, neighborhoods, selectedNeighborhood, viewType]);
    useEffect(() => {
        if (neighborhoods.length === 0) {
            if (window.location.search !== "") {
                var term = queryString.parse(window.location.search).search
                setCurrentTerm(term)
                setSearchTerm(term)
                fetchPropertiesBySearch(term);
            }

            fetchNeighborhoods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const initMap = (address: GoogleLatLng) => {
        if (ref.current) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: 12,
                    center: address,
                    mapTypeControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    panControl: false,
                    zoomControl: true,
                    gestureHandling: 'greedy',
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggableCursor: 'pointer',
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [
                                { visibility: "off" }
                            ]
                        },
                        {
                            featureType: "transit",
                            stylers: [{ visibility: "off" }],
                        },
                    ]
                })
            )
        }
    }


    async function fetchPropertiesByNeighborhood(n: Neighborhood) {
        try {
            setSelectedNeighborhood(n);
            await fetch(baseEndpoint + 'Properties/neighborhood/' + n.internalId).then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Property[];
                    setAllProperties(r);
                    setShownProperties(r);
                }
            })
        }
        catch (ex) {

        }
    }

    async function fetchPropertiesBySearch(term: string) {
        try {
            await fetch(baseEndpoint + 'Properties/search/' + term).then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Property[];
                    setSearchedProperties(r);
                }
            })
        }
        catch (ex) {

        }
    }

    function createMarkers(proper: Property[]) {
        var mark = [] as any[]
        if (!markers) {
            mark = [] as any[]
        }
        proper.forEach(p => {
            var latLng = { lat: p.addresses[0].lat, lng: p.addresses[0].lng }
            var add = p.addresses[0].address + " " + p.addresses[0].city + ", " + p.addresses[0].state + " " + p.addresses[0].zip;
            var m = new google.maps.Marker({
                position: latLng,
                icon: 'green-pin.png',
                label: {
                    text: (Math.round(p.calcScore)).toString(),
                    color: '#0404fc',
                    fontSize: "9px",
                    fontWeight: "bold"
                },
                title: add,
            })
            m.addListener('click', () => {
                history.push('/property/' + p.addresses[0].internalId.toString())
            })


            p.addresses.forEach(a => {

                var elem = document.getElementById(a.internalId.toString());
                if (!!elem) {
                    elem.onmouseover = function () {
                        m.setIcon('blue-pin.png');
                        m.setLabel({
                            text: (Math.round(p.calcScore)).toString(),
                            color: '#17d703',
                            fontSize: "10px",
                            fontWeight: "bold"
                        })
                        m.setZIndex(100);
                    }
                    elem.onmouseleave = function () {
                        m.setIcon('green-pin.png');
                        m.setLabel({
                            text: (Math.round(p.calcScore)).toString(),
                            color: '#0404fc',
                            fontSize: "9px",
                            fontWeight: "bold"
                        })
                        m.setZIndex(0);
                    }
                }
            })

            m.setMap(map as GoogleMap);
            mark.push(m);
        });
        setMarkers(mark);
    }

    async function fetchNeighborhoods() {
        setNeighborhoodLoading(true);
        try {
            await fetch(baseEndpoint + 'neighborhoods').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Neighborhood[];
                    setNeighborhoods(r);
                    setNeighborhoodLoading(false);
                }
            })
        }
        catch (ex) {

        }
    }

    const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    const search = () => {
        var url = "/properties?";
        var query = {
            search: searchTerm
        }
        url += queryString.stringify(query, { skipNull: true })
        history.push(url)
    }

    const clear = () => {
        setSearchTerm("");
        history.push("/properties")
    }

    const handleChangeViewType = (event: React.MouseEvent<HTMLElement>, type: string) => {
        if (type != null) {
            setViewType(type as "list" | "map");
        }

    };
    console.log(window.location)
    return (
        <div className={classes.root}>
            <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                        {selectedNeighborhood.id === "-1" && <Slide direction="right" in={selectedNeighborhood.id === "-1"} mountOnEnter unmountOnExit><div style={{ paddingLeft: "17px", paddingRight: "17px" }}>
                            <form onSubmit={(e: any) => {
                                search();
                                e.preventDefault();
                            }} style={{ marginTop: "10px" }}>
                                <TextField
                                    size="small"
                                    style={{ width: "100%" }}
                                    className={classes.input}
                                    id="firstName"
                                    label="Search"
                                    variant="outlined"
                                    value={searchTerm}
                                    onChange={handleChangeSearchTerm}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><IconButton type="submit" onClick={search} style={{ padding: "2px" }}><SearchIcon /></IconButton></InputAdornment>,
                                        endAdornment: searchTerm !== "" ? <InputAdornment position="end"><IconButton onClick={clear} style={{ padding: "2px" }}><ClearIcon /></IconButton></InputAdornment> : null
                                    }}
                                />
                            </form>
                            {(currentTerm === "" || neighborhoods.filter(n => n.name.toUpperCase().includes(currentTerm.toUpperCase())).length !== 0) && <Typography style={{ marginTop: "8px", textAlign: "left" }} variant="h5">Select Neighborhood:</Typography>}
                            {neighborhoodLoading === false ? <React.Fragment>
                                {(currentTerm === "" || neighborhoods.filter(n => n.name.toUpperCase().includes(currentTerm.toUpperCase())).length !== 0) && <List>
                                { neighborhoods.map((n, i) => {
                                    if (currentTerm === "" || n.name.toUpperCase().includes(currentTerm.toUpperCase())) {
                                        return (
                                            <ListItem key={i}><Button onClick={() => { fetchPropertiesByNeighborhood(n) }}>{n.name}</Button></ListItem>
                                        )
                                    }
                                    else {
                                        return null
                                    }

                                })}
                            </List>}
                                {currentTerm !== "" && searchedProperties.length !== 0 && <div>
                                    <Typography style={{ marginTop: "8px", textAlign: "left" }} variant="h5">Properties:</Typography>
                                    <List>
                                        {searchedProperties.map((p, i) => {
                                            return (p.addresses.map((a, j) => {
                                                return (<ListItem key={a.address}>
                                                    <Paper id={a.internalId.toString()} onClick={() => { history.push('/property/' + a.internalId) }} style={{ display: "flex", width: "100%" }} elevation={3}>
                                                        <div style={{ paddingLeft: "5px" }}>
                                                            <Typography variant="h6">{a.address}</Typography>
                                                            <div style={{ width: "100px", textAlign: "center", position: "relative", paddingTop: "10px" }}>

                                                                <img style={{ height: "100px" }} src="../leaf-score.png" alt="leaf" />
                                                                <Typography variant="h5" style={{ position: "absolute", top: "30%", left: "35%", color: "#0404fc" }} ><strong>{(Math.round(p.calcScore)).toString()}</strong></Typography>
                                                            </div>
                                                        </div>
                                                        <img style={{ height: "75%", marginLeft: "auto" }} alt="Not Found" src={"https://maps.googleapis.com/maps/api/streetview?size=150x150&location=" + p.addresses[0].lat + "," + p.addresses[0].lng + "&source=outdoor&heading=" + p.addresses[0].heading + "&fov=90&&key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ"} />
                                                    </Paper>
                                                </ListItem>)
                                            }))

                                        })}
                                    </List>
                                </div>}
                            </React.Fragment> :
                                <div style={{ marginTop: "15vh" }}><CircularProgress size={60} style={{ color: "#0404fc" }} /></div>
                            }

                        </div>
                        </Slide>}
                        {selectedNeighborhood.id !== "-1" && <Slide direction="left" in={selectedNeighborhood.id !== "-1"} mountOnEnter unmountOnExit>
                            <div>
                                <div style={{ textAlign: "left", display: "flex" }}>
                                    <IconButton onClick={() => {
                                        setSelectedNeighborhood({ id: "-1" } as Neighborhood)
                                        setViewType("list");
                                    }}><ArrowBackIcon /></IconButton>
                                    <Typography style={{ marginTop: "8px" }} variant="h5">{selectedNeighborhood.name}:</Typography>
                                </div>
                                {viewType === "list" && <div>

                                    <List>
                                        {shownProperties.map((p, i) => {
                                            return (p.addresses.map((a, j) => {
                                                return (<ListItem key={a.address}>
                                                    <Paper id={a.internalId.toString()} onClick={() => { history.push('/property/' + a.internalId) }} style={{ display: "flex", width: "100%" }} elevation={3}>
                                                        <div style={{ paddingLeft: "5px" }}>
                                                            <Typography variant="h6">{a.address}</Typography>
                                                            <div style={{ width: "100px", textAlign: "center", position: "relative", paddingTop: "10px" }}>

                                                                <img style={{ height: "100px" }} src="../leaf-score.png" alt="leaf" />
                                                                <Typography variant="h5" style={{ position: "absolute", top: "30%", left: "35%", color: "#0404fc" }} ><strong>{(Math.round(p.calcScore)).toString()}</strong></Typography>
                                                            </div>
                                                        </div>
                                                        <img style={{ height: "75%", marginLeft: "auto" }} alt="Not Found" src={"https://maps.googleapis.com/maps/api/streetview?size=150x150&location=" + p.addresses[0].lat + "," + p.addresses[0].lng + "&source=outdoor&heading=" + p.addresses[0].heading + "&fov=90&&key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ"} />
                                                    </Paper>
                                                </ListItem>)
                                            }))

                                        })}
                                    </List>
                                </div>}
                                <Slide direction="up" in={viewType === "map"} mountOnEnter unmountOnExit>

                                    <div style={{ height: "60vh" }}>
                                        {scriptLoaded && (
                                            <div ref={ref} style={{ height: "92%", margin: "10px 17px" }} className="map-container__map"></div>
                                        )}

                                    </div>
                                </Slide>

                                <Paper style={{ position: "fixed", bottom: 10, zIndex: 999, marginLeft: "40%" }} elevation={5}>
                                    <ToggleButtonGroup size="small" value={viewType} exclusive onChange={handleChangeViewType}>
                                        <ToggleButton classes={{ root: classes.toggleActive, selected: classes.toggleDisabled }} value="list">
                                            List
                                        </ToggleButton>
                                        <ToggleButton classes={{ root: classes.toggleActive, selected: classes.toggleDisabled }} value="map">
                                            Map
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Paper>
                            </div>
                        </Slide>}
                    </div>

                </div>

            </div>
        </div>
    );
}