import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DialogContent from '@material-ui/core/DialogContent';
import PersonIcon from '@material-ui/icons/Person';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, IconButton, InputAdornment, makeStyles, Slide, TextField, Theme } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { resetPassword } from '../Actions/thunks';
import { connect } from 'react-redux';
import { baseEndpoint, isMobile } from '../App';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            overflowX: "hidden"
        },
    }),
);

function ForgotPasswordDialog(props: any) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [confirmError, setConfirmError] = useState<boolean>(false);
    const [inputType, setInputType] = React.useState<string>("password");
    const [receivedCode, setReceivedCode] = React.useState<boolean>(false);
    const [passwordError, setPasswordError] = React.useState<boolean>(false);
    const [emailError, setEmailError] = useState<string>("");
    const [resetCode, setResetCode] = useState<string>("");
    const [codeError, setCodeError] = useState<string>("");
    const [getCodeError, setGetCodeError] = useState<string>("");
    const classes = useStyles();

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleChangeResetCode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResetCode(event.target.value);
    }

    const handleChangeConfirmPass = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    }

    const handleChangeInputType = () => {
        var newType = inputType === "password" ? "" : "password";
        setInputType(newType);
    }


    const getCode = async () => {
        var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
        var emailMatch = emailRegEx.test(email);
        if (email.trim() === "") {
            setEmailError("**No email was entered");
        }
        else if (emailMatch === false) {
            setEmailError("**Invalid email")
        }
        else if (emailError !== "") {
            setEmailError("");
        }
        if (email.trim() !== "" && emailMatch === true) {
            var requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(email)
            }
            await fetch(baseEndpoint + "users/forgotPassword", requestOptions)
                .then(async response => {
                    if (response.ok) {
                        setReceivedCode(true);
                        setGetCodeError("");
                    }
                    else {
                        var err = await response.text();
                        setGetCodeError(err);
                    }

                });

        }

    }

    const resetPassword = async () => {
        if (password.trim().length < 8) {
            setPasswordError(true);
        }
        else if (passwordError === true) {
            setPasswordError(false);
        }
        if(resetCode.trim().length > 6)
        {
            setCodeError("**Reset code is too long")
        }
        else if(resetCode.trim().length < 6)
        {
            setCodeError("**Reset code is too short");
        }
        else if(codeError !== "")
        {
            setCodeError("");
        }
        if(password !== confirmPassword)
        {
            setConfirmError(true)
        }
        else if(confirmError === true)
        {
            setConfirmError(false)
        }
        if(password.trim().length >= 8 && resetCode.length === 6 && password === confirmPassword)
        {
            await props.resetPassword(resetCode, password, email);
        }

    } 

    const backToEmail = () => {
        setReceivedCode(false);
    }
    const handleClose = () => {
        props.handleClose();
        setReceivedCode(false);
        setEmailError("");
    }


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            classes={{ paper: classes.dialog }}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
        > <div style={{ display: "flex" }}>
                <DialogTitle id="dialog-title"><span style={{ fontSize: isMobile === false ? "2.125rem": "1.5rem", fontWeight: 400, lineHeight: "1.235" }} >Reset Password</span></DialogTitle>
                <IconButton onClick={handleClose} style={{ marginLeft: "auto", height: "fit-content", color: '#D11A2A', padding: "5px", marginTop: "5px", marginRight: "5px" }}><CloseIcon fontSize="small" /></IconButton>
            </div>
            {receivedCode === false && <Slide direction="right" in={!receivedCode} mountOnEnter>
                <DialogContent>
                {getCodeError !== "" && <Alert severity={"error"}>
                    {getCodeError}
                </Alert>}
                    <form onSubmit={(event) => {
                        event.preventDefault();
                    }}>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <TextField
                                style={{ width: isMobile === false ? "50%" : "80%" }}
                                id="email"
                                label="Email"
                                value={email}
                                error={emailError !== ""}
                                helperText={emailError !== "" ? emailError : "Enter email to receive reset code."}
                                onChange={handleChangeEmail}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{ color: emailError === "" ? "black" : "#f44336" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <Button onClick={getCode} style={{ color: '#0404fc' }} type="submit" >Get code</Button>
                        </div>
                    </form>
                </DialogContent>
            </Slide>}
            {receivedCode === true && <Slide direction="left" in={receivedCode} mountOnEnter>
                <DialogContent>
                {props.userState.resetMessage !== "" && <Alert severity={"error"}>
                    {props.userState.resetMessage}
                </Alert>}
                    <form onSubmit={(event) => {
                        event.preventDefault();
                    }}>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <TextField
                                style={{ width: "50%" }}
                                id="code"
                                label="Reset Code"
                                value={resetCode}
                                onChange={handleChangeResetCode}
                                error={codeError !== ""}
                                helperText={codeError}
                                variant="standard"
                            />
                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: "5px", placeContent: "center" }}>
                            <TextField
                                style={{ width: "50%" }}
                                id="password"
                                label="New Password"
                                value={password}
                                type={inputType}
                                onChange={handleChangePassword}
                                helperText={passwordError ? "**Must be at least 8 characters" : "Must be at least 8 characters"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleChangeInputType} style={{ padding: "4px" }}>{inputType === "password" ? <Visibility /> : <VisibilityOffIcon />}</IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                variant="standard"
                            />
                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <TextField
                                style={{ width: "50%" }}
                                id="confirmPassword"
                                label="Confirm Password"
                                value={confirmPassword}
                                type={inputType}
                                error={confirmError} 
                                helperText={confirmError === true ? "**Password does not match" : ""}
                                onChange={handleChangeConfirmPass}
                                variant="standard"
                            />
                        </div>
                        <div style={{ width: "50%", display: "flex", marginBottom: "15px", marginLeft: "auto", marginRight: "auto" }}>
                            <Button onClick={backToEmail} style={{ float: "left", marginRight: "auto" }}  >Back</Button>
                            <Button onClick={resetPassword} style={{ color: '#0404fc', float: "right" }} type="submit" >Submit</Button>
                        </div>
                    </form>
                </DialogContent>
            </Slide>}
        </Dialog >
    );
}
const mapStateToProps = (state: any) => ({
    userState: state.userState,
});

const mapDispatchToProps = (dispatch: any) => ({
    resetPassword: (code: string, password: string, email: string) => dispatch(resetPassword(code, password, email))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPasswordDialog);