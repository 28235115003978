import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, List, ListItem, Button, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Address, Property } from '../Models/Property';
import { baseEndpoint } from '../App';
import { Neighborhood } from '../Models/Neighborhood';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "10px",
      display: "flex"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    input: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        color: '#0404fc',
        borderWidth: "3px"
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#0404fc',
        borderWidth: "3px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
      },
    },
  }),
);

export const loadMapApi = () => {
  const mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ&libraries=places&languages=no&region=NO&v=quarterly"
  const scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsUrl) === 0) {
      return scripts[i];
    }
  }

  const googleMapsScript = document.createElement('script');
  googleMapsScript.src = mapsUrl;
  googleMapsScript.async = true;
  googleMapsScript.defer = true;
  window.document.body.appendChild(googleMapsScript);

  return googleMapsScript;
}


type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;

export default function PropertyList(props: any) {
  const classes = useStyles();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [markers, setMarkers] = useState<any[]>();
  const [map, setMap] = useState<GoogleMap>();
  const [allProperties, setAllProperties] = useState<Property[]>([] as Property[]);
  const [shownProperties, setShownProperties] = useState<Property[]>([] as Property[]);
  const [neighborhoods, setNeighborhoods] = useState<any[]>([] as any[]);
  const [searchTerm, setSearchTerm] = useState<string>("");


  useEffect(() => {

    if (!map) {
      const googleMapScript = loadMapApi();
      googleMapScript.addEventListener('load', function () {
        setScriptLoaded(true);
        initMap(new google.maps.LatLng(40.766632, -73.977211))

      });

    }

    if (shownProperties.length > 0 && neighborhoods.length > 0 && !markers) {
      createMarkers(shownProperties);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, allProperties, shownProperties, neighborhoods]);
  useEffect(() =>{
    if(map && allProperties.length === 0)
    {
      fetchProperties();
        fetchNeighborhoods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[map])

  const initMap = (address: GoogleLatLng) => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: 12,
          center: address,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: true,
          gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'pointer',
          styles: [
            {
              featureType: "poi",
              stylers: [
                { visibility: "off" }
              ]
            },
            {
              featureType: "transit",
              stylers: [{ visibility: "off" }],
            },
          ]
        })
      )
    }
  }


  async function fetchProperties() {
    try {
      await fetch(baseEndpoint + 'Properties/list').then(async (response) => {
        if (response.ok) {
          var r = await response.json() as Property[];
          setAllProperties(r);
          setShownProperties(r);
          //createMarkers(r);
        }
      })
    }
    catch (ex) {

    }
  }

  function createMarkers(proper: Property[]) {
    var mark = [] as any[]
    if (!markers) {
      mark = [] as any[]
    }
    proper.forEach(p => {
      var latLng = { lat: p.addresses[0].lat, lng: p.addresses[0].lng }
      var add = p.addresses[0].address + " " + p.addresses[0].city + ", " + p.addresses[0].state + " " + p.addresses[0].zip;
      var m = new google.maps.Marker({
        position: latLng,
        icon: 'green-pin.png',
        label: {
          text: (Math.round(p.calcScore)).toString(),
          color: '#0404fc',
          fontSize: "9px",
          fontWeight: "bold"
        },
        title: add,
      })
      var elem = document.getElementById(p.addresses[0].internalId.toString());
      if (!!elem) {
        m.addListener('click', () => {
          elem?.click();
        })
      }

      p.addresses.forEach(a => {

        var elem = document.getElementById(a.internalId.toString());
        if (!!elem) {
          elem.onmouseover = function () {
            m.setIcon('blue-pin.png');
            m.setLabel({
              text: (Math.round(p.calcScore)).toString(),
              color: '#17d703',
              fontSize: "10px",
              fontWeight: "bold"
            })
            m.setZIndex(100);
          }
          elem.onmouseleave = function () {
            m.setIcon('green-pin.png');
            m.setLabel({
              text: (Math.round(p.calcScore)).toString(),
              color: '#0404fc',
              fontSize: "9px",
              fontWeight: "bold"
            })
            m.setZIndex(0);
          }
        }
      })

      m.setMap(map as GoogleMap);
      mark.push(m);
    });
    setMarkers(mark);
  }

  async function fetchNeighborhoods() {
    try {
      await fetch(baseEndpoint + 'neighborhoods').then(async (response) => {
        if (response.ok) {
          var r = await response.json() as Neighborhood[];
          setNeighborhoods(r);
        }
      })
    }
    catch (ex) {

    }
  }

  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  const search = () => {
    if (searchTerm !== "") {
      var temp = [] as Property[];
      allProperties.forEach(p => {
        var t = Object.assign({}, p) as Property;
        t.addresses = [] as Address[];
        p.addresses.forEach(a => {
          if (a.address.toUpperCase().includes(searchTerm.toUpperCase())) {
            t.addresses.push(a);
          }
          else if (a.masterAddress != null && a.masterAddress.toUpperCase().includes(searchTerm.toUpperCase())) {
            t.addresses.push(a);
          }
        })
        if (t.addresses.length > 0) {
          temp.push(t);
        }
      })
      if (temp.length === 0) {
        temp.push({ internalId: -1, addresses: [{ address: "No results found" }] as Address[] } as Property);
      }
      else {

        setMarkers(undefined)
      }
      var marks = markers as any[];
      marks.forEach(m => {
        m.setMap(null)
      })


      setShownProperties(temp);
    }
    else {
      temp = Object.assign([], allProperties) as Property[];
      marks = markers as any[];
      marks.forEach(m => {
        m.setMap(null)
      })
      setMarkers(undefined)
      setShownProperties(temp);
    }
  }

  const clear = () => {
    setSearchTerm("");
    var temp = Object.assign([], allProperties) as Property[];
    var marks = markers as any[];
    marks.forEach(m => {
      m.setMap(null)
    })
    setMarkers(undefined)
    setShownProperties(temp);
  }

  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "17px", marginLeft: "17px" }} />
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <div style={{ paddingLeft: "17px" }}>
              <div style={{ display: "flex" }}>
                <img alt="" style={{ padding: "5px 5px" }} src="bullet-leaf.png" />
                <Typography style={{ textAlign: "left", color: "#0404fc", paddingTop: "10px" }} variant="h4">The Parslii System</Typography>
              </div>
              <form onSubmit={(e: any) => {
                 e.preventDefault();
                search();
               
              }} style={{ marginTop: "10px", marginLeft: "50px" }}>
                <TextField
                  size="small"
                  style={{ width: "100%" }}
                  className={classes.input}
                  id="firstName"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleChangeSearchTerm}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><IconButton type="submit" onClick={search} style={{ padding: "2px" }}><SearchIcon /></IconButton></InputAdornment>,
                    endAdornment: searchTerm !== "" ? <InputAdornment position="end"><IconButton  onClick={clear} style={{ padding: "2px" }}><ClearIcon /></IconButton></InputAdornment> : null
                  }}
                />
              </form>

            </div>
            <div style={{ paddingLeft: "72px",height: "60vh", maxHeight: "60vh", overflow: "auto" }}>
              {neighborhoods.length > 0 && shownProperties.length > 0 &&  <List>
                {neighborhoods.map((n, i) => {
                  var count = shownProperties.filter(p => p.neighborhoodId === n.internalId).length;
                  if (count > 0) {
                    return (<ListItem style={{ display: "block", paddingTop: "2px", paddingBottom: "2px" }} key={i}><Typography variant="body2"><strong>{n.name}</strong></Typography>
                      <List style={{ paddingTop: "0px" }}>
                        {shownProperties.map((p, j) => {

                          if (p.neighborhoodId === n.internalId) {
                            return (
                              p.addresses.map((a, k) => {
                                var add = a.address + ", " + a.city + ", " + a.state + " " + a.zip;
                                return (<ListItem style={{ paddingTop: "1px", paddingBottom: "1px" }} key={j + " " + k}><Button href={'/property/' + a.internalId} style={{ textTransform: "none" }} id={a.internalId.toString()}> <Typography variant="body2">{add}</Typography></Button></ListItem>)
                              }));
                          }
                          else {
                            return null;
                          }
                        })}</List>

                    </ListItem>)
                  }
                  else {
                    return null;
                  }

                })}
              </List>}
              {shownProperties.length === 1 && shownProperties[0].internalId === -1 &&
              <div style={{height: "60vh"}}>
                <Typography variant="body1"><strong>No results found</strong></Typography>
                </div>
              }
            </div>
          </div>
          <div style={{ width: "50%", height: "65vh" }}>
            {scriptLoaded && (
              <div ref={ref} style={{ height: "92%", margin: "10px 17px" }} className="map-container__map"></div>
            )}
            <div style={{ width: "90%", borderWidth: "3px", borderColor: "#0404fc", borderStyle: "solid", marginLeft: "5%" }}>
              <Typography style={{ color: "#0404fc", marginBottom: "10px" }} variant="body1">We are continually analyzing properties and adding more Parslii Scores. Don't see the address you're looking for? Request a Score! It's FREE              <Button href='/requestProperty' style={{ backgroundColor: "#17d703", color: "white", marginLeft: "10px" }}>Request a Parslii Score</Button></Typography>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}