import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, createStyles, Divider, IconButton, List, ListItem, makeStyles, Popover, Theme, Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { connect } from 'react-redux';
import { signOut } from '../Actions/thunks';
import LoginDialog from './LoginDialog';
import MenuIcon from '@material-ui/icons/Menu';
import { baseEndpoint } from '../App';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#0404fc",
      color: "white",
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }),
);

function Header(props: any) {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [loginOpen, setLoginOpen] = React.useState<boolean>(false);
  const classes = useStyles();

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  const onCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  }

  const signOut = () => {
    if (loginOpen === true) {
      setLoginOpen(false);
    }
    props.signOut();
  }

  const handleOpenLogin = () => {
    setLoginOpen(true);
  }

  const handleCloseLogin = () => {
    setLoginOpen(false);
  }
  console.log(props.userState);
  return (
    <div style={props.isMobile === true ? {position:  "sticky", top: "0", zIndex: 900, backgroundColor: "white"} : undefined}>
      <div style={{ display: "flex", margin: (props.isMobile === false) ? "0 20px" : "0 0" }}>
        <div style={{ flexGrow: 1, textAlign: (props.isMobile === false) ? "left" : "left" }}>
          <Link to={'/home'}  >
            <img style={{ width: (props.isMobile === false) ? "auto" : "55%" }} alt="" src="../parslii-logo.png" />
          </Link>
        </div>
        {props.isMobile === false ? <div style={{ alignSelf: "center", textAlign: "right", width: "45%", marginRight: "2%" }}>

          {window.location.pathname !== "/properties" && <Button href='/properties' style={{ borderStyle: "solid", borderColor: "grey", borderWidth: "2px", color: "#17d703", backgroundColor: "white" }}>Browse Parslii Scores</Button>}
          <Button onClick={handleOpenMenu} style={{ borderStyle: "solid", borderColor: "grey", borderWidth: "2px", color: "#17d703", backgroundColor: "white", marginLeft: "20px" }}>Menu</Button>
          <IconButton href="/cart" style={{ backgroundColor: "white", marginLeft: "20px" }}>
            <Badge classes={{ badge: classes.badge }} badgeContent={props.cartState.items != null ? props.cartState.items.length : 0} >
              <ShoppingCartIcon style={{ color: "#17d703" }} />
            </Badge>
          </IconButton>
          <br />
          {props.userState.isAuth === true && <Typography variant="caption"><strong>Hi, {props.userState.user.firstName}!</strong></Typography>}
        </div>
        :
        <IconButton onClick={handleOpenMenu}  style={{ color: "black", backgroundColor: "white", marginRight: "10px" }}>
          <Badge classes={{ badge: classes.badge }} badgeContent={props.cartState.items != null ? props.cartState.items.length : 0} >
            <MenuIcon />
            </Badge>
            </IconButton>
      }

      </div>
      <Popover
        anchorEl={anchorEl}
        open={menuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={onCloseMenu}
      >
        <List>

          <ListItem key={2}>
            <Button style={{ width: "100%", justifyContent: "left" }} href="/requestProperty">
              Request Property Score
            </Button>
          </ListItem>
          <ListItem key={3}>
            <Button style={{ width: "100%", justifyContent: "left" }} href="/about">
              About
            </Button>
          </ListItem>
          <ListItem key={4}>
            <Button style={{ width: "100%", justifyContent: "left" }} href="/contact">
              Contact
            </Button>
          </ListItem>
          {props.isMobile === true && <ListItem key={"cart"}>
          <Button style={{ width: "100%", justifyContent: "left", whiteSpace: "nowrap" }} href="/cart">
          <Badge classes={{ badge: classes.badge }} badgeContent={props.cartState.items != null ? props.cartState.items.length : 0} >
              <span style={{paddingRight: "10px"}}>Cart</span>  
              </Badge>
            </Button>
          </ListItem>}
          {props.userState.isAuth === true && props.userState.user.category === 1 && baseEndpoint.includes("dev") &&
            <ListItem>
              <Button style={{ width: "100%", justifyContent: "left" }} href="/dashboard">
                Dashboard
              </Button>
            </ListItem>
          }
          <Divider />
          <ListItem key={1}>
            {props.userState.isAuth === false ? <Button onClick={handleOpenLogin} style={{ width: "100%", justifyContent: "left" }}>
              Sign In
            </Button> :
              <Button style={{ width: "100%", justifyContent: "left" }} onClick={signOut}>
                Sign Out
              </Button>}
          </ListItem>
        </List>
      </Popover>
      {props.userState.isAuth === false && <LoginDialog open={loginOpen} handleClose={handleCloseLogin} />}
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  cartState: state.cartState,
  userState: state.userState
});
const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);