import { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Property } from '../Models/Property';
import { baseEndpoint } from '../App';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button,  Snackbar, TextField, Typography } from '@material-ui/core';
import { loadMapApi } from '../PropertyList/PropertyList';
import { addToCart } from '../Actions/CartActions';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';
import {Color} from "@material-ui/lab/Alert"
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "10px",
      display: "flex"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    input: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        color: '#0404fc',
        borderWidth: "3px",
        backgroundColor: "white"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: "translate(14px, -6px) scale(0.75)"
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
      },
    },
  }),
);



interface PropertyPagePropes {
  id: string,

}

interface PropertyCartProps {
  cartState: any,
  addToCart: (property: Property) => string,
  userState: any
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;


function PropertyPage(props: RouteComponentProps<PropertyPagePropes> & PropertyCartProps) {
  const classes = useStyles();
  const history = useHistory()
  const [addressId, setAddressId] = useState<number>(-1);
  const [property, setProperty] = useState<Property>({} as Property);
  const [streetViewUrl, setStreetViewUrl] = useState<string>("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<any>();
  const [map, setMap] = useState<GoogleMap>();
  const [heading, setHeading] = useState<string>("-1");
  const [lat, setLat] = useState<string>("0");
  const [lng, setLng] = useState<string>("0");
  const [adminAlert, setAdminAlert] = useState<boolean>(false);
  const [adminAlertMessage, setAdminAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<Color | undefined>("success");

  useEffect(() => {

    if (property.id == null) {
      fetchProperty();
    }
    else if (!map) {
      const googleMapScript = loadMapApi();
      googleMapScript.addEventListener('load', function () {
        setScriptLoaded(true);
        initMap(new google.maps.LatLng(property.addresses[0].lat, property.addresses[0].lng))

      });
    }
    else if (map) {
      var m = marker;
      var add = property.addresses[0].address + " " + property.addresses[0].city + ", " + property.addresses[0].state + " " + property.addresses[0].zip;
      var latLng = { lat: property.addresses[0].lat, lng: property.addresses[0].lng }
      m = new google.maps.Marker({
        position: latLng,
        icon: '../green-pin.png',
        title: add,
      })
      console.log(map);
      m.setMap(map as GoogleMap);
      setMarker(m);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id, property, map]);

  const initMap = (address: GoogleLatLng) => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: 18,
          center: address,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: true,
          gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'pointer',
          styles: [
            {
              featureType: "poi",
              stylers: [
                { visibility: "off" }
              ]
            },
            {
              featureType: "transit",
              stylers: [{ visibility: "off" }],
            },
          ]
        })
      )
    }
  }

  async function fetchProperty() {
    console.log(props.match.params.id)
    try {
      await fetch(baseEndpoint + 'Properties/address/' + props.match.params.id).then(async (response) => {
        if (response.ok) {
          var r = await response.json() as Property;
          setAddressId(Number(props.match.params.id));
          setProperty(r);
          if (property.id == null) {
            setHeading(r.addresses[0].heading.toString());
            setLat(r.addresses[0].lat.toString());
            setLng(r.addresses[0].lng.toString());
          }

          var url = "https://maps.googleapis.com/maps/api/streetview?size=450x450&location=" + (lat === "0" ? r.addresses[0].lat : lat) + "," + (lng === "0" ? r.addresses[0].lng : lng) + "&source=outdoor&heading=" + (heading === "-1" ? r.addresses[0].heading : heading) + "&fov=90&&key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ"
          setStreetViewUrl(url);
        }
      })
    }
    catch (ex) {

    }
  }


  const handleAddToCart = async () => {
    props.addToCart(property);
    history.push("/cart");
  }

  const handleChangeHeading = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeading(event.target.value);
  };
  const handleChangeLat = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLat(event.target.value);
  };

  const handleChangeLng = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLng(event.target.value);
  };


  const tryHeading = () => {
    fetchProperty();
  }

  const saveHeading = async () => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + props.userState.user.currentToken,
        'Content-Type': 'application/json',
        'Response-Type': 'Blob',
      },
      body: JSON.stringify({heading: Number(heading), lat: Number(lat), lng: Number(lng)})
    }
    try {
      await fetch(baseEndpoint + "properties/heading/" + property.addresses[0].internalId, requestOptions)
        .then(async response => {
          if (response.ok) {
            setAdminAlertMessage("Street View Change Saved!");
            setAlertSeverity("success");
            setAdminAlert(true);
          }
          else {
            setAdminAlertMessage("Street View Change Error!");
            setAlertSeverity("error");
            setAdminAlert(true);
            console.log(response);
          }

        })
    } catch (error) {
      console.log(error);

    }
  }

  const publish = async () => {
    var requestOptions = {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + props.userState.user.currentToken,
        'Content-Type': 'application/json',
        'Response-Type': 'Blob',
      },
      body: JSON.stringify(property)
    }
    try {
      await fetch(baseEndpoint + "properties/publish/" , requestOptions)
        .then(async response => {
          if (response.ok) {
            setAdminAlertMessage("Publish Success!");
            setAlertSeverity("success");
            setAdminAlert(true);
          }
          else {
            console.log(response);
            var error = await response.text();
            if(error === "Invalid session!")
            {
              setAdminAlertMessage("Invalid session please try logging out and back in!")
            }
            else{
              setAdminAlertMessage("Publish Error!");
            }
            setAlertSeverity("error");
            setAdminAlert(true);
          }

        })
    } catch (error) {
      console.log(error);

    }
  }

  const handleCloseAlert = () =>{
    setAdminAlert(false);
}

  return (
    <div className={classes.root}>
      <div style={{ width: "65%" }}>
        {property.id != null && <div style={{ border: "4px solid #0404fc", borderWidth: "5px", backgroundColor: "#0404fc", textAlign: "left", paddingLeft: "10px", paddingBottom: "3px", fontSize: "22px", height: "24px", marginRight: "2%", marginLeft: "17px", color: "white", fontWeight: "bolder" }} >{property.addresses[0].address + " " + property.addresses[0].city}</div>}
        {property.id != null && <div style={{ width: "96%", height: "92%", marginTop: "15px", backgroundColor: "#787878", marginRight: "2%", marginLeft: "2%", }}>
          {streetViewUrl !== "" && addressId > 0 && <img style={{ height: "75%", paddingTop: "7%" }} alt="Not Found" src={streetViewUrl} />}
          {props.userState.isAuth === true && props.userState.user.category === 1 && <div style={{ marginTop: "25px", width: "100%" }}>
            <TextField
              id="heading"
              label="Heading"
              value={heading}
              onChange={handleChangeHeading}
              variant="outlined"
              className={classes.input}
              style={{ marginRight: "15px", width: "25%" }}
            />
            <TextField
              id="lat"
              label="Lat"
              value={lat}
              onChange={handleChangeLat}
              variant="outlined"
              className={classes.input}
              style={{ marginRight: "15px", width: "25%" }}
            />
            <TextField
              id="lng"
              label="Lng"
              value={lng}
              onChange={handleChangeLng}
              variant="outlined"
              className={classes.input}
              style={{ marginRight: "15px", width: "25%" }}
            />
            <Button disabled={isNaN(Number(heading)) || heading.length === 0 || isNaN(Number(lat)) || isNaN(Number(lng))} onClick={tryHeading} 
            style={{ backgroundColor: isNaN(Number(heading)) || heading.length === 0 || isNaN(Number(lat)) || isNaN(Number(lng)) ? "gray" : "#0404fc", color: isNaN(Number(heading)) || heading.length === 0 ? "lightgray" : "white", marginTop: "5px" }}>Try it</Button>
            <Button onClick={saveHeading} style={{ backgroundColor: "#17d703", color: "white", marginTop: "5px", marginLeft: "10px" }}>Save</Button>
            <Button onClick={publish} style={{ backgroundColor: "#17d703", color: "white", marginTop: "5px", marginLeft: "10px" }}>Publish</Button>
          </div>}
        </div>}

      </div>
      <div style={{ width: "35%", textAlign: "center" }}>
        <div style={{ border: "4px solid #0404fc", backgroundColor: "#0404fc", borderWidth: "5px", fontSize: "22px", height: "24px", paddingBottom: "3px", paddingLeft: "10px", marginRight: "3%", textAlign: "left", marginLeft: "2%", color: "white", fontWeight: "bolder" }} >Parslii Score</div>
        <div style={{ height: "92%", width: "95%", backgroundColor: "#787878", marginRight: "2%", marginLeft: "2%", marginTop: "15px" }}>
          <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
            <div style={{ width: "180px", textAlign: "center", position: "relative", paddingTop: "10px" }}>

              <img src="../leaf-score.png" alt="leaf" />
              <Typography variant="h2" style={{ position: "absolute", top: "22%", left:  "25%", color: "#0404fc" }} ><strong>{(Math.round(property.calcScore)).toString()}</strong></Typography>
            </div>
            <Typography style={{ color: "white", textAlign: "left" }} variant="body1">Parslii Score is a measure of a property's strengths and viability for supporting a successful business</Typography>
          </div>
          <Typography style={{ color: "white", textAlign: "left", marginLeft: "10px", marginRight: "5px" }} variant="body2">Behind every Parslii Score is a full PROPERTY REPORT. All the research and dozens of data points Parslii uses to analyze every property. So you can have the power to make an objective, informed decision for your business.</Typography>
          {property.id != null && <div style={{ width: "100%", textAlign: "right" }}><Button onClick={handleAddToCart}
            disabled={props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1}
            style={{
              backgroundColor: props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1 ? 'gray' : "#0404fc",
              color: props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1 ? "lightgray" : "white", marginTop: "15px", marginRight: "15px"
            }}>Get Parslii Property Report</Button></div>}

          {scriptLoaded && (
            <div ref={ref} style={{ height: "calc(100% - 335px)", margin: "10px 17px" }} className="map-container__map"></div>
          )}

        </div>
      </div>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={adminAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alertSeverity}>
                    {adminAlertMessage}
                </Alert>
            </Snackbar>
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  cartState: state.cartState,
  userState: state.userState

});

const mapDispatchToProps = (dispatch: any) => ({
  addToCart: (property: Property) => dispatch(addToCart(property)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PropertyPage)