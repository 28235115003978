import { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Property } from '../Models/Property';
import { baseEndpoint } from '../App';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button,   Typography } from '@material-ui/core';
import { loadMapApi } from '../PropertyList/PropertyList';
import { addToCart } from '../Actions/CartActions';
import { connect } from 'react-redux';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: "10px",
      display: "flex"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    input: {
      backgroundColor: "white",
      '& label.Mui-focused': {
        color: '#0404fc',
        borderWidth: "3px",
        backgroundColor: "white"
      },
      '& .MuiInputLabel-outlined': {
        backgroundColor: "white",
        paddingRight: "10px",
        transform: "translate(14px, 13px) scale(1)"
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: "translate(14px, -6px) scale(0.75)"
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: "12px",
        paddingBottom: "12px"
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&:hover fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
        '&.Mui-focused fieldset': {
          borderColor: '#0404fc',
          borderWidth: "3px"
        },
      },
    },
  }),
);



interface PropertyPagePropes {
  id: string,

}

interface PropertyCartProps {
  cartState: any,
  addToCart: (property: Property) => string,
  userState: any
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;


function PropertyPageMobile(props: RouteComponentProps<PropertyPagePropes> & PropertyCartProps) {
  const classes = useStyles();
  const history = useHistory()
  const [addressId, setAddressId] = useState<number>(-1);
  const [property, setProperty] = useState<Property>({} as Property);
  const [streetViewUrl, setStreetViewUrl] = useState<string>("");
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [marker, setMarker] = useState<any>();
  const [map, setMap] = useState<GoogleMap>();
  const [heading, setHeading] = useState<string>("-1");
  const [lat, setLat] = useState<string>("0");
  const [lng, setLng] = useState<string>("0");

  useEffect(() => {

    if (property.id == null) {
      fetchProperty();
    }
    else if (!map) {
      const googleMapScript = loadMapApi();
      googleMapScript.addEventListener('load', function () {
        setScriptLoaded(true);
        initMap(new google.maps.LatLng(property.addresses[0].lat, property.addresses[0].lng))

      });
    }
    else if (map) {
      var m = marker;
      var add = property.addresses[0].address + " " + property.addresses[0].city + ", " + property.addresses[0].state + " " + property.addresses[0].zip;
      var latLng = { lat: property.addresses[0].lat, lng: property.addresses[0].lng }
      m = new google.maps.Marker({
        position: latLng,
        icon: '../green-pin.png',
        title: add,
      })
      console.log(map);
      m.setMap(map as GoogleMap);
      setMarker(m);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id, property, map]);

  const initMap = (address: GoogleLatLng) => {
    if (ref.current) {
      setMap(
        new google.maps.Map(ref.current, {
          zoom: 18,
          center: address,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: true,
          fullscreenControl: false,
          panControl: false,
          zoomControl: true,
          gestureHandling: 'cooperative',
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          draggableCursor: 'pointer',
          styles: [
            {
              featureType: "poi",
              stylers: [
                { visibility: "off" }
              ]
            },
            {
              featureType: "transit",
              stylers: [{ visibility: "off" }],
            },
          ]
        })
      )
    }
  }

  async function fetchProperty() {
    console.log(props.match.params.id)
    try {
      await fetch(baseEndpoint + 'Properties/address/' + props.match.params.id).then(async (response) => {
        if (response.ok) {
          var r = await response.json() as Property;
          setAddressId(Number(props.match.params.id));
          setProperty(r);
          if (property.id == null) {
            setHeading(r.addresses[0].heading.toString());
            setLat(r.addresses[0].lat.toString());
            setLng(r.addresses[0].lng.toString());
          }

          var url = "https://maps.googleapis.com/maps/api/streetview?size=450x450&location=" + (lat === "0" ? r.addresses[0].lat : lat) + "," + (lng === "0" ? r.addresses[0].lng : lng) + "&source=outdoor&heading=" + (heading === "-1" ? r.addresses[0].heading : heading) + "&fov=90&&key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ"
          setStreetViewUrl(url);
        }
      })
    }
    catch (ex) {

    }
  }


  const handleAddToCart = async () => {
    props.addToCart(property);
    history.push("/cart");
  }




  return (
    <div className={classes.root}>
      <div style={{height: "fit-content"}}>
        {property.id != null && <div style={{ border: "4px solid #0404fc", borderWidth: "5px", backgroundColor: "#0404fc", textAlign: "left", paddingBottom: "6px", paddingTop: "6px", fontSize: "22px", height: "25px", color: "white", fontWeight: "bolder" }} >{property.addresses[0].address + " " + property.addresses[0].city}</div>}
        {property.id != null && <div style={{ width: "100%", height: "33vh" }}>
          {streetViewUrl !== "" && addressId > 0 && <img style={{ width: "100%", height: "100%" }} alt="Not Found" src={streetViewUrl} />}
        </div>}
        <div style={{ width: "96%", marginRight: "2%", marginLeft: "2%", marginTop: "5px" }}>
          <div style={{ display: "flex", padding: "5px", marginBottom: "10px", alignItems: "center" }}>
            <div>
              <Typography style={{ color: "#0404fc", textAlign: "left" }} variant="h6"><strong>Parslii Score</strong></Typography>
              <Typography style={{ color: "grey", textAlign: "left" }} variant="body2">Parslii Score is a measure of a property's strengths and viability for supporting a successful business</Typography>
              {property.id != null &&<Button onClick={handleAddToCart}
                disabled={props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1}
                style={{
                  backgroundColor: props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1 ? 'gray' : "#0404fc",
                  color: props.cartState.items.length !== 0 && props.cartState.items.findIndex((c: any) => c.addresses[0].internalId === property.addresses[0].internalId) !== -1 ? "lightgray" : "white", marginTop: "15px", fontSize: "small"
                }}>Get Parslii Property Report</Button>}
            </div>
            <div style={{ textAlign: "center", position: "relative", paddingTop: "10px" }}>

              <img src="../leaf-score.png" alt="leaf" />
              <Typography variant="h2" style={{ position: "absolute", top: "22%", left: "25%", color: "#0404fc" }} ><strong>{(Math.round(property.calcScore)).toString()}</strong></Typography>
            </div>

          </div>

          {scriptLoaded && (
            <div ref={ref} style={{ height: "25vh" }} className="map-container__map"></div>
          )}

        </div>
      </div>

    </div>
  );
}
const mapStateToProps = (state: any) => ({
  cartState: state.cartState,
  userState: state.userState

});

const mapDispatchToProps = (dispatch: any) => ({
  addToCart: (property: Property) => dispatch(addToCart(property)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PropertyPageMobile)