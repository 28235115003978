import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Neighborhood } from '../Models/Neighborhood';
import { AppBar, createStyles, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, List, ListItem, makeStyles, MenuItem, Radio, RadioGroup, Select, Slide, Step, StepLabel, Stepper, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import { Answer, Question, ScoreInfo } from '../Models/Info';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { Address, Landlord, Property } from '../Models/Property';
import { AddPropertyStepIcon, AddPropStepConnector } from './AddPropertyStepIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: '#0404fc'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        stepper: {
            '&.MuiStepIcon-root.MuiStepIcon-completed': {
                color: "red!important"
            }
        },
        input: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                color: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#0404fc',
                borderWidth: "3px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
            },
        }
    }),
);





export interface AddPropertyDialogProps {
    open: boolean,
    onClose: (property: any, propertyScoreInfo: any, neighborhood: any, neighborhoodScoreInfo: any, landlord: any, landlordScoreInfo: any) => void,
    questions: Question[],
    answers: Answer[],
    neighborhoods: Neighborhood[],
    landlords: Landlord[]
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPropertyDialog(props: AddPropertyDialogProps) {
    const classes = useStyles();
    const steps = ["Property", "Neighborhood", "Landlord"]
    const [activeStep, setActiveStep] = React.useState(0);
    const [property, setProperty] = React.useState({} as Property);
    const [address, setAddress] = React.useState<string>("");
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [city, setCity] = React.useState<string>("");
    const [cityError, setCityError] = useState<boolean>(false);
    const [state, setState] = React.useState<string>("");
    const [stateError, setStateError] = useState<boolean>(false);
    const [zip, setZip] = React.useState<string>("");
    const [zipErorr, setZipError] = useState<boolean>(false);
    const [block, setBlock] = useState<string>("");
    const [blockError, setBlockError] = useState<boolean>(false);
    const [lot, setLot] = useState<string>("");
    const [lotError, setLotError] = useState<boolean>(false);
    const [borough, setBorough] = useState<string>("");
    const [boroughError, setBoroughError] = useState<boolean>(false);
    const [properScoreInfo, setProperScoreInfo] = React.useState<ScoreInfo[]>([] as ScoreInfo[]);
    const [propScoreInfoErrors, setPropScoreInfoErrors] = React.useState<number[]>([] as number[]);
    const [selectedNeighborhood, setSelectedNeighborhood] = React.useState<Neighborhood>({ internalId: 0 } as Neighborhood)
    const [landlord, setLandlord] = React.useState<Landlord>({ internalId: -1 } as Landlord);
    const [landlordName, setLandlordName] = React.useState<string>("");
    const [landlordNameError, setLandlordNameError] = React.useState<boolean>(false);
    const [landlordScoreInfo, setLandlordScoreInfo] = useState<ScoreInfo[]>([] as ScoreInfo[]);
    const [stepErrors, setStepErrors] = useState<string[]>([] as string[]);
    const [masterAddress, setMasterAddress] = useState<string>("");
    const [blockLockError, setBlockLockError] = React.useState<boolean>(false);

    const BLOCK_LOC_DROPDOWN = ["23-parslii-report-block-long-corner-e.png", "23-parslii-report-block-long-corner-w.png",
        "23-parslii-report-block-long-mid.png", "23-parslii-report-block-short-corner-n.png",
        "23-parslii-report-block-short-corner-s.png", "23-parslii-report-block-short-mid.png"]

    useEffect(() => {
        if (props.open === true && properScoreInfo.length === 0) {
            var info = [] as ScoreInfo[]
            props.questions.filter(q => q.questionType === 0 && q.parentQuestionId != null && q.required === true).forEach(q => {

                var s = {} as ScoreInfo;
                s.questionDescription = q.description;
                s.questionId = q.internalId;
                info.push(s);

            });
            setProperScoreInfo(info);
            info = [] as ScoreInfo[]
            props.questions.filter(q => q.questionType === 2 && q.parentQuestionId != null && q.required === true).forEach(q => {

                var s = {} as ScoreInfo;
                s.questionDescription = q.description;
                s.questionId = q.internalId;
                info.push(s);

            });
            setLandlordScoreInfo(info);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    const handleClose = () => {
        setActiveStep(0);
        props.onClose(null, null, null, null, null, null);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            setStepErrors([] as string[]);
            var questionErrors = [] as number[]
            properScoreInfo.forEach((element, i) => {
                if (!element.answerId || element.answerId == null) {
                    questionErrors.push(i)
                }
                if(element.questionId === 3 && !element.additionalInfo)
                {
                    console.log(element);
                    setBlockLockError(true);
                }
                else if(element.questionId === 3){
                    
                    setBlockLockError(false);
                }
            });
            if (questionErrors.length > 0) {
                setPropScoreInfoErrors(questionErrors);
                setStepErrors((stepError) => stepError.concat(["Missing Required answer(s)!"]))
                setAddressError(address === "");
                if (address === "") {
                    setStepErrors((stepError) => stepError.concat(["Address is Required!"]))
                }
                setCityError(city === "");
                if (city === "") {
                    setStepErrors((stepError) => stepError.concat(["City is Required!"]))
                }
                setStateError(state === "");
                if (state === "") {
                    setStepErrors((stepError) => stepError.concat(["State is Required!"]))
                }
                setZipError(zip === "");
                if (zip === "") {
                    setStepErrors((stepError) => stepError.concat(["Zip is Required!"]))
                }
                setBlockError(block === "");
                if (block === "") {
                    setStepErrors((stepErrors) => stepErrors.concat(["Block is Required!"]));
                }
                setLotError(lot === "");
                if (lot === "") {
                    setStepErrors((stepErrors) => stepErrors.concat(["Lot is Required!"]));
                }
                setBoroughError(borough === "");
                if (borough === "") {
                    setStepErrors((stepErrors) => stepErrors.concat(["Borough is Required!"]));
                }
            }
            else {
                setPropScoreInfoErrors([] as number[]);
                if (address === "" || city === "" || state === "" || zip === "" || block === "" || lot === "") {
                    setAddressError(address === "");
                    if (address === "") {
                        setStepErrors((stepError) => stepError.concat(["Address is Required!"]))
                    }
                    setCityError(city === "")
                    if (city === "") {
                        setStepErrors((stepError) => stepError.concat(["City is Required!"]))
                    }
                    setStateError(state === "")
                    if (state === "") {
                        setStepErrors((stepError) => stepError.concat(["State is Required!"]))
                    }
                    setZipError(zip === "")
                    if (zip === "") {
                        setStepErrors((stepError) => stepError.concat(["Zip is Required!"]))
                    }
                    setBlockError(block === "");
                    if (block === "") {
                        setStepErrors((stepErrors) => stepErrors.concat(["Block is Required!"]));
                    }
                    setLotError(lot === "");
                    if (lot === "") {
                        setStepErrors((stepErrors) => stepErrors.concat(["Lot is Required!"]));
                    }
                    setBoroughError(borough === "");
                    if (borough === "") {
                        setStepErrors((stepErrors) => stepErrors.concat(["Borough is Required!"]));
                    }
                }
                else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

            }

        }
        else if (activeStep === 1) {
            setStepErrors([] as string[]);
            if (selectedNeighborhood.internalId === 0) {
                setStepErrors((stepError) => stepError.concat(["Selecting a neighborhood is Required!"]))
            }
            else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }

        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

    };

    const handleBack = () => {
        setStepErrors([] as string[]);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSave = () => {

        setStepErrors([] as string[]);
        var questionErrors = [] as number[]
        landlordScoreInfo.forEach((element, i) => {
            if (!element.answerId || element.answerId == null) {
                questionErrors.push(i)
            }
        });
        if (landlordName !== "" && questionErrors.length === 0) {
            var p = Object.assign({}, property);
            p.internalId = -1;
            setActiveStep(0);
            props.onClose(p, properScoreInfo, selectedNeighborhood, null, landlord, landlordScoreInfo);
        }
        else {
            setLandlordNameError(landlordName === "");
            if (landlordName === "") {
                setStepErrors((stepError) => stepError.concat(["Landlord Name is required or select existing landlord!"]))
            }
            if (questionErrors.length !== 0) {
                setPropScoreInfoErrors(questionErrors);
                setStepErrors((stepError) => stepError.concat(["Missing Required answer(s)!"]))
            }
        }



    };

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ address: event.target.value } as Address]
        }
        else {
            p.addresses[0].address = event.target.value;
        }
        setProperty(p)

    };

    const handleChangeMasterAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMasterAddress(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ masterAddress: event.target.value } as Address]
        }
        else {
            p.addresses[0].masterAddress = event.target.value;
        }
        setProperty(p)

    };

    const handleChangeBlock = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlock(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ block: Number(event.target.value) } as Address]
        }
        else {
            p.addresses[0].block = Number(event.target.value);
        }
        setProperty(p)

    };

    const handleChangeLot = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLot(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ lot: Number(event.target.value) } as Address]
        }
        else {
            p.addresses[0].lot = Number(event.target.value);
            console.log(p);
        }
        setProperty(p)

    };

    const handleChangeBorough = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBorough(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ lot: Number(event.target.value) } as Address]
        }
        else {
            p.addresses[0].borough = Number(event.target.value);
            console.log(p);
        }
        setProperty(p)

    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ city: event.target.value } as Address]
        }
        else {
            p.addresses[0].city = event.target.value;
        }
        setProperty(p)

    };

    const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ state: event.target.value } as Address]
        }
        else {
            p.addresses[0].state = event.target.value;
        }
        setProperty(p)

    };

    const handleChangeZip = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZip(event.target.value);
        let p = Object.assign({}, property);
        if (!property.addresses) {
            p.addresses = [{ zip: event.target.value } as Address]
        }
        else {
            p.addresses[0].zip = event.target.value;
        }
        setProperty(p)

    };

    const handleChangeAnswer = (event: React.ChangeEvent<{ value: unknown }>, questionIndex: number) => {
        if (activeStep === 0) {
            var temp = Object.assign([], properScoreInfo) as ScoreInfo[];
            temp[questionIndex].answerId = event.target.value as number;
            temp[questionIndex].score = props.answers.find(a => a.internalId === event.target.value as number)?.score as number;
            temp[questionIndex].answerDescription = props.answers.find(a => a.internalId === event.target.value as number)?.description as string;
            setProperScoreInfo(temp);
        }
        else if (activeStep === 2) {
            temp = Object.assign([], landlordScoreInfo) as ScoreInfo[];
            temp[questionIndex].answerId = event.target.value as number;
            temp[questionIndex].score = props.answers.find(a => a.internalId === event.target.value as number)?.score as number;
            temp[questionIndex].answerDescription = props.answers.find(a => a.internalId === event.target.value as number)?.description as string;
            setLandlordScoreInfo(temp);
        }

    }

    const handleChangeFact = (event: React.ChangeEvent<{ value: unknown }>, questionIndex: number) => {
        if (activeStep === 0) {
            var temp = Object.assign([], properScoreInfo) as ScoreInfo[];
            temp[questionIndex].fact = event.target.value as string;
            setProperScoreInfo(temp);
        }
        else if (activeStep === 2) {
            temp = Object.assign([], landlordScoreInfo) as ScoreInfo[];
            temp[questionIndex].fact = event.target.value as string;
            setLandlordScoreInfo(temp);
        }

    };

    const handleChangeAdditionalInfo = (event: React.ChangeEvent<{ value: unknown }>, questionIndex: number) => {
        if (activeStep === 0) {
            var temp = Object.assign([], properScoreInfo) as ScoreInfo[];
            temp[questionIndex].additionalInfo = event.target.value as string;
            setProperScoreInfo(temp);
        }
        else if (activeStep === 2) {
            temp = Object.assign([], landlordScoreInfo) as ScoreInfo[];
            temp[questionIndex].additionalInfo = event.target.value as string;
            setLandlordScoreInfo(temp);
        }

    };

    const handleChangeNeighborhood = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedNeighborhood(props.neighborhoods.find(n => n.internalId.toString() === (event.target as HTMLInputElement).value) as Neighborhood);
    };




    const handleChangeLandlordName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLandlordName(event.target.value);
        let l = Object.assign({}, landlord);
        l.name = event.target.value;
        setLandlord(l)

    };





    return (
        <Dialog TransitionComponent={Transition} fullScreen open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Add Property
                    </Typography>
                    {activeStep > 0 && <Button color="inherit" onClick={handleBack}>Back</Button>}
                    {activeStep === 2 ? <Button autoFocus color="inherit" onClick={handleSave}>
                        save
                    </Button> : <Button autoFocus color="inherit" onClick={handleNext}>
                        next
                    </Button>}
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Stepper connector={<AddPropStepConnector />} activeStep={activeStep}>
                    {steps.map((label) => {
                        return (
                            <Step className={classes.stepper} key={label} >
                                <StepLabel StepIconComponent={AddPropertyStepIcon}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === 0 && <div>
                    {stepErrors.length > 0 && <List>
                        {stepErrors.map((e, i) => {
                            return (
                                <ListItem style={{ color: "#f44336" }} key={i}><Typography variant="body2"><strong>{e}</strong></Typography></ListItem>
                            )
                        })}
                    </List>}
                    <div style={{ display: "flex", marginTop: "15px" }}>
                        <TextField error={addressError} helperText={addressError ? "**Required" : ""} style={{ width: "60%", marginLeft: "10%", marginRight: "12px" }} onChange={handleChangeAddress} value={address} id="address" label="Address" />
                        <TextField error={cityError} helperText={cityError ? "**Required" : ""} style={{ width: "140px", marginRight: "12px" }} onChange={handleChangeCity} value={city} id="city" label="City" />
                        <TextField error={stateError} helperText={stateError ? "Required" : ""} style={{ width: "50px", marginRight: "12px" }} onChange={handleChangeState} value={state} id="state" label="State" />
                        <TextField error={zipErorr} helperText={zipErorr ? "**Required" : ""} style={{ width: "75px" }} onChange={handleChangeZip} value={zip} id="zip" label="Zip" />
                    </div>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                        <TextField type="number" error={boroughError} helperText={boroughError ? "**Required" : ""} style={{ width: "15%", marginLeft: "10%" }} onChange={handleChangeBorough} value={borough} id="borough" label="Borough" />
                        <TextField type="number" error={blockError} helperText={blockError ? "**Required" : ""} style={{ width: "15%", marginLeft: "12px" }} onChange={handleChangeBlock} value={block} id="block" label="Block" />
                        <TextField type="number" error={lotError} helperText={lotError ? "**Required" : ""} style={{ width: "15%", marginLeft: "12px" }} onChange={handleChangeLot} value={lot} id="lot" label="Lot" />
                    </div>
                    <div style={{ display: "flex", marginTop: "15px" }}>
                        <TextField style={{ width: "60%", marginLeft: "10%" }} onChange={handleChangeMasterAddress} value={masterAddress} id="masterAddress" label="Master Address" />
                    </div>
                    <div>
                        <List>
                            {properScoreInfo.map((q, i) => {
                                return (
                                    <ListItem style={{ display: "flex", marginTop: "10px" }} key={i}>
                                        <div style={{ width: "33%" }}>
                                            <Typography variant="body1">{q.questionDescription}{(props.questions.find(a => a.internalId === q.questionId) as Question).required && <strong style={{ color: "red" }}>  *</strong>}</Typography>
                                        </div>
                                        <div style={{ width: "30%", marginLeft: "3%", marginTop: "11px" }}>
                                            <FormControl style={{ width: "100%" }} error={propScoreInfoErrors.includes(i)}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={q.answerId == null ? '' : q.answerId}
                                                    onChange={(e) => {
                                                        handleChangeAnswer(e, i)
                                                    }}
                                                >
                                                    {props.answers.map((a, j) => {
                                                        if (a.questionId === q.questionId) {
                                                            return (
                                                                <MenuItem key={j} value={a.internalId}>{a.description} ({a.score})</MenuItem>
                                                            )
                                                        }
                                                        else {
                                                            return null;
                                                        }
                                                    })}
                                                </Select>
                                                {propScoreInfoErrors.includes(i) && <FormHelperText>**Required</FormHelperText>}
                                            </FormControl>
                                        </div>
                                        <div style={{ width: "14%", display: "flex", marginLeft: "2%" }}>
                                            <TextField label="Fact" style={{ width: "100%" }} size="small" onChange={(e) => {
                                                handleChangeFact(e, i)
                                            }} value={q.fact != null ? q.fact : ""}
                                            />
                                        </div>
                                        <div style={{ width: "15%", display: "flex", marginLeft: "2%" }}>
                                            {q.questionId !== 3 ? <TextField label="Additional Info" style={{ width: "100%" }} size="small" onChange={(e) => {
                                                handleChangeAdditionalInfo(e, i)
                                            }} value={q.additionalInfo != null ? q.additionalInfo : ""}
                                            /> :
                                                <FormControl error={blockLockError === true} style={{ width: "100%" }}>
                                                    <InputLabel id="add_info">Additional Info</InputLabel>
                                                    <Select
                                                        labelId="add_info"
                                                        id="add_info"
                                                        value={q.additionalInfo == null ? '' : q.additionalInfo}
                                                        onChange={(e) => {
                                                            handleChangeAdditionalInfo(e, i)
                                                        }}
                                                    >
                                                        {BLOCK_LOC_DROPDOWN.map((a, j) => {
                                                            return (
                                                                <MenuItem key={j} value={a}>{a}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {blockLockError && <FormHelperText>*Required</FormHelperText>}
                                                </FormControl>
                                            }
                                        </div>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                </div>}
                {activeStep === 1 && <div>
                    {stepErrors.length > 0 && <List>
                        {stepErrors.map((e, i) => {
                            return (
                                <ListItem style={{ color: "#f44336" }} key={i}><Typography variant="body2"><strong>{e}</strong></Typography></ListItem>
                            )
                        })}
                    </List>}
                    <Typography variant="h5">Select Existing Neighborhood: {selectedNeighborhood.name !== null && <strong>{selectedNeighborhood.name}</strong>}</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="neighborhood" name="neighborhood" value={selectedNeighborhood.internalId} onChange={handleChangeNeighborhood}>
                            {props.neighborhoods.map((n, i) => {
                                return (
                                    <FormControlLabel key={i} value={n.internalId} control={<Radio />} label={n.name} />
                                )
                            })}
                        </RadioGroup>
                    </FormControl>
                </div>}
                {activeStep === 2 && <div>
                    {stepErrors.length > 0 && <List>
                        {stepErrors.map((e, i) => {
                            return (
                                <ListItem style={{ color: "#f44336" }} key={i}><Typography variant="body2"><strong>{e}</strong></Typography></ListItem>
                            )
                        })}
                    </List>}
                    <div>
                        <Typography variant="h5">Create new Landlord:</Typography>
                        <div>
                            <TextField error={landlordNameError} helperText={landlordNameError ? "**Required" : ""}
                                style={{ width: "75%", marginLeft: "12.5%" }} onChange={handleChangeLandlordName} value={landlordName} id="landlord" label="Landlord Name" />
                        </div>
                        <div>
                            <List>
                                {landlordScoreInfo.map((q, i) => {
                                    return (
                                        <ListItem style={{ display: "flex", marginTop: "10px" }} key={i}>
                                            <div style={{ width: "33%" }}>
                                                <Typography variant="body1">{q.questionDescription}</Typography>
                                            </div>
                                            <div style={{ width: "30%", marginLeft: "3%", marginTop: "11px" }}>
                                                <FormControl style={{ width: "100%" }}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={q.answerId == null ? '' : q.answerId}
                                                        onChange={(e) => {
                                                            handleChangeAnswer(e, i)
                                                        }}
                                                    >
                                                        {props.answers.map((a, j) => {
                                                            if (a.questionId === q.questionId) {
                                                                return (
                                                                    <MenuItem key={j} value={a.internalId}>{a.description} ({a.score})</MenuItem>
                                                                )
                                                            }
                                                            else {
                                                                return null;
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div style={{ width: "14%", display: "flex", marginLeft: "2%" }}>
                                                <TextField label="Fact" style={{ width: "100%" }} size="small" onChange={(e) => {
                                                    handleChangeFact(e, i)
                                                }} value={q.fact != null ? q.fact : ""}
                                                />
                                            </div>
                                            <div style={{ width: "15%", display: "flex", marginLeft: "2%" }}>
                                                <TextField label="Additional Info" style={{ width: "100%" }} size="small" onChange={(e) => {
                                                    handleChangeAdditionalInfo(e, i)
                                                }} value={q.additionalInfo != null ? q.additionalInfo : ""}
                                                />
                                            </div>
                                        </ListItem>
                                    )
                                })}
                            </List>
                        </div>
                    </div>
                </div>}
            </DialogContent>

        </Dialog>
    );
}
