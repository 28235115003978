import React, { ChangeEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom'
const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundImage: "url(home_background_mobile.jpg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    input: {
        backgroundColor: "white",
        '& .MuiOutlinedInput-adornedEnd':{
            paddingRight: "0px"
        },
        '& label.Mui-focused': {
          color: '#0404fc',
          borderWidth: "3px"
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#0404fc',
          borderWidth: "3px"
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#0404fc',
            borderWidth: "3px"
          },
          '&:hover fieldset': {
            borderColor: '#0404fc',
            borderWidth: "3px"
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0404fc',
            borderWidth: "3px"
          },
        },
      }
  }),
);

export default function HomeMobile() {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  let history = useHistory();

  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  const searchPropertyList = () =>{
    var url = "/properties?";
    var query = {
      search: searchTerm
    }
    url += queryString.stringify(query, {skipNull: true})
    history.push(url) 
  }

  return (
    <div className={classes.root}>
        <Typography style={{color: "#0404fc", marginTop: "35px", width: "90%", marginLeft: "auto", marginRight: "auto"}} variant={window.innerWidth > 330 ? "h3" : "h4"}><strong>New York City</strong></Typography>
        <Typography style={{color: "rgb(255, 121, 0)", width: "90%", marginLeft: "auto", marginRight: "auto"}} variant={window.innerWidth > 330 ? "h5" : "h6"}><strong>Commercial Real Estate Research</strong></Typography>

        <form style={{marginTop: "32vh", width: "90%", marginLeft: "auto", marginRight: "auto"}}
        onSubmit={(e: React.SyntheticEvent) => {
          e.preventDefault();
          searchPropertyList();
            }}>
        <Typography style={{color: "#0404fc", textAlign: "left"}} variant="h6"><strong>Find a Parslii Score</strong></Typography>
        <TextField
                  size="small"
                  style={{ width: "100%" }}
                  className={classes.input}
                  id="search"
                  placeholder='Address, Neighborhood, Zip'
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleChangeSearchTerm}
                  InputProps={{
                    endAdornment: <InputAdornment position="start"><IconButton onClick={searchPropertyList} type="submit"  style={{ padding: "0px" }}><SearchIcon /></IconButton></InputAdornment>
                  }}
                />
        </form>

    </div >
  );
}
