import { baseEndpoint } from "../App";
import { loginUser, resetError, signInError, signoutUser, signUpError } from "./UserActions";

const CATS = ["tenant", "broker", "landlord"];

export const createUser = (values: any) => async (dispatch: any, getState: any) => {
  var requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      Category: CATS.findIndex(c => c === values.category) + 2,
      FirstName: values.firstName,
      LastName: values.lastName,
      Company: values.company,
      EmailAddress: values.email,
      Password: values.password,
      Phone: values.phone
    })
  }
  await fetch(baseEndpoint + "users/create", requestOptions)
    .then(async response => {
      if (response.ok) {
        var u = await response.json();
        dispatch(loginUser(u));
      }
      else {
        var message = await response.text();
        dispatch(signUpError(message));
      }

    });


}

export const signOut = () => async (dispatch: any, getState: any) => {
  try{
  var userState = getState().userState;
  var requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token: userState.user.currentToken.toString()
    })
  }
  await fetch(baseEndpoint + "users/signout", requestOptions)
    .then(async response => {
      if (response.ok) {
        dispatch(signoutUser());
      }
    });
  }
  catch(err)
  {
    dispatch(signoutUser());
  }
}

export const login = (email: string, password: string) => async (dispatch: any, getState: any) => {
  var requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password
    })
  }
  await fetch(baseEndpoint + "users/login", requestOptions)
    .then(async response => {
      if (response.ok) {
        var u = await response.json();
        dispatch(loginUser(u));
        return (true);
      }
      else {
        var message = await response.text();
        dispatch(signInError(message));
        return (false);
      }

    });

}

export const refreshSession = () => async(dispatch: any, getState: any) => {
  try{
  var userState = getState().userState;
  var requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token: userState.user.refreshToken.toString()
    })
  }
  await fetch(baseEndpoint + "users/refresh", requestOptions)
    .then(async response => {
      if (response.ok) {
        var u = await response.json();
        dispatch(loginUser(u));
        return (true);
      }
      else{
        dispatch(signOut());
      }
    });
  }
  catch(err)
  {
    dispatch(signoutUser());
  }
}



export const resetPassword = (code: string, newPass: string, email: string) => async (dispatch: any, getState: any) => {
  var requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({email: email, code: code, password: newPass})
  }
  await fetch(baseEndpoint + "users/passwordReset", requestOptions)
    .then(async response => {
      if (response.ok) {
        console.log("here");
        var u = await response.json();
        dispatch(loginUser(u));
        return(true);
      }
      else {
        var err = await response.text();
        dispatch(resetError(err));
        return (false);
      }

    });
}