import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import {  Provider} from 'react-redux';
import { persistor, store } from './store';


ReactDOM.render(
  <Provider store={store}>
  <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
  <BrowserRouter forceRefresh={true} >
      <App />
  </BrowserRouter >
  </PersistGate>
    </Provider>
, 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
