import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField,  Snackbar } from '@material-ui/core';
import { useState } from 'react';
import { baseEndpoint } from '../App';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: "10px",
            display: "block"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        input: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                color: '#0404fc',
                borderWidth: "3px",
                backgroundColor: "white"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                fontSize: "0.85em",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input': {
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "0.85em"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#0404fc',
                    borderWidth: "3px"
                },
            },
        },
        inputError: {
            backgroundColor: "white",
            '& label.Mui-focused': {
                borderWidth: "3px"
            },
            '& .MuiInput-underline:after': {
                borderWidth: "3px"
            },
            '& .MuiInputLabel-outlined': {
                backgroundColor: "white",
                paddingRight: "10px",
                transform: "translate(14px, 13px) scale(1)"
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: "translate(14px, -6px) scale(0.75)"
            },
            '& .MuiOutlinedInput-input': {
                paddingTop: "12px",
                paddingBottom: "12px"
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderWidth: "3px"
                },
                '&:hover fieldset': {
                    borderWidth: "3px"
                },
                '&.Mui-focused fieldset': {
                    borderWidth: "3px"
                },
            },
        }
    }),
);

function RequestPropertyMobile(props: any) {
    const classes = useStyles();
    const [firstName, setFirstName] = useState<string>("");
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const [lastName, setLastName] = useState<string>("");
    const [lastNameError, setLastNameError] = useState<boolean>(false);
    const [company, setCompany] = useState<string>("");
    const [companyError, setCompanyError] = useState<boolean>(false);
    const [category, setCategory] = useState<number>(0);
    const [categoryError, setCategoryError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [addressError, setAddressError] = useState<boolean>(false);
    const [comments, setComments] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [zipError, setZipError] = useState<string>("");
    const [requestAlertOpen, setRequestAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    useEffect(() => {
        if (props.userState.isAuth) {
            setFirstName(props.userState.user.firstName);
            setLastName(props.userState.user.lastName);
            setEmail(props.userState.user.emailAddress);
            if (props.userState.user.category !== 1) {
                setCategory(props.userState.user.category)
            }
        }
        else {
            setCategory(0)
            setFirstName("");
            setLastName("");
            setEmail("");
        }
    }, [props])

    const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    };

    const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    };

    const handleChangeCategory = (event: React.ChangeEvent<{ value: unknown }>) => {
        setCategory(event.target.value as number);
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(event.target.value);
    };

    const handleChangeZip = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZip(event.target.value);
    };

    const handleChangeComments = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComments(event.target.value);
    };

    const handleSendRequest = async () => {
        var emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/); // eslint-disable-line
        var emailMatch = emailRegEx.test(email);
        if (firstName.trim() === "") {
            setFirstNameError(true);
        }
        else {
            setFirstNameError(false);
        }
        if (lastName.trim() === "") {
            setLastNameError(true);
        }
        else {
            setLastNameError(false);
        }
        if (category === 3 && company.trim() === "") {
            setCompanyError(true);
        }
        else {
            setCompanyError(false);
        }
        if (category === 0) {
            setCategoryError(true);
        }
        else {
            setCategoryError(false);
        }
        if (email.trim() === "") {
            setEmailError("**Email required");
        }
        else if (emailMatch === false) {
            setEmailError("**Invalid email")
        }
        else if (emailError !== "") {
            setEmailError("");
        }
        if (address.trim() === "") {
            setAddressError(true);
        }
        else {
            setAddressError(false);
        }
        if (zip.trim() === "") {
            setZipError("**Property zip code is required");
        }
        else if (isNaN(+zip) || zip.trim().length !== 5) {
            setZipError("**Invalid zip code")
        }
        else {
            setZipError("");
        }
        if (firstName.trim() !== "" && lastName.trim() !== "" && (category !== 3 || company.trim() !== "") && category !== 0 && email.trim() !== "" && emailMatch === true && address.trim() !== "" && zip.trim() !== "") {
            var requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Response-Type': 'application/json',
                },
                body: JSON.stringify({ FirstName: firstName, LastName: lastName, EmailAddress: email, Address: address, Zip: zip, Company: company, Comments: comments, Category: category })
            }
            try {
                await fetch(baseEndpoint + "properties/request", requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            setAlertMessage("Thank you for your property request! We will get back to you soon!");
                            setRequestAlertOpen(true);
                        }
                        else {
                            setAlertMessage("There was an error with your request! Please try again later!");
                            setRequestAlertOpen(true);
                        }
                    });
            }
            catch (error) {
                console.log(error);
            }
        }


    }

    const handleCloseRequestAlert = () => {
        setRequestAlertOpen(false);
    }

    return (
        <div className={classes.root}>
            <div style={{ display: "flex", marginLeft: "2%" }}>
                <Typography style={{ color: "#0404fc", textAlign: "left" }} variant="h4">
                    <strong>Want to Know a Property's Score?</strong>
                </Typography>
                <img style={{ width: "45%" }} alt="Parslii leaf with question mark." src="22-parslii-mark-border-question-png.png" />
                <br />
            </div>
            <Typography style={{ textAlign: "left", marginLeft: "2%" }} variant="h6"><strong>Let us know!</strong></Typography>
            <div style={{ width: "96%", paddingLeft: "2%", paddingRight: "2%", backgroundColor: "#0404fc" }}>

                <form style={{ paddingTop: "13px" }}>
                    <div style={{ backgroundColor: "#c2c2c2", paddingTop: "10px", paddingBottom: "10px" }}>
                        <TextField
                            id="address"
                            label="Property Request Address *"
                            value={address}
                            onChange={handleChangeAddress}
                            variant="outlined"
                            className={!addressError ? classes.input : classes.inputError}
                            error={addressError}
                            style={{ width: "96%", marginLeft: "2%", marginRight: "2%" }}
                        />
                        <TextField
                            id="zip"
                            label="Property Request Zip Code *"
                            value={zip}
                            onChange={handleChangeZip}
                            variant="outlined"
                            className={zipError === "" ? classes.input : classes.inputError}
                            error={zipError !== ""}
                            style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}
                        />
                    </div>
                    <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}>
                        <TextField
                            id="first-name"
                            label="First Name *"
                            value={firstName}
                            onChange={handleChangeFirstName}
                            variant="outlined"
                            className={!firstNameError ? classes.input : classes.inputError}
                            error={firstNameError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}>
                        <TextField
                            id="last-name"
                            label="Last Name *"
                            value={lastName}
                            onChange={handleChangeLastName}
                            variant="outlined"
                            className={!lastNameError ? classes.input : classes.inputError}
                            error={lastNameError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}>
                        <TextField
                            id="company"
                            label={category === 3 ? "Company *" : "Company"}
                            value={company}
                            onChange={handleChangeCompany}
                            variant="outlined"
                            className={!companyError ? classes.input : classes.inputError}
                            error={companyError}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}>
                        <FormControl error={categoryError} variant="outlined" style={{ width: "100%", textAlign: "left" }} className={!categoryError ? classes.input : classes.inputError} >
                            <InputLabel id="category-label-id">Category *</InputLabel>
                            <Select

                                labelId="category-label-id"
                                id="category"
                                onChange={handleChangeCategory}
                                value={category}
                            >
                                <MenuItem value={2}>Tenant</MenuItem>
                                <MenuItem value={3}>Broker</MenuItem>
                                <MenuItem value={4}>Landlord</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{  width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px" }}>
                        <TextField
                            id="email"
                            label="Email Address *"
                            value={email}
                            onChange={handleChangeEmail}
                            variant="outlined"
                            className={emailError === "" ? classes.input : classes.inputError}
                            error={emailError !== ""}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ width: "96%", marginLeft: "2%", marginRight: "2%", marginTop: "12px"}}>
                        <TextField
                            id="comments"
                            label="Comments"
                            value={comments}
                            multiline
                            rows={2}
                            onChange={handleChangeComments}
                            className={classes.input}
                            variant="outlined"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div style={{ paddingTop: "10px", textAlign: "right", width: "calc(96% + 15px)" }}>
                        <Button onClick={handleSendRequest} style={{ backgroundColor: "#17d703", color: "black" }}>Submit</Button>
                    </div>

                </form>
            </div>
            <Snackbar anchorOrigin={{ horizontal: "center", vertical: "top" }} open={requestAlertOpen} autoHideDuration={6000} onClose={handleCloseRequestAlert}>
                <Alert onClose={handleCloseRequestAlert} severity={alertMessage.includes("error") ? "error" : "success"}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}
const mapStateToProps = (state: any) => ({
    userState: state.userState
});
export default connect(mapStateToProps)(RequestPropertyMobile);