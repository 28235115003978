import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DialogContent from '@material-ui/core/DialogContent';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { login } from '../Actions/thunks';
import { connect } from 'react-redux';
import { signInError } from '../Actions/UserActions';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { isMobile } from '../App';

function LoginDialog(props: any) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [inputType, setInputType] = React.useState<string>("password");
    const [forgotPassOpen, setForgotPassOpen] = React.useState<boolean>(false);


    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleChangeInputType = () => {
        var newType = inputType === "password" ? "" : "password";
        setInputType(newType);
    }


    const login = async () => {
        props.login(email, password);
    }

    const handleClose = () => {
        props.signInError("");
        props.handleClose()
    }

    const handleOpenForgotPass = () => {
        setForgotPassOpen(true);
    }

    const handleCloseForgotPass = (value: any) => {
        setForgotPassOpen(false);
    }


    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
            ><div style={{ display: "flex" }}>
                    <DialogTitle id="dialog-title"><span style={{ fontSize: isMobile === false ? "2.125rem" : "1.5rem", fontWeight: 400, lineHeight: "1.235" }} >Login</span></DialogTitle>
                    <IconButton onClick={handleClose} style={{ marginLeft: "auto", height: "fit-content", color: '#D11A2A', padding: "5px", marginTop: "5px", marginRight: "5px" }}><CloseIcon fontSize="small" /></IconButton>
                </div>

                <DialogContent>
                    {props.userState.loginMessage !== "" && <Alert style={{ marginBottom: "15px", padding: "0px 10px" }} severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {props.userState.loginMessage}
                    </Alert>}
                    <form onSubmit={(event) => {
                        event.preventDefault();
                    }}>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <TextField
                                style={{ width: isMobile === false ? "50%" : "80%" }}
                                id="email"
                                label="Email"
                                value={email}
                                onChange={handleChangeEmail}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />

                        </div>
                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <TextField
                                style={{  width: isMobile === false ? "50%" : "80%" }}
                                id="password"
                                label="Password"
                                value={password}
                                type={inputType}
                                onChange={handleChangePassword}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleChangeInputType} style={{ padding: "4px" }}>{inputType === "password" ? <Visibility /> : <VisibilityOffIcon />}</IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                variant="standard"
                            />
                        </div>

                        <div style={{ width: "100%", display: "flex", marginBottom: "15px", placeContent: "center" }}>
                            <Button onClick={login} style={{ color: '#0404fc' }} type="submit" >Login</Button>
                        </div>
                    </form>
                    <Typography style={{ float: isMobile === false ? "left": "none", marginLeft: "20px" }} variant="caption">Don't have an account? <Link href="/signup" style={{ cursor: "pointer", color: "#0404fc" }}>Sign Up</Link></Typography>
                    <Typography style={{ float: isMobile === false ? "right": "left", marginLeft: "20px" }} variant="caption"> <Link onClick={handleOpenForgotPass} style={{ cursor: "pointer", color: "#0404fc" }}>Forgot Password</Link></Typography>
                </DialogContent>
            </Dialog>
            <ForgotPasswordDialog open={forgotPassOpen} handleClose={handleCloseForgotPass}/>
        </div>
    );
}
const mapStateToProps = (state: any) => ({
    userState: state.userState,
});

const mapDispatchToProps = (dispatch: any) => ({
    login: (email: string, password: string) => dispatch(login(email, password)),
    signInError: (message: string) => dispatch(signInError(message))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginDialog);