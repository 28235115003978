
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';



export interface CheckoutOptionDialogProps {
  open: boolean,
  onClose: (value: string) => void,
  
}
export function CheckoutOptionDialog(props: CheckoutOptionDialogProps) {
    const {open, onClose} = props;
    
    const handleClose = () =>{
        onClose("");
    }

    const asGuest = () =>{
        onClose("guest");
    }

    const asLoggedIn = () =>{
        onClose("user");
    }


  return (
    <Dialog fullWidth={true} maxWidth={"sm"} onClose={handleClose} open={open}>
      <DialogTitle id="simple-dialog-title">Checkout</DialogTitle>
      <DialogContent style={{ textAlign: "center" }}>
        <Button style={{backgroundColor: "#0404fc", color: "white", marginRight: "10px"}} onClick={asLoggedIn}>Login/Sign Up</Button>
        <Button onClick={asGuest}>Continue as guest</Button>
      </DialogContent>
    </Dialog>
  );
}
const mapStateToProps = (state: any) => ({
  cartState: state.cartReducer,
});
export default connect(mapStateToProps)(CheckoutOptionDialog);