import { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
const queryString = require('query-string');
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "absolute",
            backgroundColor: "white",
            zIndex: 999,
            height: "90%",
            width: "100%"
        },
    }),
);

export const loadMapApi = () => {
    const mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ&libraries=places&languages=no&region=NO&v=quarterly"
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsUrl) === 0) {
            return scripts[i];
        }
    }

    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = mapsUrl;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    window.document.body.appendChild(googleMapsScript);

    return googleMapsScript;
}


type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;
let places: google.maps.places.PlacesService;

export default function MapPage(props: any) {
    const classes = useStyles();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMap>();
    const params = queryString.parse(props.location.search, { arrayFormat: 'index' })


    useEffect(() => {

        if (!map) {
            const googleMapScript = loadMapApi();
            googleMapScript.addEventListener('load', function () {
                setScriptLoaded(true);
                initMap(new google.maps.LatLng(params.lat, params.lng))

            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    const initMap = (address: GoogleLatLng) => {
        if (ref.current) {
            const transitLayer = new google.maps.TransitLayer();
            var m =
                new google.maps.Map(ref.current, {
                    zoom: Number(params.zoom),
                    center: address,
                    mapTypeControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: false,
                    panControl: false,
                    zoomControl: false,
                    gestureHandling: 'cooperative',
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    draggableCursor: 'pointer',
                    styles: [
                        {
                            featureType: "poi",
                            stylers: [
                                { visibility: params.hotels && params.hotels === "true" ? "off" : "on" }
                            ]
                        },
                        {
                            featureType: "transit",
                            elementType: "labels.icon",
                            stylers: [{ visibility: (params.transitLayer && params.transitLayer === "true") ? "on" : "off" }],
                        },
                    ]
                })
            if (params.transitLayer && params.transitLayer === "true") {
                transitLayer.setMap(m)
            }
            else if (params.bikeLayer && params.bikeLayer === "true") {
                const bikeLayer = new google.maps.BicyclingLayer();
                bikeLayer.setMap(m);
            }
            else if (params.hotels && params.hotels === "true") {
                google.maps.event.addListener(m, 'bounds_changed', function () {
                    places = new google.maps.places.PlacesService(m);
                    search(m);
                });

            }
            else {
                setMap(m);
            }
            var latLng = { lat: Number(params.lat), lng: Number(params.lng) }
            new google.maps.Marker({
                position: latLng,
                icon: 'bullet-leaf.png',
                map: m
            });


            setMap(m);
        }
    }

    function search(ma: any) {
        console.log(ma.getBounds());
        const search = {
            bounds: ma.getBounds() as google.maps.LatLngBounds,
            types: ["lodging"],
        };

        places.nearbySearch(
            search,
            (
                results: google.maps.places.PlaceResult[] | null,
                status: google.maps.places.PlacesServiceStatus,
                pagination: google.maps.places.PlaceSearchPagination | null
            ) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                    console.log(results);
                    // Create a marker for each hotel found, and
                    // assign a letter of the alphabetic to each marker icon.
                    for (let i = 0; i < results.length; i++) {
                        // Use marker animation to drop the icons incrementally on the map.
                        let m = new google.maps.Marker({
                            position: (results[i].geometry as google.maps.places.PlaceGeometry)
                                .location,
                        });
                        // If the user clicks a hotel marker, show the details of that hotel
                        // in an info window.
                        // @ts-ignore TODO refactor to avoid storing on marker
                        m.setMap(ma);
                    }
                }
            }
        );
    }



    return (
        <div className={classes.root}>
            <div style={{ position: "absolute", top: "2%", left: "0%", height: "100%", width: "90%", marginLeft: "5%" }}>
                {scriptLoaded && (
                    <div ref={ref} style={{ height: "100%", margin: "10px 17px" }} id="chart" className="map-container__map"></div>
                )}
            </div>
        </div>
    );
}