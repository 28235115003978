import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Collapse, IconButton, List, ListItem, Paper, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { baseEndpoint } from '../App';
import { Neighborhood } from '../Models/Neighborhood';
import { ExpandMore } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Landlord, Property } from '../Models/Property';
import EditNeighborhoodDialog from './EditNeighborhoodDialog';
import { Answer, Question, QuestionType, ScoreInfo } from '../Models/Info';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditQuestionDialog from './EditQuestionDialog';
import { Alert } from '@material-ui/lab';
import AddPropertyDialog from './AddPropertyDialog';
import ContinueSessionDialog from '../SignUp/ContinueSessionDialog';
import { refreshSession } from '../Actions/thunks';
import TaxDataDialog from './TaxDataDialog';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function QuestionRow(props: { row: Question, parent: string, answers: Answer[], editQuestion: (q: Question, a: Answer[]) => void }) {
    const { row, parent, answers, editQuestion } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{parent}</TableCell>
                <TableCell>{QuestionType[row.questionType]}</TableCell>
                <TableCell>{row.description}{row.required && <strong style={{ color: "red" }}>  *</strong>}</TableCell>
                <TableCell>{row.maxScore}</TableCell>
                <TableCell><IconButton onClick={() => editQuestion(row, answers)} style={{ color: "#ffb74d" }}><EditIcon /></IconButton></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box style={{ marginLeft: "8%" }} margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Answers
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Answer</TableCell>
                                        <TableCell>Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {answers.map((a, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{a.description}</TableCell>
                                                <TableCell>{a.score}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "15px",
            textAlign: "left"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export const loadMapApi = () => {
    const mapsUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBJ8YqLm8Q0vyLA74cV2TUvVqNM-o_NAoQ&libraries=places&languages=no&region=NO&v=quarterly"
    const scripts = document.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
        if (scripts[i].src.indexOf(mapsUrl) === 0) {
            return scripts[i];
        }
    }

    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = mapsUrl;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    window.document.body.appendChild(googleMapsScript);

    return googleMapsScript;
}

export interface AdminDashboardProps {
    userState: any,
    refreshSession: () => void
}

function AdminDashboard(props: AdminDashboardProps) {
    const [neighborhoods, setNeighborhoods] = useState<Neighborhood[]>([] as Neighborhood[]);
    const [allProperties, setAllProperties] = useState<Property[]>([] as Property[]);
    const [editNeighborhoodOpen, setEditNeighborhoodOpen] = useState<boolean>(false);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood>({} as Neighborhood);
    const [allAnswers, setAllAnswers] = useState<Answer[]>([] as Answer[]);
    const [allQuestions, setAllQuestions] = useState<Question[]>([] as Question[]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [selectedEditQuestion, setSelectedEditQuestion] = useState<Question>({} as Question);
    const [editQuestionOpen, setEditQuestionOpen] = useState<boolean>(false);
    const [selectedEditAnswers, setSelectedEditAnswers] = useState<Answer[]>([] as Answer[]);
    const [editNotifOpen, setEditNotifOpen] = useState<boolean>(false);
    const [editNotifMessage, setEditNotifMessage] = useState<string>("");
    const [editLoad, setEditLoad] = useState<boolean>(false);
    const [addPropertyOpen, setAddPropertyOpen] = useState<boolean>(false);
    const [landlords, setLandlords] = useState<Landlord[]>([] as Landlord[]);
    const [sessionExpiredOpen, setSessionExpiredOpen] = useState<boolean>(false);
    const [taxDataOpen, setTaxDataOpen] = useState<boolean>(false);
    const [taxProperty, setTaxProperty] = useState<Property>({} as Property);
    const classes = useStyles();
    useEffect(() => {
        loadMapApi();
        fetchNeighborhoods();
        fetchProperties();
        fetchAnswers();
        fetchQuestions();
        fetchLandlords()

    }, [props])

    async function fetchNeighborhoods() {
        try {
            await fetch(baseEndpoint + 'neighborhoods').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Neighborhood[];
                    setNeighborhoods(r);
                }
            })
        }
        catch (ex) {

        }
    }

    async function fetchLandlords() {
        try {
            await fetch(baseEndpoint + 'landlords/list').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Landlord[];
                    setLandlords(r);
                }
            })
        }
        catch (ex) {

        }
    }

    async function fetchProperties() {
        try {
            await fetch(baseEndpoint + 'Properties/list').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Property[];
                    setAllProperties(r);
                }
            })
        }
        catch (ex) {

        }
    }
    async function fetchAnswers() {
        try {
            await fetch(baseEndpoint + 'Answers/list').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Answer[];
                    setAllAnswers(r);
                }
            })
        }
        catch (ex) {

        }
    }

    async function fetchQuestions() {
        try {
            await fetch(baseEndpoint + 'questions/list').then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as Question[];
                    setAllQuestions(r);
                }
            })
        }
        catch (ex) {

        }
    }

    const editNeighborhood = async (n: Neighborhood) => {


        try {
            await fetch(baseEndpoint + 'neighborhoods/info/' + n.internalId).then(async (response) => {
                if (response.ok) {
                    var r = await response.json() as ScoreInfo[];
                    n.scoreInfo = r;
                    setSelectedNeighborhood(n);
                    setEditNeighborhoodOpen(true);
                }
            })
        }
        catch (ex) {

        }
    }

    const closeEditNeighborhood = async (value: Neighborhood) => {
        if (value != null) {
            var info = Object.assign([], value.scoreInfo);
            var val = Object.assign({}, value)
            val.scoreInfo = [] as ScoreInfo[];
            var requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + props.userState.user.currentToken,
                    'Content-Type': 'application/json',
                    'Response-Type': 'Blob',
                },
                body: JSON.stringify({ neighborhood: val, info: info })
            }
            try {
                await fetch(baseEndpoint + "neighborhoods/info/update/" + val.internalId, requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            await response.json();
                            setEditNeighborhoodOpen(false);
                        }
                        else {
                            if (response.status === 403) {
                                setEditLoad(false);
                                setSessionExpiredOpen(true);
                            }
                        }

                    })
            } catch (error) {
                setEditNeighborhoodOpen(false);
            }

        }
        else{
            setEditNeighborhoodOpen(false);
        }

    }

    const editQuestion = (question: Question, answers: Answer[]) => {
        setSelectedEditQuestion(question);
        setSelectedEditAnswers(answers);
        setEditQuestionOpen(true);
    }

    const closeEditQuestion = async (question: Question, answers: Answer[]) => {
        if (question != null) {
            setEditLoad(true);
            var requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + props.userState.user.currentToken,
                    'Content-Type': 'application/json',
                    'Response-Type': 'Blob',
                },
                body: JSON.stringify(question)
            }
            try {
                await fetch(baseEndpoint + "questions/update/" + question.internalId, requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            await response.json()
                            fetchQuestions();
                            setEditLoad(false);
                            setEditNotifMessage(question.description + " edited successfully!");
                            setEditNotifOpen(true);
                        }
                        else {
                            if (response.status === 403) {
                                setEditLoad(false);
                                setEditNotifMessage("Error: Session has expired. Please logout and back in.");
                                setEditNotifOpen(true);
                            }
                        }

                    })
            } catch (error) {
                console.log(error);

            }

        }
        setSelectedEditQuestion({} as Question);
        setSelectedEditAnswers([] as Answer[]);
        setEditQuestionOpen(false);
    }

    const handleAddPropertyOpen = () => {
        setAddPropertyOpen(true);
    }

    const handloseCloseSessionExpired = (refresh: boolean) =>{
        setSessionExpiredOpen(false);
        if(refresh === true)
        {
            props.refreshSession();
        }
    }

    const handleCloseAddProperty = async (property: Property, propScoreInfo: ScoreInfo[], neighborhood: Neighborhood, neighborhoodScoreInfo: ScoreInfo[], landlord: Landlord, landlordScoreInfo: ScoreInfo[]) =>{
        
        if (property != null) {
            setEditLoad(true);
            var requestOptions = {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + props.userState.user.currentToken,
                    'Content-Type': 'application/json',
                    'Response-Type': 'application/json',
                },
                body: JSON.stringify({ property: property, propertyScoreInfo: propScoreInfo, neighborhood: neighborhood, neighborhoodScoreInfo: neighborhoodScoreInfo,
                landlord: landlord, landlordScoreInfo: landlordScoreInfo})
            }
            try {
                await fetch(baseEndpoint + "properties/add", requestOptions)
                    .then(async response => {
                        if (response.ok) {
                            var r = await response.json()
                            console.log(r);
                            setEditLoad(false);
                            setEditNotifMessage("Added " + property.addresses[0].address + " successfully!");
                            setEditNotifOpen(true);
                            setAddPropertyOpen(false);
                        }
                        else {
                            if (response.status === 403) {
                                setEditLoad(false);
                                setSessionExpiredOpen(true);
                            }
                            else{
                                var error = await response.text();
                                console.error("Send to Simon: " + error)
                                setEditLoad(false);
                                setEditNotifMessage("An error occured while adding " + property.addresses[0].address);
                                setEditNotifOpen(true);
                            }
                        }

                    })
            } catch (error: unknown) {
                if(error instanceof Error)
                {
                    console.error("Send to Simon: " + error.message);
                }
                
                setEditLoad(false);
                
                setEditNotifMessage("An error occured while adding " + property.addresses[0].address);
                setEditNotifOpen(true);

            }
            
        }
        else{
            setAddPropertyOpen(false);
        }

    }
    const openTaxData = (p: Property) =>{
        setTaxProperty(p);
        setTaxDataOpen(true);
    }

    const handleCloseTaxData = () =>{
        setTaxProperty({} as Property);
        setTaxDataOpen(false);
    }
    return (
        <div style={{ flexGrow: 1 }}>
            <Backdrop open={editLoad}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", textAlign: "left", marginRight: "17px", marginLeft: "17px" }} >
                <Button onClick={() => setSelectedTab(0)} style={{ color: "white", paddingLeft: "5px", backgroundColor: selectedTab === 0 ? "#17d703" : "initial", borderRadius: "initial" }}>Properties</Button>
                <Button onClick={() => setSelectedTab(1)} style={{ color: "white", marginLeft: "10px", backgroundColor: selectedTab === 1 ? "#17d703" : "initial", borderRadius: "initial" }}>Questions & Answers</Button>
                <Button onClick={() => setSelectedTab(2)} style={{ color: "white", marginLeft: "10px", backgroundColor: selectedTab === 2 ? "#17d703" : "initial", borderRadius: "initial" }}>Tax Data</Button>        
            </div>
            {selectedTab === 0 && <Paper elevation={5} className={classes.root}>
                <Typography style={{ marginTop: "10px", marginLeft: "15px" }} variant="h4">Neighborhoods</Typography>
                <Button onClick={handleAddPropertyOpen} style={{ color: "black", backgroundColor: "#17d703", marginLeft: "25px", marginTop: "10px" }} size='small' endIcon={<AddIcon />}>Add Property</Button>
                {neighborhoods.length !== 0 ? <List>
                    {neighborhoods.map((n, i) => {
                        return (
                            <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }} key={i}>
                                <Accordion style={{ width: "100%" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        IconButtonProps={{ style: { marginRight: "70%" } }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="body1">{n.name}</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails style={{ display: "block" }}>
                                        <Button onClick={() => { editNeighborhood(n) }} style={{ color: "black", backgroundColor: '#ffb74d' }} size='small' endIcon={<EditIcon />}>Edit Neighborhood</Button>
                                        <List>
                                            {allProperties.length > 0 && allProperties.map((p, j) => {
                                                if (p.neighborhoodId === n.internalId) {
                                                    var adds = "";
                                                    p.addresses.forEach((a, k) => {
                                                        adds += a.address + ", " + a.city + ", " + a.state + " " + a.zip;
                                                        if (k !== p.addresses.length - 1) {
                                                            adds += " - ";
                                                        }
                                                    });
                                                    return (
                                                        <ListItem key={j}>
                                                            <Button style={{ textTransform: "none" }} > <Typography style={{ textAlign: "left" }} variant="body2">{adds}</Typography></Button>
                                                        </ListItem>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }

                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        )
                    })}
                </List> : <div style={{ marginTop: "15vh", textAlign: "center", paddingBottom: "10vh" }}><CircularProgress size={70} style={{ color: "#0404fc" }} /></div>}
            </Paper>}
            {selectedTab === 1 && <Paper elevation={5} className={classes.root}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Group</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Question</TableCell>
                            <TableCell>Max Score</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allQuestions.map((q, i) => {
                            if (q.parentQuestionId != null) {
                                return (
                                    <QuestionRow editQuestion={editQuestion} key={i} row={q} parent={allQuestions.filter(p => p.internalId === q.parentQuestionId)[0].description} answers={allAnswers.filter(a => a.questionId === q.internalId)} />
                                )
                            }
                            else {
                                return null
                            }
                        })}
                    </TableBody>
                </Table>
            </Paper>}
            {selectedTab === 2 && <Paper elevation={5} className={classes.root}>
                <Typography style={{ marginTop: "10px", marginLeft: "15px" }} variant="h4">Tax Data</Typography>
                {neighborhoods.length !== 0 ? <List>
                    {neighborhoods.map((n, i) => {
                        return (
                            <ListItem style={{ paddingTop: "0px", paddingBottom: "0px" }} key={i}>
                                <Accordion style={{ width: "100%" }} >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        IconButtonProps={{ style: { marginRight: "70%" } }}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography variant="body1">{n.name}</Typography>

                                    </AccordionSummary>
                                    <AccordionDetails style={{ display: "block" }}>
                                        <List>
                                            {allProperties.length > 0 && allProperties.map((p, j) => {
                                                if (p.neighborhoodId === n.internalId && p.addresses[0].block !== 0) {
                                                    var adds = "";
                                                    p.addresses.forEach((a, k) => {
                                                        adds += a.address + ", " + a.city + ", " + a.state + " " + a.zip;
                                                        if (k !== p.addresses.length - 1) {
                                                            adds += " - ";
                                                        }
                                                    });
                                                    return (
                                                        <ListItem key={j}>
                                                            <Button onClick={() => openTaxData(p)} style={{ textTransform: "none" }} > <Typography style={{ textAlign: "left" }} variant="body2">{adds}</Typography></Button>
                                                        </ListItem>
                                                    )
                                                }
                                                else {
                                                    return null;
                                                }

                                            })}
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                        )
                    })}
                </List> : <div style={{ marginTop: "15vh", textAlign: "center", paddingBottom: "10vh" }}><CircularProgress size={70} style={{ color: "#0404fc" }} /></div>}
            </Paper>}
            <EditQuestionDialog open={editQuestionOpen} question={selectedEditQuestion} onClose={closeEditQuestion} answers={selectedEditAnswers} />
            <EditNeighborhoodDialog open={editNeighborhoodOpen} answers={allAnswers} onClose={closeEditNeighborhood} neighborhood={selectedNeighborhood} />
            {addPropertyOpen === true && <AddPropertyDialog neighborhoods={neighborhoods} landlords={landlords} questions={allQuestions} answers={allAnswers} open={addPropertyOpen} onClose={handleCloseAddProperty} />}

            <Snackbar open={editNotifOpen} autoHideDuration={6000} onClose={() => {
                setEditNotifOpen(false);
            }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => {
                    setEditNotifOpen(false);
                }} variant="filled" severity={editNotifMessage.includes("successfully!") ? "success" : "error"}>
                    {editNotifMessage}
                </Alert>
            </Snackbar>
            <ContinueSessionDialog open={sessionExpiredOpen} onClose={handloseCloseSessionExpired} />
            <TaxDataDialog open={taxDataOpen} property={taxProperty} onClose={handleCloseTaxData} />
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    userState: state.userState

});
const mapDispatchToProps = (dispatch: any) => ({
    refreshSession: () => dispatch(refreshSession())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
