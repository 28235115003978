import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Backdrop, Button, Card, CardActions, CardContent, CircularProgress, IconButton, List, ListItem, Snackbar, Tooltip, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { clearCart, removeFromCart } from '../Actions/CartActions';
import { Property } from '../Models/Property';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { baseEndpoint } from '../App';
import { CheckoutOptionDialog } from './CheckoutOptionDialog';
import FileSaver from 'file-saver';
import { Alert } from '@material-ui/lab';
import LoginDialog from '../Header/LoginDialog';
import {Color} from "@material-ui/lab/Alert"
import BillingDialogMobile from './BillingDialogMobile';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "15px",
            textAlign: "left",
            height: "65vh"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);
export const PRICE = 150;
export const BETA_PRICE = 20;
function CartMobile(props: any) {
    const classes = useStyles();
    const [billingOpen, setBillingOpen] = useState<boolean>(false);
    const [checkoutOptionsOpen, setCheckoutOptionsOpen] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [downloadAlertOpen, setDownloadAlertOpen] = useState<boolean[]>([]);
    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [checkoutAlertOpen, setCheckoutAlertOpen] = useState<boolean>(false);
    const [checkoutAlert, setCheckoutAlert] = useState<string>("");
    const [alertSeverity, setAlertSeverity] = useState<Color | undefined>("success");
    const [loadingEllipses, setLoadingEllipses] = React.useState<string>("   ");
    useEffect(() => {
        if (props.cartState.items > 0) {
            var o = [] as boolean[]
            props.cartState.items.forEach((c: any) => {
                o.push(false);
            })
            setDownloadAlertOpen(o);
        }
        if (props.userState.isAuth === true) {
            setLoginOpen(false);
        }
    }, [props]);

    useEffect(() =>{
        setTimeout(()=>{
            if(downloadLoading === true)
            {
                var count = loadingEllipses.split('.');
                console.log(count);
                if(count.length === 1)
                {
                    setLoadingEllipses(".  ");
                }
                else if(count.length === 2){
                    setLoadingEllipses(".. ");
                }
                else if(count.length === 3)
                {
                    setLoadingEllipses("...");
                }
                else if(count.length === 4)
                {
                    setLoadingEllipses("   ");
                }
                
            }
            else{
                setLoadingEllipses("   ");
            }
           }, 500)


    },[downloadLoading, loadingEllipses])

    const removeFromCart = (id: number) => {
        props.removeFromCart(id);
    }

    const checkout = () => {
        setBillingOpen(true);

    }
    const handleCheckoutOptionsOpen = () => {
        if (props.userState.isAuth === true) {
            checkout();
        }
        else {
            setCheckoutOptionsOpen(true);
        }

    }

    const handleCheckoutOptionsClose = (value: string) => {
        setCheckoutOptionsOpen(false);
        if (value === "guest") {
            checkout();
        }
        if (value === "user") {
            checkout();
            setLoginOpen(true);
        }
    }

    const downloadScoreReport = async (id: string, email: string, addressId: number, last: boolean) => {
        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.userState.user.currentToken,
                'Content-Type': 'application/json',
                'Response-Type': 'Blob',
            },
            body: JSON.stringify(email)
        }
        try {
            await fetch(baseEndpoint + "properties/report/" + id, requestOptions)
                .then(async response => {
                    if (response.ok) {
                        var p = props.cartState.items.filter((pr: any) => pr.addresses[0].internalId === addressId)[0];
                        console.log(p);
                        var address = p.addresses[0].address + " " + p.addresses[0].city + ", " + p.addresses[0].state + " " + p.addresses[0].zip;
                        //Create a Blob from the PDF Stream
                        const file = new Blob([await response.blob()], { type: "application/pdf" });
                        FileSaver.saveAs(file, address);
                        if (last === true) {
                            setDownloadLoading(false);
                            props.clearCart();
                            setAlertSeverity("success");
                            setCheckoutAlert("Property report download complete!");
                            setCheckoutAlertOpen(true);
                           
                        }
                        var temp = Object.assign([], downloadAlertOpen) as boolean[];
                        temp[props.cartState.items.findIndex((pr: any) => pr.addresses[0].internalId === id)] = true;
                        setDownloadAlertOpen(temp);
                        return
                    }
                    else {
                        if (last === true) {
                            var mess = await response.text();
                            console.log(mess);
                            setAlertSeverity("error");
                            setCheckoutAlert("Error: "+ mess+"");
                            setCheckoutAlertOpen(true);
                            setDownloadLoading(false);
                        }
                        return;
                    }

                })
        } catch (error) {
            console.log(error);
            if (last === true) {
                setDownloadLoading(false);
            }

            return
        }
    }


    const handleBillingClose = (value: any[]) => {
        console.log(value);
        if (value.length > 0) {
            setDownloadLoading(true);
            value.forEach((c: any, i: number) => {
                var last = i + 1 >= value.length
                downloadScoreReport(c.id, c.email, c.addressId, last);
            })
        }
        setBillingOpen(false);
    }

    const handleCloseDownloadAlert = (index: number) => {
        var temp = Object.assign([], downloadAlertOpen) as boolean[];
        temp[index] = false;
        setDownloadAlertOpen(temp);
    }

    const handleCloseLogin = () => {
        setLoginOpen(false);
        if (props.userState.isAuth === true) {
            checkout();
        }

    }

    const handleCloseAlert = () =>{
        setCheckoutAlertOpen(false);
    }
    return (
        <div style={{ flexGrow: 1 }}>
            {downloadAlertOpen.length > 0 && downloadAlertOpen.map((o: boolean, i: number) => {
                var p = props.cartState.items[i];
                var address = p.addresses[0].address + " " + p.addresses[0].city + ", " + p.addresses[0].state + " " + p.addresses[0].zip;
                return (<Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={() => { handleCloseDownloadAlert(i) }}>
                    <Alert variant="filled" onClose={() => { handleCloseDownloadAlert(i) }} severity="success" style={{ width: '100%' }}>
                        {address} download success!
                    </Alert>
                </Snackbar>)
            })}
            <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "5px", marginLeft: "5px" }} />
            <Card style={{ overflowY: "auto" }} className={classes.root}>
                <CardContent style={{ minHeight: "60%" }}>
                    <Typography style={{ paddingLeft: "5px" }} variant="h6"><strong>Score Report Shopping Cart</strong></Typography>
                    {props.cartState != null && props.cartState.items.length > 0 && <List style={{ paddingLeft: "5px", width: "100%" }}>
                        {props.cartState.items.map((p: Property, i: number) => {
                            var address = p.addresses[0].address + " " + p.addresses[0].city + ", " + p.addresses[0].state + " " + p.addresses[0].zip;
                            return (
                                <ListItem style={{ paddingBottom: "15px", paddingLeft: "0px" }} key={i}>
                                    <div style={{ display: "block", width: "100%" }}>
                                        <div style={{ display: "flex", width: "100%" }}>
                                            <div style={{ textAlign: "right" }}>
                                                <Tooltip title="Remove"><IconButton onClick={() => { removeFromCart(p.addresses[0].internalId) }} style={{ padding: "5px", color: "#C71020" }}><HighlightOffIcon /></IconButton></Tooltip>
                                            </div>
                                            <div><img src="green-pin.png" alt="" /></div><Typography style={{ paddingLeft: "5px" }} variant="body1"><strong>{address}</strong></Typography>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <span style={{ paddingLeft: "45px", fontSize: "0.9em" }}><strong>Score:</strong> {(Math.round(p.calcScore)).toString()}</span>    <span style={{ paddingLeft: "8px", fontSize: "0.9em" }}><strong>Neighborhood:</strong> {p.neighborhood.name}</span>
                                        </div>
                                    </div>
                                </ListItem>
                            )
                        })}
                    </List>}
                </CardContent>

                {props.cartState && props.cartState.items.length > 0 && <CardActions style={{ justifyContent: "right", textAlign: "right", display: "block" }}>
                    <Typography style={{ textAlign: "right", paddingRight: "60px", color: "red" }} variant="h6">Total(beta): ${props.cartState.items.length * BETA_PRICE}  </Typography>
                    <Typography style={{ textAlign: "right", paddingRight: "75px", textDecoration: "line-through" }} variant="h6">Total: ${props.cartState.items.length * PRICE}  </Typography>
                    <div style={{ display: "inline" }}>
                        <Button size="small" onClick={handleCheckoutOptionsOpen} style={{ backgroundColor: "#0404fc", color: "white", marginRight: "10px" }}>Checkout</Button>
                        <Button size="small" href="/properties" style={{ backgroundColor: "#0404fc", color: "white"}}>Continue Shopping</Button>
                    </div>

                </CardActions>}
            </Card>
            <CheckoutOptionDialog open={checkoutOptionsOpen} onClose={handleCheckoutOptionsClose} />
            <BillingDialogMobile onClose={handleBillingClose} open={billingOpen} />
            <Backdrop className={classes.backdrop} open={downloadLoading}>
            <div style={{width: "100%"}}>
                 <CircularProgress size={60} color="inherit" />
                 <Typography style={{whiteSpace: "pre"}} variant="body2">{ "Please wait while we download your property report" + (props.cartState.items.length > 1 ? "s" : "") + loadingEllipses}</Typography>
                 
                </div>
            </Backdrop>
            {props.userState.isAuth === false && <LoginDialog open={loginOpen} handleClose={handleCloseLogin} />}
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={checkoutAlertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert variant="filled" onClose={handleCloseAlert} severity={alertSeverity}>
                    {checkoutAlert}
                </Alert>
            </Snackbar>
        </div>
    );
}
const mapDispatchToProps = (dispatch: any) => ({
    removeFromCart: (id: number) => dispatch(removeFromCart(id)),
    clearCart: () => dispatch(clearCart())
});
const mapStateToProps = (state: any) => ({
    cartState: state.cartState,
    userState: state.userState
});
export default connect(mapStateToProps, mapDispatchToProps)(CartMobile);