export interface ScoreInfo {
    questionId: number,
    questionDescription: string,
    answerId: number,
    answerDescription: string,
    fact: string,
    additionalInfo: string,
    score: number,
    id: string
}

export interface Answer{
    internalId: number,
    id: string,
    description: string,
    score: number,
    questionId: number,
    createdBy: number
    createdUtc: Date,

}

export interface Question{
    internalId: number,
    id: string,
    description: string,
    maxScore: number,
    parentQuestionId: number,
    createdBy: number
    createdUtc: Date,
    answerType: AnswerType,
    required: boolean,
    questionType: QuestionType
}

export enum AnswerType{
    Boolean, 
    String,
    Number
}

export enum QuestionType{
    Property,
    Neighborhood,
    Landlord
}