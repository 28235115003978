import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: "15px",
            textAlign: "left"
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
    }),
);

export default function AboutPage() {
    const classes = useStyles();

    return (
        <div style={{ flexGrow: 1 }}>
            <div style={{ backgroundColor: "#0404fc", borderWidth: "5px", height: "29px", marginRight: "17px", marginLeft: "17px" }} />
            <Paper className={classes.root}>
                <Typography style={{ marginLeft: "15px", marginRight: "15px" }} variant="h4">About Us</Typography>
                <br />
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">What is Parslii?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    Parslii is a research and rating system for retail and restaurant commercial real estate properties.
                    The Parslii system researches, analyzes, scores, and uncovers insights into properties with retail storefront spaces.
                    Similar to buying a TV or major appliance, Parslii allows you to select several properties and compare all of their details side-by-side,
                    in an effort to help you make a more informed decision about the space you choose for your business.
                    <br />
                    <br />
                    Parslii does the research for you, compiling and analyzing over 60 different data points from dozens of sources,
                    and then extracts insights from the data, so that the process of choosing a space is as transparent and objective as possible.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">How does Parslii work?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    Parslii takes all of the data harvested about a particular building, feeds it through our analysis system,
                    and translates that into a score. This Parslii Score, on a scale from 0 - 100, will give a general view of how that building ranks against other
                    buildings in terms of its viability as a location for launching and sustaining a successful business.
                    <br />
                    <br />
                    If a Parslii Score for a building seems within an acceptable range, you can purchase the full Parslii Score Reports for that, and any other buildings
                    you are interested in, and directly compare the findings for each, in every category of analysis. This Score Report comparison will give you a much clearer,
                    thorough, and objective understanding of the positive and negative aspects pertaining to the buildings, in order for you to make a decision based on factual data rather
                    than on gut feeling or hearsay.
                </Typography>
                <br />
                <div style={{ display: "flex", marginRight: "15px" }}>
                    <div>
                        <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">
                            Buying Parslii Score Reports for several properties before my business makes its first dollar seems expensive. I know it takes money to make money,
                            but I am trying to keep things as tight as possible. Is the expense of buying Score Reports worth it?
                        </Typography>
                        <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="body2">
                            We would have asked the same thing if Parslii was around when we were looking for commercial space!
                            With investment money burning quickly, it never seemed like there was an extra few hundred dollars to spend.
                            Besides, you think, “I can do the research myself!” But, after signing a lease, and a few months in, you realize
                            that the clause about property taxes which you didn’t think much of (everybody has to pay taxes, right??), has come back to bite you.
                            And the landlord is sending ginormous invoices. Then, after an eviction notice, tons of emotional stress, a year in court, and tens of
                            thousands of dollars in attorney’s fees, you wish you would have better understood the situation you were getting yourself into back then,
                            before you signed that faulty lease. Maybe you could have avoided this painful and costly trap. Suddenly the small initial expense of a
                            Parslii Score Report, which would have alerted you to property tax red flags, seems like it would have been a wise investment.
                            <br />
                            <br />
                            Our founder knows this regret very personally because he went through all of it himself.
                            In 2015 he built and opened a restaurant in New York City, managing the entire process, from searching for commercial real estate,
                            negotiating leases, and getting City permits, to dealing with contractors, landlord eviction notices, and lots of attorneys, by himself.
                            He dealt with it all. And dealing with it meant he ended up learning the hard way, by researching issues himself, making educated guesses, and learning from mistakes.
                            Of course some of those lessons ended up being very costly in both money and stress. One of the thoughts he kept coming back to was,
                            “If only I would have known this sooner!!”
                            He felt so strongly about this, and the need for tenants to be better informed and know what they are getting into before they take the plunge, that he wrote a book about his experience.
                        </Typography>
                        <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="body2"><a href="https://www.amazon.com/dp/B09WQ4Z7RC" rel="noreferrer" target="_blank">Burnt - A Trial By Fire Education In The Cruel World Of New York City Commercial Real Estate And Hospitality</a></Typography>
                        <br />
                        <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                            The book is full of tips meant to help those with dreams of opening their own business, so that they can learn from his experience in commercial real estate,
                            avoid the traps, pitfalls, and inevitable mistakes he made, and have a much easier and more successful go of it in general. The book also has a few chapters that are just plain crazy.
                            Check it out!
                            <br />
                            <br />
                            Of course the experiences detailed in the book of wading neck-deep into the murky waters of New York commercial real estate were also the inspiration
                            for what would become Parslii. So his answer to the question of the upfront cost of Parslii Score Reports being money well spent is definitely “YES!”
                        </Typography>
                        <br />
                    </div>
                    <div>
                        <img alt="Book cover titled 'Burnt: A Trial By Fire Education In The Cruel World Of New York City Commercial Real Estate And Hospitality'." src="book.jpg"></img>
                    </div>
                </div>
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">Could I just do the research by myself?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    All of the data that Parslii uses is publicly accessible so, sure, you could just do the research yourself.
                    And maybe you are smart enough to know where to find it all and know how to make sense of it all. But many people don’t know where to find lots of important data.
                    And many people don’t even know what they should be looking for. And many people, given the data, don’t know what insights they could be pulling from it.
                    You probably aren’t one of those people. So go ahead.
                    <br />
                    <br />
                    However, doing thorough research on a commercial property can take a significant amount of time.
                    And many people, managing a growing business or working at trying to launch one, simply don’t have much time to spare.
                    So Parslii can do the research work for you and save you lots of time. Maybe you are one of those people?
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">What if a building I am interested in does not have a Parslii Score?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    You can request a score! We are adding buildings and property scores all the time so we can certainly look
                    into a property you are interested in! Just fill out the form on the Parslii ‘Property Score Request Form’ page with the
                    address of the property that you are interested in, and submit. The Parslii team will do the analysis on that building and
                    let you know what score it received (it will also now show up on the map). You can then decide if you would like to purchase the full Score Report for that property.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">Where does Parslii find its data?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">Parslii gathers its data for analysis from multiple publicly available sources.</Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">What is a Parslii Certification?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    While Parslii Scores are derived only from available data, Parslii will give owners the opportunity to
                    certify data points about a building that might not otherwise be publicly available. They will also be able to certify that they will offer various
                    concessions and/or lease language in term negotiations. Certifications are an opportunity for owners to make the lease negotiation process quicker, less costly, and more efficient.
                    The Parslii Score for a given building will improve with each Certification that the owner provides.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">What is ESG and why does Parslii care about it?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    Businesses are rapidly growing in development of their Environmental, Social, and Governance (also known as ESG) practices.
                    At the same time, investors and customers are increasingly focused on these issues as they choose where to spend their money.
                    While people might think that many ESG factors seem as though they would not apply to real estate, in actuality ESG practices affect multiple
                    areas of commercial real estate, from environmental concerns and building energy efficiency, to labor practices, equality in leasing, and social outreach to name a few. For example, in 2019 New York City enacted Local Law 97 which requires many buildings in the city to drastically reduce their carbon emissions starting in 2024. How building owners navigate these requirements will greatly affect the financial health of their buildings and their tenants through the length of their lease.
                    <br />
                    <br />
                    In an effort to bring important ESG practices further into focus for the real estate industry,
                    Parslii offers a platform for property owners to document their ESG milestones, initiatives,
                    and projected plans. This parslii ESG verification program can be a vital tool for potential
                    tenants, as well as investors, to make sure an owner’s ESG practices align with the values and
                    outlook of not only their business but also the values of their customers.
                    <br />
                    <br />
                    Parslii Property Reports will provide owners with an opportunity to tout their company’s ESG implementation track record and plans for compliance into the future.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">What is the Parslii methodology?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    The Parslii system and analysis methodology is proprietary. However, we work very hard to make sure all data
                    is as accurate, objective, and thorough as possible. And we are constantly updating it.
                    <br />
                    <br />
                    The Parslii analysis system never directly solicits or publishes tenant reviews or opinions
                    about a property or owner in order to influence a building’s Parslii Score. All Parslii Scores are
                    derived from publicly available data and measurable information. Also, every building goes through the
                    exact same analysis system so there is no bias for or against any property. The facts and analysis dictate the Score.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">Can I use Parslii research in place of hiring a real estate attorney?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    No. Parslii research and Property Report insights ARE NOT legal advice. They are meant
                    to augment and inform your legal approach to lease negotiations. Any decisions regarding leasing and lease
                    negotiations should be made in conjunction with an experienced real estate attorney.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">How often does Parslii update its scores and research?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                    Parslii is continually verifying and updating the data that it uses for its analysis. Parslii is also
                    continually adding to and refining its analysis algorithm. So Parslii Scores may adjust from time to time.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">
                    What if I see something on Parslii that I think is wrong? What if I have information about a building, a landlord,
                    a location, etc. that few others may know and that I think might affect its Parslii Score or be important when considering that building for a lease?
                </Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                If you see something, say something! Please let us know! We want Parslii data to be as thorough and accurate as possible, 
                so we want to see any mistakes corrected more than anyone. Submit a tip by using our ‘Contact’ form or by emailing us at <a href="mailto:info@parslii.com">info@parslii.com</a>
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">Are Parslii Scores and Score Report analysis available anywhere beyond New York City?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                Parslii launched in New York City and has been concentrating on refinement of our system and database growth here. As soon as we can, though, we will make Parlii Scores available in other cities. 
                Please feel free to contact us at <a href="mailto:info@parslii.com">info@parslii.com</a> and let us know what city’s commercial real estate scene you are interested in us tackling next.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">I am a commercial real estate broker. Can I use a good Parslii Score and the info on a full Score Report to help me lease a commercial retail space I am listing?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                A good Parslii Score can certainly help to get potential tenants interested in a space. We are creating a widget so that brokers can whitelabel a 
                Parslii Score onto their listing for a space. Contact us at <a href="mailto:info@parslii.com">info@parslii.com</a> to inquire about getting a white label Parslii Score for your listing.
                <br />
                <br />
                While the research and insight material published in a full Parslii Score Report is the property of Parslii and cannot be passed beyond the original purchaser, real estate brokers can subscribe to Parslii in order to share 
                Parslii research information with potential tenants of a space that they list. Please contact us at <a href="mailto:info@parslii.com">info@parslii.com</a> for more information.
                </Typography>
                <br />
                <Typography style={{ marginLeft: "25px", marginRight: "15px" }} variant="h6">I am a property owner. My buildings seem to have low Parslii Scores. What can I do?</Typography>
                <Typography style={{ marginLeft: "25px", marginRight: "15px", marginBottom: "25px" }} variant="body2">
                All Parslii scores for all buildings are based on the same set of data metrics. So a low score cannot be changed unless the underlying data changes in some way. However, we are launching a system of post-score Certifications that 
                property owners can add to their properties in order to increase a building’s Parslii Score. Please contact us at <a href="mailto:info@parslii.com">info@parslii.com</a> for more information about Parslii Owner Certifications.
                </Typography>
                <br />
            </Paper>

        </div>
    );
}

