import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button,  Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { signOut } from '../Actions/thunks';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    position: "relative",
    // backgroundColor: "#326b9d",
    padding: "0 0px 12px!important",
    height: "17vh",
    backgroundColor: "#0404fc",
    placeContent: "center"
  },
  dividerRoot: {
    backgroundColor: "black"
  }
}));

function MobileFooter(props: any) {
  const classes = useStyles();

console.log(window.location);
  return (
    <div className={classes.appBar}>
      {window.location.pathname !== "/properties" && <Button href='/properties' style={{ backgroundColor: "#17d703", color: "black", marginTop: "15px" }}>Browse Parslii Scores</Button>}
      <div style={{ marginTop: "10%" }}>
        <Typography style={{ color: "white" }} variant="caption"> Please see our full <a href="/terms" style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>Terms of Use</a>.
        </Typography>

      </div>

    </div>
  );
}
const mapStateToProps = (state: any) => ({
  userState: state.userState
});
const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MobileFooter);