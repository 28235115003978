import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AppBar, Checkbox, createStyles, FormControl, FormControlLabel, IconButton, InputLabel, makeStyles, MenuItem, Select, Slide, TextField, Theme, Toolbar, Typography } from '@material-ui/core';
import { Answer, Question, QuestionType } from '../Models/Info';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor: '#0404fc'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);





export interface EditQuestionDialogProps {
    open: boolean,
    onClose: (q: any, a: any[]) => void,
    question: Question,
    answers: Answer[],
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditQuestionDialog(props: EditQuestionDialogProps) {
    const classes = useStyles();
    const [question, setQuestion] = useState<Question>({} as Question);
    const [answers, setAnswers] = useState<Answer[]>([] as Answer[]);
    useEffect(() => {
        if (props.open === true) {
            setQuestion(props.question)
            setAnswers(props.answers)

        }

    }, [props])

    const handleClose = () => {
        setQuestion({} as Question);
        props.onClose(null, []);
    };


    const handleChangeQuestionDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        var temp = Object.assign({}, question) as Question;
        temp.description = event.target.value;
        setQuestion(temp);

    };

    const handleChangeQuestionType = (event: React.ChangeEvent<{ value: any }>) => {
        var type: keyof typeof QuestionType = event.target.value;
        var temp = Object.assign({}, question) as Question;
        temp.questionType = QuestionType[type];
        setQuestion(temp);
    }

    const handleChangeAnswerDescription = (event: React.ChangeEvent<{ value: unknown }>, i: number) => {
        var temp = Object.assign([], answers) as Answer[];
        temp[i].description = event.target.value as string;
        setAnswers(temp);

    };

    const handleChangeAnswerScore = (event: React.ChangeEvent<{ value: unknown }>, i: number) => {
        var temp = Object.assign([], answers) as Answer[];
        var score = event.target.value as number;
        if (!isNaN(score)) {
            temp[i].score = event.target.value as number;
            var isHigher = answers.filter(a => a.score > score).length === 0;
            if (isHigher) {
                var quest = Object.assign({}, question) as Question;
                quest.maxScore = score;
                setQuestion(quest);
            }
            setAnswers(temp);
        }


    };
    const handleChangeRequired = (event: React.ChangeEvent<HTMLInputElement>) => {
        var quest = Object.assign({}, question) as Question;
        quest.required = event.target.checked;
        setQuestion(quest);
    };

    const handleSave = () => {
        props.onClose(question, []);
    };
    return (
        <Dialog TransitionComponent={Transition} fullScreen open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            {question.id != null && <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {question.description}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleSave}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>}
            <DialogContent>
                <div style={{ display: "flex", marginTop: "15px" }}>
                    <TextField style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }} onChange={handleChangeQuestionDescription} value={question.description} id="question-description" label="Question" />
                </div>
                <div style={{ display: "flex", marginTop: "15px", width: "80%", marginLeft: "10%" }}>
                    <FormControl style={{ width: "50%" }}>
                        <InputLabel id="demo-simple-select-label">Question Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={QuestionType[question.questionType]}
                            onChange={(e) => {
                                handleChangeQuestionType(e)
                            }}
                        >
                            <MenuItem value={QuestionType[0]}>{QuestionType[0]}</MenuItem>
                            <MenuItem value={QuestionType[1]}>{QuestionType[1]}</MenuItem>
                            <MenuItem value={QuestionType[2]}>{QuestionType[2]}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        style={{ marginLeft: "25px", marginTop: "5px" }}
                        control={
                            <Checkbox
                                style={{ color: "#0404fc"}}
                                checked={question.required}
                                onChange={handleChangeRequired}
                                name="required"
                            />
                        }
                        label="Is Required"
                    />
                    <Typography style={{ marginLeft: "25px", marginRight: "auto", marginTop: "15px" }} variant="body1">Max Score: <strong>{question.maxScore}</strong></Typography>
                </div>
                <div style={{ width: "80%", marginLeft: "10%", paddingTop: "50px" }}>
                    <Typography variant="h6">Answers</Typography>
                    {answers.length > 0 && answers.map((a, i) => {
                        return (
                            <div style={{ width: "100%", display: "flex", marginTop: i === 0 ? "18px" : "15px" }} key={i}>
                                <TextField onChange={(e) => { handleChangeAnswerDescription(e, i) }} style={{ width: "80%", marginRight: "1%" }} value={a.description} />
                                <TextField onChange={(e) => { handleChangeAnswerScore(e, i) }} style={{ width: "19%" }} value={a.score} />
                            </div>
                        )
                    })}
                </div>
            </DialogContent>

        </Dialog>
    );
}
