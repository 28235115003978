import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import { baseEndpoint } from '../App';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export default function Home() {
  const classes = useStyles();
  const [downloadGateOpen, setDownloadGateOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const openDownloadSample = () => {
    setDownloadGateOpen(true);
  }

  const submitDownloadSample = async (skip: boolean) => {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Response-Type': 'Blob',
      },
      body: JSON.stringify(skip === false ? email : "" )
    }
    try {
      await fetch(baseEndpoint + "properties/sample", requestOptions)
        .then(async response => {
          if (response.ok) {
            setDownloadGateOpen(false)
          }
          else {
            setDownloadGateOpen(false)
          }

        })
    } catch (error) {
      console.log(error);

    }

  }



  return (
    <div className={classes.root}>
      <img alt="Retail buildings on a New York City block with heading 'Unsure how to choose where to open your business?'" src="21-parslii-home-image-questions.jpg" style={{ width: "90%", height: "100%", display: "block", paddingLeft: "5%", paddingTop: "15px" }} />
      <div style={{ width: "90%", marginLeft: "5%", textAlign: "left", paddingBottom: "45px" }}>
        <Typography style={{ fontStyle: "italic", marginLeft: "5%", marginBottom: "10px", paddingTop: "25px" }} variant="h5">The pressure is intense to make an informed decision!</Typography>
        <Typography style={{ marginLeft: "5%" }} variant="body2">What to do??? <Button href='/properties' style={{ backgroundColor: "#17d703", color: "black", marginLeft: "35%" }}>Browse Parslii Scores</Button></Typography>

      </div>
      <img alt="Retail buildings on a New York City block with the Parslii logo and Parslii Score rating on each and heading 'Parslii can help'" src="21-parslii-home-image-parslii-scores.jpg" style={{ width: "90%", height: "100%", display: "block", paddingLeft: "5%" }} />
      <div style={{ width: "90%", marginLeft: "5%", color: "#0404fc", backgroundColor: "lightgray" }}>
        <Typography style={{ textAlign: "left", marginLeft: "5%", fontStyle: "bold", paddingTop: "35px", paddingBottom: "55px" }} variant="h4">
          <strong>Parslii does the research for you and helps make the space search process easy...</strong>
        </Typography>
      </div>
      <div style={{ width: "90%", marginLeft: "5%", display: "flex", justifyContent: "left", marginTop: "15px" }}>
        <Typography variant="h4" style={{ fontStyle: "bold", marginLeft: "5%", marginTop: "60px", paddingRight: "10px" }}>Browse the Parslii Scores </Typography><img src="leaf-score.png" alt="leaf" />
      </div>
      <div style={{ width: "90%", marginLeft: "5%", color: "black", display: "flex" }}>
        <div style={{ fontStyle: "bold", marginLeft: "5%", paddingTop: "10px", width: "52%", textAlign: "left", marginRight: "4%", position: "relative" }}>
          <Typography variant="h5" style={{ marginTop: "15px" }}>Get the full Parslii Property Report</Typography>
          <Typography variant="h6" style={{ marginLeft: "33%", color: "GrayText" }}>Information and multiple data points in several categories for every property</Typography>
          <div style={{ backgroundColor: "#b2b2fe", marginLeft: "33%", position: "absolute", bottom: "0px", padding: "10px", textAlign: "center" }}>
            <Typography variant="h6" style={{ textAlign: "left" }} >Download and check out sample Parslii Property Report pages</Typography>
            <Button onClick={openDownloadSample} style={{ backgroundColor: "#17d703", color: "black", width: "100%" }}>Sample Property Report</Button>
          </div>
        </div>
        <div style={{ width: "44%", backgroundColor: "#b2b2fe" }}>
          <img style={{ marginTop: "15px", marginBottom: "15px" }} src="22-parslii-sample-report-stack.png" alt="Overlapping pages of a Parslii Property Report." />
        </div>
      </div>
      <div style={{ width: "90%", marginLeft: "5%", color: "black", textAlign: "left" }}>
        <Typography variant="h4" style={{ fontStyle: "bold", marginLeft: "5%", marginTop: "60px", paddingRight: "10px" }}>Compare Property Reports</Typography>
        <div style={{ display: "flex", marginLeft: "20%", width: "77%" }}>
          <div style={{ display: "block", backgroundColor: "lightgray", width: "30%", marginRight: "1%", textAlign: "center" }}>
            <Typography style={{ fontStyle: "bold" }} variant="body1">101 Example St.</Typography>
            <img style={{ width: "30vh" }} src="22-parslii-sample-report-stack-small.png" alt="Pages of a Parslii Property Report with heading '101 Example St'." />
          </div>
          <div style={{ display: "block", backgroundColor: "lightgray", width: "30%", marginRight: "1%", marginLeft: "1%", textAlign: "center" }}>
            <Typography style={{ fontStyle: "bold" }} variant="body1">10 Sample Ave.</Typography>
            <img style={{ width: "30vh" }} src="22-parslii-sample-report-stack-small.png" alt="Pages of a Parslii Property Report with heading '10 Sample Ave'." />
          </div>
          <div style={{ display: "block", backgroundColor: "lightgray", width: "30%", marginLeft: "1%", textAlign: "center" }}>
            <Typography style={{ fontStyle: "bold" }} variant="body1">51 Example St.</Typography>
            <img style={{ width: "30vh" }} src="22-parslii-sample-report-stack-small.png" alt="Pages of a Parslii Property Report with heading '51 Example St'." />
          </div>
        </div>
        <Typography variant="h6" style={{ fontStyle: "bold", marginLeft: '20%', marginTop: "20px" }}> -Multiple data points for each property</Typography>
        <Typography variant="h6" style={{ fontStyle: "bold", marginLeft: '20%', marginTop: "20px" }}> -Owner Certifications</Typography>
        <div style={{ width: "74%", marginLeft: '20%' }}>
          <div style={{ border: "10px solid #17d703", marginTop: "10px", paddingLeft: "10px", textAlign: "left", borderLeft: "35px solid #17d703", display: "block", position: "relative" }}>
            <div style={{ transform: "rotate(270deg)", color: "white", transformOrigin: "0 0", margin: "0 0 10px 0", position: "absolute", top: 155, left: -35, padding: "5px 10px", background: "none" }}>
              <Typography variant="h6">CERTIFICATION</Typography>
            </div>
            <Typography style={{ height: "147px" }} variant="h6">
              Owners can certify data points about a building that <br />
              might not otherwise be publicy available. <br />
              Certifications improve the Parslii Score and give the <br />
              tenant more decision-making power.

            </Typography>
          </div>
        </div>
        <Typography variant="h6" style={{ fontStyle: "bold", marginLeft: '20%', marginTop: "20px" }}> -ESG Implementation</Typography>
        <div style={{ width: "74%", marginLeft: '20%' }}>
          <div style={{ border: "10px solid rgb(255, 121, 0)", marginTop: "10px", paddingLeft: "10px", textAlign: "left", borderLeft: "35px solid rgb(255, 121, 0)", display: "block", position: "relative" }}>
            <div style={{ transform: "rotate(270deg)", color: "white", transformOrigin: "0 0", margin: "0 0 10px 0", position: "absolute", top: 100, left: -35, padding: "5px 10px", background: "none" }}>
              <Typography variant="h6">ESG</Typography>
            </div>
            <Typography variant="h6">
              Owners can document their Environmental, Social, <br />
              and Governance (ESG) practices to improve their <br />
              Parslii score.

            </Typography>
          </div>
        </div>


      </div>
      <div style={{ width: "90%", marginLeft: "5%", color: "black", textAlign: "left", paddingTop: "20px", display: "flex" }}>
        <img src="22-check-blue.png" alt="check" />
        <Typography variant="h4" style={{ textAlign: "left", marginLeft: "25px", fontStyle: "bold", paddingTop: "50px", paddingBottom: "10px", color: "#0404fc" }}><strong>Choose the best property for your business!</strong></Typography>
      </div>
      <div style={{ width: "90%", marginLeft: "5%", backgroundColor: "lightgray", textAlign: "left", paddingTop: "15px", paddingBottom: "10px" }}>
        <Typography variant="h4" style={{ marginLeft: "5%", fontStyle: "bold", paddingTop: "10px", paddingBottom: "10px" }}>Streamline your research and space selection process!</Typography>
        <Typography variant="h4" style={{ marginLeft: "5%", fontStyle: "bold", paddingTop: "10px", paddingBottom: "10px", color: "#0404fc" }}>Get started using Parslii <Button href='/properties' style={{ backgroundColor: "#17d703", color: "black", marginLeft: "35%" }}>Browse Parslii Scores</Button></Typography>
      </div>
      <Dialog onClose={() => { setDownloadGateOpen(false) }} open={downloadGateOpen}>
        <div style={{ display: "flex" }}>
          <DialogTitle id="dialog-title"><span style={{ fontSize: "1.5rem", fontWeight: 400, lineHeight: "1.235" }} >Download Sample Property Report</span></DialogTitle>
          <IconButton onClick={() => { setDownloadGateOpen(false) }} style={{ marginLeft: "auto", height: "fit-content", color: '#D11A2A', padding: "5px", marginTop: "5px", marginRight: "5px" }}><CloseIcon fontSize="small" /></IconButton>
        </div>

        <DialogContent>
          <div style={{ width: "100%", display: "flex", flexDirection: "column", marginBottom: "15px", placeContent: "center" }}>
            <Typography variant="body1">Enter email to continue opening our sample property report.</Typography>
            <TextField
              style={{ width: "100%" }}
              id="email"
              label="Email"
              value={email}
              onChange={handleChangeEmail}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
              variant="standard"
            />

          </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>{submitDownloadSample(false)}} style={{ backgroundColor: "#17d703" }} target="_blank" href="230830-parslii-sample-report-pages.pdf" >Submit</Button>
          <Button onClick={()=>{submitDownloadSample(true)}} style={{color: "gray"}} target="_blank" href="230830-parslii-sample-report-pages.pdf" >Skip</Button>
          

        </DialogActions>
      </Dialog>
    </div >
  );
}

