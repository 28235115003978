import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, List, ListItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { signOut } from '../Actions/thunks';
import LoginDialog from '../Header/LoginDialog';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    position: "relative",
    // backgroundColor: "#326b9d",
    padding: "0 0px 12px!important",
    height: "15vh",
  },
  dividerRoot: {
    backgroundColor: "black"
  }
}));

function Footer(props: any) {
  const classes = useStyles();
  const [loginOpen, setLoginOpen] = React.useState<boolean>(false);

  const signOut = () =>{
    if(loginOpen === true)
    {
      setLoginOpen(false);
    }
    props.signOut();
  }

  const handleOpenLogin = () =>{
    setLoginOpen(true);
  }

  const handleCloseLogin = () =>{
    setLoginOpen(false);
  }

  return (
    <div className={classes.appBar}>
      <div style={{ marginLeft: "5%", textAlign: "left", marginTop: "20px", width: "90%" }}>

        <div style={{display: "flex"}}>
          <div style={{ width: "70%" }}>
            <p style={{ fontSize: "0.75rem", fontFamily: "Roboto, Helvetica, Arial, sans-serif", fontWeight: 400, lineHeight: "1.66", letterSpacing: "0.03333em" }}>
              Disclaimer - parslii Scores and parslii Property Reports are not legal advice. Any decisions regarding leasing or<br />
              lease negoatiations should be made in conjunction with your attorney. Please see our full <a href="/terms" style={{cursor: "pointer", color: "#3f51b5", textDecoration: "underline"}}>Terms of Use</a>.
            </p>
            <Link to={'/home'}  > 
            <img alt="" src="../parslii-logo-footer.png" style={{ marginRight: "12%", marginTop: '20px' }} />
            </Link>
            

            <a  href="https://twitter.com/parslii" rel="noreferrer" target="_blank">
            <img alt="" src="../twitter_PNG1.png" style={{ marginRight: "12px" }} />
            </a>
            <a href="https://www.instagram.com/parsliiscore/" rel="noreferrer" target="_blank">
            <img alt="" src="../instagram-PNG.png" />
            </a>
            <br />
            <Typography style={{ marginLeft: "40px" }} variant="caption">© 2021  parslii, LLC</Typography>
          </div>

          <div style={{width: "30%"}}>

          <List style={{ float: "right", marginRight: "5%", fontWeight: 700 }}>
          <ListItem style={{paddingTop: "0px", paddingBottom: "0px"}} key={1}>
          {props.userState.isAuth === false ? <Button onClick={handleOpenLogin} style={{ width: "100%", justifyContent: "left", color: "#17d703" }}>
              Sign In
            </Button> :
            <Button style={{ width: "100%", justifyContent: "left", color: "#17d703" }} onClick={signOut}>
              Sign Out
            </Button>}
            </ListItem>
            <ListItem style={{paddingTop: "0px", paddingBottom: "0px"}} key={2}>
              <Button style={{ width: "100%", justifyContent: "left", color: "#17d703" }} href="/requestProperty">
                Request Property Score
              </Button>
            </ListItem>
            <ListItem style={{paddingTop: "0px", paddingBottom: "0px"}} key={3}>
              <Button style={{ width: "100%", justifyContent: "left", color: "#17d703" }} href="/about">
                About
              </Button>
            </ListItem>
            <ListItem style={{paddingTop: "0px", paddingBottom: "0px"}} key={4}>
              <Button style={{ width: "100%", justifyContent: "left", color: "#17d703"}} href="/contact">
                Contact
              </Button>
            </ListItem>
            <ListItem style={{paddingTop: "0px", paddingBottom: "0px"}} key={5}>
              <Button style={{ width: "100%", justifyContent: "left", color: "#17d703"}} href="/terms">
                Terms of Use
              </Button>
            </ListItem>
          </List>

          </div>
          <br />
        </div>


      </div>

      {props.userState.isAuth === false && <LoginDialog open={loginOpen} handleClose={handleCloseLogin} />} 
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  userState: state.userState
});
const mapDispatchToProps = (dispatch: any) => ({
  signOut: () => dispatch(signOut()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Footer);